import React, { forwardRef } from "react";
import LoaderSpin from "../LoaderSpin";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | React.ReactNode;
  variant?: "basic" | "outline";
  loader?: boolean;
}

const Button: React.FC<ButtonProps> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ type, label, className, variant, loader, disabled, ...props }, ref) => {
  // Define CSS classes for each variant.
  const variantClasses = {
    basic: `bg-[#1C468E] rounded-xl p-2 text-white font-normal text-sm w-full ${className}`,
    outline: `rounded-xl p-2 text-sm border border-blue-700 text-blue-600 w-full ${className}`,
  };

  // Define CSS for disabled state.
  const disabledStyles = `opacity-50 cursor-not-allowed`;

  // Combine all classes based on the current state.
  const finalClasses = `${variantClasses[variant ?? "basic"]} ${className} ${
    disabled ? disabledStyles : ""
  }`;

  return (
    <button
      {...props}
      ref={ref}
      className={finalClasses}
      disabled={disabled} // Ensure the button is disabled.
    >
      {loader ? <LoaderSpin /> : label}
    </button>
  );
});

export default Button;
