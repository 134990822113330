import add from "../../assets/images/add.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useState } from "react";
import useCmsMasterStateStore from "../../state/useCmsMasterStateStore";
import { axiosUAMInstance } from "../../utils/axios";

interface AddStateModalProps {
  onClose: () => void;
}

type FormValues = {
  stateName: string;
  stateCode: string;
};

const AddStateModal: React.FC<AddStateModalProps> = ({ onClose }) => {
  // const [loader, setLoader] = useState<boolean>(false);
  // const [message, setMessage] = useState<string>("");
  const { loader, setLoader, message, setMessage } = useCmsMasterStateStore();
  const [messageType, setMessageType] = useState("success");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { stateCode, stateName } = data;
    setLoader(true);
    axiosUAMInstance
      .post(`/location/state/add`, {
        name: stateName,
        isocode: stateCode,
        cid: 95,
      })
      .then((response) => {
        // console.log(response?.data?.data);
        setMessage(response?.data?.message || "State added successfully");
        setMessageType("success");
        reset();
        setLoader(false);
        setTimeout(() => {
          onClose();
          setMessage("");
        }, 4000);
      })
      .catch((error) => {
        // console.log(error);
        setLoader(false);
        setMessage(error.response?.data?.message || error.message);
        setMessageType("error");
      });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
  };
  const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevents the event from reaching the modal background and closing the modal
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={onClose}
      >
        <Box sx={style}>
          <div
            className="m-[16px] md:m-[0px]  rounded-2xl p-[8px] text-gilroy-medium pb-[30px] shadow-xl bg-white  w-80 sm:w-[350px] md:w-[500px] lg:w-[600px]"
            onClick={handleContentClick}
          >
            <div
              className="flex flex-row justify-end mb-[12px] cursor-pointer"
              onClick={onClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="text-center text-black text-2xl font-normal ">
              Add State
            </h1>
            <h2
              className={`text-center text-xl my-5 ${
                messageType === "success" ? "text-green-500" : "text-red-500"
              }`}
            >
              {message}
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="md:px-[32px] px-[16px]">
                <div className="mt-[20px]">
                  <label
                    htmlFor="stateName"
                    className=" text-black text-[16px] font-normal   text-gilroy-medium"
                  >
                    State Name:
                  </label>
                  <InputFields
                    id="stateName"
                    placeholder="Enter State Name"
                    {...register("stateName", {
                      required: "State Name is required",
                      minLength: {
                        value: 3,
                        message:
                          "State Name must be at least 3 characters long",
                      },
                      validate: {
                        notEmpty: (value) =>
                          value.trim() !== "" || "State Name cannot be empty",
                        noNumeric: (value) =>
                          /^[a-zA-Z ]+$/.test(value) ||
                          "State Name cannot include numbers",
                      },
                    })}
                    className="mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] border  border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                  />
                  {errors.stateName && (
                    <p className="text-red-500">{errors.stateName.message}</p>
                  )}
                </div>
                <div className="mt-[20px]">
                  <label
                    htmlFor="stateCode"
                    className=" text-black text-[16px] font-normal   text-gilroy-medium"
                  >
                    State Code:
                  </label>
                  <InputFields
                    id="stateCode"
                    placeholder="Enter your state code"
                    {...register("stateCode", {
                      required: "State Code is required",
                      minLength: {
                        value: 2,
                        message: "State Code must be exactly 2 characters long",
                      },
                      maxLength: {
                        value: 2,
                        message: "State Code must be exactly 2 characters long",
                      },
                    })}
                    className="mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] border  border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                  />
                  {errors.stateCode && (
                    <p className="text-red-500">{errors.stateCode.message}</p>
                  )}
                </div>

                <hr className="w-full bg-[#E6E6E6] mb-[24px] mt-[60px]"></hr>
                <ButtonComp
                  type="submit"
                  onClose={onClose}
                  loader={loader}
                  // disabled={!isValid || Object.keys(errors).length > 0}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default AddStateModal;
