import { FC, useState, useEffect } from "react";
import add from "../../assets/images/add.svg";
import InputFields from "./InputField";
import SelectButton from "./SelectButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import LoaderSpin from "../LoaderSpin";
import "./EditModel.css";
import useCmsFormControlStore from "../../state/cmsFormControl";
import { axiosUAMInstance } from "../../utils/axios";

interface Option {
  value: string;
  label: string;
  id: number;
}

interface FileType {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface EntitySection {
  id: number;
  entityId: number;
  sectionName: string;
  createdAt: string;
  updatedAt: string;
}

interface FormData {
  documentName: string;
  fileSizeLimit: string;
  minDPI: string;
}

interface Props {
  handleOpenClose: () => void;
  heading: string;
  fileTypeData?: FileType[];
  entitySectionsData?: EntitySection[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const EditModalScheme: FC<Props> = ({
  handleOpenClose,
  heading,
  fileTypeData,
  entitySectionsData,
}: Props) => {
  const documentFieldUpdated = useCmsFormControlStore(
    (state) => state.updatedDocumentField
  );
  const [selectedOption1, setSelectedOption1] = useState<Option | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<Option | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectButtonError, setSelectButtonError] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      documentName: "",
      fileSizeLimit: "",
      minDPI: "",
    },
  });

  const options: Option[] = fileTypeData
    ? fileTypeData.map((fileType) => ({
        value: fileType.name,
        label: fileType.name,
        id: fileType.id,
      }))
    : [];

  const options1 = [
    {
      value: "Deposit Taker",
      label: "Deposit Taker",
      id: 1,
    },
    {
      value: "Regulator / Competent Authority",
      label: "Regulator / Competent Authority",
      id: 2,
    },
  ];

  useEffect(() => {
    setIsFormSubmitted(false);
  }, [selectedOption1, selectedOption2]);

  const handleSetOption1 = (option: Option) => {
    setSelectedOption1(option);
    setSelectButtonError("");
  };

  const handleSetOption2 = (option: Option) => {
    setSelectedOption2(option);
    setSelectButtonError("");
  };

  const handleFormSubmit: SubmitHandler<FormData> = (data) => {
    setIsFormSubmitted(true);

    if (!selectedOption1) {
      setSelectButtonError("Please select a file type");
      return;
    }

    if (!selectedOption2) {
      setSelectButtonError("Please select a section");
      return;
    }

    const fileType = selectedOption1 ? selectedOption1.id : null;
    const sectionId = selectedOption2 ? selectedOption2.id : null;

    const requestData = {
      documentName: data.documentName,
      fileType: fileType,
      fileSizeLimit: parseInt(data.fileSizeLimit),
      minDpi: parseInt(data.minDPI),
      sectionId: sectionId,
    };

    setLoader(true);

    axiosUAMInstance
      .post(`/registration/add-document-field`, requestData)
      .then((response) => {
        if (response.data.success) {
          documentFieldUpdated();
          reset();
          setLoader(false);
          handleOpenClose();
        } else {
          alert(response.data?.message);
        }
      })
      .catch((error) => {
        console.log("API error:", error.message);
        setApiError(error.message);
        setLoader(false);
      });
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleOpenClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="md:h-auto rounded-2xl p-[8px] text-gilroy-medium shadow-xl bg-white w-[350px] md:w-[500x] lg:w-[550px] ">
            <div
              className="flex flex-row justify-end cursor-pointer"
              onClick={handleOpenClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mt-[12px] text-center text-black text-[24px] font-normal">
              {heading}
            </h1>
            <p className="text-center text-red-500">{apiError}</p>
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="p-4 md:px-[24px]"
            >
              <div
                className="custom-scrollbar"
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="my-4">
                  <label
                    htmlFor="documentName"
                    className=" text-black text-[16px] font-normal  mb-[8px] text-gilroy-medium"
                  >
                    Document Name:
                  </label>
                  <InputFields
                    type="text"
                    id="documentName"
                    placeholder="type here"
                    {...register("documentName", {
                      required: "Document Name is required",
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-5">
                    {errors?.documentName?.message}
                  </p>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    File Types:
                  </label>

                  <SelectButton
                    setOption={handleSetOption1}
                    options={options}
                    selectedOption={selectedOption1}
                    placeholder="Select"
                  />
                  {selectButtonError && (
                    <p className="text-red-500">{selectButtonError}</p>
                  )}
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    Section id:
                  </label>

                  <SelectButton
                    setOption={handleSetOption2}
                    options={options1}
                    selectedOption={selectedOption2}
                    placeholder="Select"
                  />
                  {selectButtonError && (
                    <p className="text-red-500">{selectButtonError}</p>
                  )}
                </div>

                <div className="my-4 relative">
                  <label
                    htmlFor="fileSizeLimit"
                    className=" text-black text-base font-normal text-gilroy-medium mb-[8px] "
                  >
                    File Size Limit :
                  </label>
                  <InputFields
                    type="text"
                    id="fileSizeLimit"
                    placeholder="type here"
                    {...register("fileSizeLimit", {
                      required: "File Size Limit is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "File Size Limit must be a number",
                      },
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <div className="absolute top-9 right-0 pr-5 pt-3 py-4 pl-2 rounded-r-2xl bg-white">
                    KB
                  </div>
                  <p className="text-red-500 -mt-5">
                    {errors?.fileSizeLimit?.message}
                  </p>
                </div>

                <div className="my-4">
                  <label
                    htmlFor="minDPI"
                    className=" text-black text-base font-normal text-gilroy-medium mb-[8px]"
                  >
                    Min DPI
                  </label>
                  <InputFields
                    type="text"
                    id="minDPI"
                    placeholder="type here"
                    {...register("minDPI", {
                      required: "Min DPI is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Min DPI must be a number",
                      },
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[38px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-10">
                    {errors?.minDPI?.message}
                  </p>
                </div>
              </div>

              <div>
                <hr className="w-full "></hr>
                <div className="flex flex-row justify-around my-4">
                  <button
                    onClick={handleOpenClose}
                    className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1c468e] text-[#1c468e]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!isValid || loader || isFormSubmitted}
                    className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1c468e] rounded-xl text-white ${
                      !isValid || loader || isFormSubmitted
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    {loader ? <LoaderSpin /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditModalScheme;
