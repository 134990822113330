// export const formatDate = (isoDate: string) => {
//   const date = new Date(isoDate);
//   const day = date.getDate().toString().padStart(2, "0");
//   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
//   const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
//   const hours = date.getHours().toString().padStart(2, "0");
//   const minutes = date.getMinutes().toString().padStart(2, "0");
//   const time = `${hours}:${minutes}`;
//   return `${day}-${month}-${year} ${time}`;
// };
// export default formatDate;
export const formatDate = (isoDate: string) => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
  let hours = date.getHours();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  if (hours > 12) {
    hours -= 12; // Convert to 12-hour format if greater than 12
  }
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${minutes}`;
  return `${day}-${month}-${year} ${time} ${amOrPm}`;
};

export default formatDate;
