import React, { useEffect, useState } from "react";
import TabsItem from "./TabsItem";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

type Props = {};

const SubTabs = (props: Props) => {
  const tabs = [
    { title: "Geography", url: "master", rurl: "/cms/master/master" },
    {
      title: "Competent Authority",
      url: "authority",
      // rurl: "/cms/authority",
      rurl: "/cms/master/authority",
    },
    {
      title: "Designated Court",
      url: "designatedcourt",
      rurl: "/cms/master/designatedcourt",
    },
    {
      title: "Regulator",
      url: "regulator",
      rurl: "/cms/master/regulator",
    },
    {
      title: "Approval Documents",
      url: "approved-document",
      rurl: "/cms/master/approved-document",
    },
    {
      title: "Languages",
      url: "Languages",
      rurl: "/cms/master/Languages",
    },
  ];

  const [activeTab, setActiveTab] = useState<string>("Geography");
  const [url, setUrl] = useState<String>("");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const cmsPath = pathname.split("/")[3];
    setUrl(cmsPath);
  }, []);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <div className=" text-[#666666] flex">
        <ul className="flex items-center bg-[#E7F0FF] rounded-[20px] flex-wrap">
          {tabs.map((menuItem, index) => (
            <Link to={menuItem?.rurl} key={index}>
              <TabsItem
                key={index}
                text={menuItem?.title}
                isActive={menuItem?.url === url}
                onClick={() => handleTabClick(menuItem?.title)}
              />
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubTabs;
