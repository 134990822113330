import Accordion from "../../components/cms/CustumAccordin/custumAccordin";
import Layout from "../../components/layout/Layout";
import BackArrow from "../../assets/images/BackArrow.svg";
import CreatedBy from "./CreatedBy";
import EntityDetails from "./EntityDetails";
import SchemeDetails from "./SchemeDetails";
import AuditTrail from "./AuditTrail";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDepositTakerRegistrationStore } from "../../state/registrationStore";
import useRenderDynamicFields from "../../hooks/useRenderDynamicFields";
import LoaderSpin from "../../components/LoaderSpin";
import { axiosUAMInstance } from "../../utils/axios";
import MangementDetails from "./ManagementDetails";
import { createColumnHelper } from "@tanstack/table-core";
import BranchDetails from "./BranchDetails";

const TableType = {
  sno: Number,
  id: Number,
  depositTakerId: String,
  addressLine1: String,
  addressLine2: String,
  pincode: String,
  state: String,
  district: String,
};

interface AccordionItem {
  header: React.ReactNode;
  content: React.ReactNode;
}

//http://34.144.216.118/api/v1/designated-court/DC1720433882799
//http://34.144.216.118/api/v1/regulator/RG1720439677276
// http://34.144.216.118/api/v1/deposit-taker/DT172042094
// http://34.144.216.118/api/v1/competent-authority/CA1720446108255
const urlForEntity: any = {
  DT: "deposit-taker",
  CA: "competent-authority",
  RG: "regulator",
  DC: "designated-court",
};

let dataAccess: any = {
  DT: {
    firstKey: "depositTaker",
    secondKey: "depositTakerFormData",
  },
  CA: {
    firstKey: "competentAuthority",
    secondKey: "competentAuthorityData",
  },
  RG: {
    firstKey: "regulator",
    secondKey: "regulatorFormData",
  },
  DC: {
    firstKey: "designatedCourt",
    secondKey: "designatedCourtFormData",
  },
};

const SchemeMasterForm = () => {
  const location = useLocation();
  const Navigate = useNavigate();

  const createdBy: "DT" | "CA" | "DC" | "RG" =
    location.state?.createdBy?.substring(0, 2);
  const createdByEntity = location.state?.createdBy;

  const uniqueId = location.state?.uniqueId;

  const depositTakerId = location.state?.depositTakerId;
  const [entityDetailsFields, setEntityDetailsFields] = useState<any[]>([]);

  const schemeId = location.state?.schemeId;
  const [page, setPage] = useState<number>(1);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const [loader3, setLoader3] = useState(true);
  const [pageSize, setPageSize] = useState<number>(2);
  const [dataBranch, setDataBranch] = useState([]);
  const [dataBranches, setDataBranches] = useState([]);
  const [createdByFormFields, setCreatedByFormFields] = useState<any>([]);
  const { setAllFormData, setAllDocumentData, allFormData } =
    useDepositTakerRegistrationStore((state) => state);

  const columnHelper = createColumnHelper<typeof TableType>();
  console.log("brancheds- data", allFormData);

  const fetchSchema = async () => {
    try {
      setLoader(true);
      const response = await axiosUAMInstance.get(
        `/scheme/field-data/${createdBy === "DT" ? 1 : 2}`
      );
      // console.log(response, "response");
      if (response.data.success) {
        const portalResponse = await axiosUAMInstance.get(
          `/scheme-portal/${uniqueId}`
        );

        const userData = portalResponse.data?.data?.schemes[0];
        let formFields = response?.data?.data?.formFields?.allFormFields.map(
          async (field: any) => {
            if (field?.key === "depositTakerId") {
              return {
                ...field,
                userInput: userData?.schemeFormData?.find(
                  (f: any) => f?.fieldId === field?.id
                )?.value,
                error: "",
                disabled: true,
                typeId: field?.fieldTypeId,
                dropdown_options: {
                  ...field?.dropdown_options,
                  options: field?.dropdown_options?.options?.map((o: any) => ({
                    name: o?.uniqueId,
                    id: o?.companyName,
                  })),
                },
              };
            } else if (field?.key === "branch") {
              try {
                const res = await axiosUAMInstance.get(
                  "/deposit-taker/branch/" + location.state.depositTakerId
                );
                let data = res.data;
                let branches = data?.data?.branches?.map((b: any) => {
                  return {
                    name: b?.pinCode + " " + b?.district + " " + b?.state,
                    id: b?.id,
                  };
                });

                return {
                  ...field,
                  userInput: userData?.schemeFormData?.find(
                    (f: any) => f?.fieldId === field?.id
                  )?.value,
                  disabled: true,
                  error: "",
                  typeId: field?.fieldTypeId,
                  dropdown_options: {
                    ...field?.dropdown_options,
                    options: branches,
                  },
                };
              } catch (error) {
                return {
                  ...field,
                  disabled: true,
                  userInput: userData?.schemeFormData?.find(
                    (f: any) => f?.fieldId === field?.id
                  )?.value,
                  error: "",
                  typeId: field?.fieldTypeId,
                };
              }
            } else {
              return {
                ...field,
                disabled: true,
                userInput: userData?.schemeFormData?.find(
                  (f: any) => f?.fieldId === field?.id
                )?.value,
                error: "",
                typeId: field?.fieldTypeId,
              };
            }
          }
        );

        formFields = await Promise.all(formFields);

        setAllFormData({
          ...response?.data?.data,
          formFields: { form_fields: formFields },
          fieldTypes: response?.data?.data?.fieldTypes,
          validations: response?.data?.data?.validations,
          fileTypes: response?.data?.data?.fileTypes,
          other: userData,
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching schema data:", error);
    }
  };

  useEffect(() => {
    if (uniqueId) {
      fetchSchema();
    }
  }, [uniqueId]);
  useEffect(() => {
    if (createdBy) {
      fetchDataforCreatedByEntity();
    }
  }, [createdBy]);
  const fetchFormFields = () => {
    setLoader2(true);
    axiosUAMInstance
      .get(`/registration/field-data/1?status=addToProfile`)
      .then(async (response) => {
        if (response?.data?.success) {
          let dtData: any = [];
          try {
            let depositTakerData = await axiosUAMInstance.get(
              `/deposit-taker/${depositTakerId}`
            );
            dtData =
              depositTakerData?.data?.data?.depositTaker?.depositTakerFormData;
          } catch (error) {
            console.log("Error");
          }
          let modifiedFormFields = response.data.data?.formFields
            ?.map((o: any) => ({
              ...o,
              userInput: dtData
                ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              error: "",
              disabled: true,
            }))
            ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder);

          // let modifiedFileFields =
          //   response?.data?.data?.registrationDocumentFields?.map((o: any) => ({
          //     ...o,
          //     file: dtData
          //       ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
          //       : "",
          //     error: "",
          //     fileName: dtData
          //       ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
          //       : "",
          //     uploadFileId: dtData
          //       ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
          //       : "",
          //     disabled: true,
          //   }));

          // let obj = {
          //   ...response?.data?.data,
          //   formFields: { form_fields: modifiedFormFields },
          // };
          setEntityDetailsFields(modifiedFormFields);
          setLoader2(false);
          // setAllDocumentData(modifiedFileFields);
        } else {
          setLoader2(false);
          alert("Error getting data, Please try later!");
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoader2(true);
      });
  };

  const fetchDataforCreatedByEntity = () => {
    setLoader3(true);
    let url = urlForEntity[createdBy] + "/" + createdByEntity;
    axiosUAMInstance
      .get(`/${url}`)
      .then((res) => {
        let data = res.data;
        if (data?.success) {
          let fields =
            data?.data[dataAccess[createdBy]["firstKey"]][
              dataAccess[createdBy]["secondKey"]
            ];
          setCreatedByFormFields(fields);
        } else {
          setCreatedByFormFields([]);
        }
      })
      .finally(() => {
        setLoader3(false);
      });
  };

  useEffect(() => {
    fetchFormFields();
  }, [depositTakerId]);

  const columns = [
    columnHelper.accessor("sno", {
      header: () => <span>Sr. No.</span>,
      cell: (info) => {
        // Calculate serial number based on current page and index of the row
        const serialNumber = (page - 1) * pageSize + (info.row.index + 1);
        return <span>{serialNumber}</span>;
      },
    }),
    columnHelper.accessor("addressLine1", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 1</span>,
    }),
    columnHelper.accessor("addressLine2", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 2</span>,
    }),
    columnHelper.accessor("state", {
      cell: (info) => info.renderValue(),
      header: () => <span>State</span>,
    }),
    columnHelper.accessor("district", {
      cell: (info) => info.renderValue(),
      header: () => <span>District</span>,
    }),
    columnHelper.accessor("pincode", {
      cell: (info) => info.renderValue(),
      header: () => <span>Pin Code</span>,
    }),
  ];

  console.log({ createdByEntity });

  const accordionItems: AccordionItem[] = [
    {
      header: "Scheme Details",
      // content: <SchemeDetails formData={allFormData} branches={dataBranch} />,
      // content: useRenderDynamicFields({
      //   formFields: allFormData?.formFields?.form_fields?.sort(
      //     (a: any, b: any) => a.sortOrder - b.sortOrder
      //   ),
      //   fieldTypes: allFormData?.fieldTypes,
      //   loader: loader,
      // }),
      content: (
        <>
          {useRenderDynamicFields({
            // formFields: allFormData?.formFields?.form_fields?.sort(
            //   (a: any, b: any) => a.sortOrder - b.sortOrder
            // ),
            formFields: allFormData?.formFields?.form_fields
              ?.filter((field: any) => field.key !== "branch") // Exclude fields with 'branch' key
              .sort((a: any, b: any) => a.sortOrder - b.sortOrder), // Sort the remaining fields by sortOrder
            fieldTypes: allFormData?.fieldTypes,
            loader: loader,
          })}
          <div className="mt-4">
            <BranchDetails />
          </div>
        </>
      ),
    },
    {
      header: "Entity Details",
      content: useRenderDynamicFields({
        formFields: entityDetailsFields?.sort(
          (a: any, b: any) => a.sortOrder - b.sortOrder
        ),
        fieldTypes: allFormData?.fieldTypes,
        loader: loader2,
      }),
    },
    {
      header: "Created by",
      content: useRenderDynamicFields({
        formFields: entityDetailsFields?.sort(
          (a: any, b: any) => a.sortOrder - b.sortOrder
        ),
        fieldTypes: allFormData?.fieldTypes,
        loader: loader3,
      }),
    },
    {
      header: "Audit Trail",
      content: <AuditTrail />,
    },
    {
      header: "Mangement Details",
      // content: <SchemeDetails formData={allFormData} branches={dataBranch} />,
      content: <MangementDetails />,
    },
  ];
  return (
    <div>
      <Layout
        layout={
          <div className="relative mx-2 xl:ml-[40px] mt-4">
            <div>
              <Accordion items={accordionItems} />
            </div>
            <div
              className="my-11 flex flex-col md:flex-row justify-between items-center"
              onClick={() => Navigate(-1)}
            >
              <div className="flex items-center cursor-pointer space-x-2 mb-3 md:mb-0">
                <img src={BackArrow} alt={BackArrow} />
                <p className="text-sm font-normal text-gilroy-regular">Back</p>
              </div>
            </div>
            <div className="border-b-2 border-[#E6E6E6]"></div>
            <div className="text-center">
              <h1 className="text-[#24222B] text-xs text-wrap text-gilroy-light mt-3 font-normal">
                © 2024 Protean BUDs, All Rights Reserved.
              </h1>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SchemeMasterForm;
