import React, { useEffect, useState } from "react";
import ReactTable from "../../components/common/ReactTable";
import CmsTabs from "../../components/cms/CmsTabs";
import Layout from "../../components/layout/Layout";
import useWebContentStore from "../../state/useWebContentStore";
import { useNavigate, useParams } from "react-router-dom";
import LoaderSpin from "../../components/LoaderSpin";
import { ColumnDef } from "@tanstack/react-table";
import ActionButton from "../../components/common/ActionButton";
import ReactTableWithoutBorder from "../../components/common/ReactTableWithoutBorder";
import Button from "../../components/form/Button";
import AddEditQuestionAnswers from "./AddEditQuestionAnswers";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

type Props = {};

interface FAQSections {
  id: number;
}

const CMSWebsiteContentInternalPageTwoQuestions = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const columns: ColumnDef<FAQSections>[] = [
    { accessorKey: "id", header: "ID" },
    {
      accessorKey: "edit",
      header: "Question & Answer",
      cell: (info: any) => {
        let data = info?.row?.original;
        return (
          <>
            <div className="">
              <div className="bg-[#ebf0fa] rounded-tr-md rounded-tl-md">
                <h1 className="font-semibold">Question</h1>
                <span>{data?.question}</span>
              </div>
              <div className="bg-gray-100">
                <h1 className="font-semibold">Answer</h1>
                <span>{data?.answer}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: (info: any) => {
        let data = info?.row?.original;
        console.log({ info });

        return (
          <ActionButton
            onClick={() => {
              setSelectedQA({
                a: data?.answer,
                q: data?.question,
                id: data?.id,
                idx: info?.row?.index,
                sectionId: id,
              });
              setIsOpenAddQuestionOpen(true);
            }}
            variant="edit"
          />
        );
      },
    },
  ];
  const [IsOpenAddQuestionOpen, setIsOpenAddQuestionOpen] =
    useState<boolean>(false);
  const { faqData, updateRefresh, setFaqData, refresh } = useWebContentStore();

  const fetchFaqData = async () => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/faq`
      );
      const data = response?.data?.data?.find(
        (d: any) => d?.language === "english"
      );
      console.log({ data });
      let modifiedFields =
        data?.content?.data?.faqPageData?.questionSections?.map(
          (subHead: any, i: number) => {
            return {
              id: i + 1,
              questions: subHead?.questions?.map((q: any, i: number) => {
                return {
                  id: i + 1,
                  ...q,
                };
              }),
              subHeading: subHead?.subHeading,
            };
          }
        );

      console.log({ modifiedFields });

      setFaqData({
        alldata: response?.data?.data,
        questionSections: modifiedFields,
      });
    } catch (error) {
      console.error("Failed to fetch FAQ data:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (faqData === null) {
      fetchFaqData();
    }
  }, [faqData, refresh]);

  useEffect(() => {
    if (faqData !== null && id) {
      setData(
        faqData?.questionSections?.find(
          (s: any, i: number) => i === parseInt(id)
        )
      );
    }
  }, [id, faqData]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedQA, setSelectedQA] = useState<{
    q: string;
    a: string;
    id: null | number;
    idx: number | null;
    sectionId: any;
  }>({
    q: "",
    a: "",
    id: null,
    idx: null,
    sectionId: parseInt(id ?? "0"),
  });

  const closeAddQuestion = () => {
    setIsOpenAddQuestionOpen(false);
  };
  const handleAPICall = async ({
    qaList,
    questionIdx,
    sectionId,
  }: {
    qaList: any[];
    sectionId: any;
    questionIdx: number | null;
  }) => {
    try {
      setLoader(true);
      let updated = null;
      if (questionIdx !== null) {
        updated = faqData?.alldata?.map((faq: any, index: number) => {
          const questionSections =
            faq?.content?.data?.faqPageData?.questionSections;
          let tempObj = {
            ...qaList?.find((qa: any) => qa?.language === faq?.language),
          };
          questionSections[parseInt(sectionId)].questions[questionIdx] = {
            question: tempObj?.question,
            answer: tempObj?.answer,
          };

          return {
            ...faq,
            content: {
              data: {
                faqPageData: {
                  ...faq?.content?.data?.faqPageData,
                  questionSections,
                },
              },
            },
          };
        });
        console.log({ updated });
      } else {
        updated = faqData?.alldata?.map((faq: any, index: number) => {
          const questionSections =
            faq?.content?.data?.faqPageData?.questionSections;
          let tempObj = {
            ...qaList?.find((qa: any) => qa?.language === faq?.language),
          };
          questionSections[parseInt(sectionId)].questions = [
            ...questionSections[parseInt(sectionId)].questions,
            { question: tempObj?.question, answer: tempObj?.answer },
          ];

          return {
            ...faq,
            content: {
              data: {
                faqPageData: {
                  ...faq?.content?.data?.faqPageData,
                  questionSections,
                },
              },
            },
          };
        });
      }
      const res = await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        updated
      );

      let data = res.data;
      if (data?.status === "success") {
        Swal.fire({
          icon: "success",
          text: data?.message,
          title: "Success",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: data?.message,
          title: "Error",
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      console.log("HEre I am");
      setIsOpenAddQuestionOpen(false);
      setLoader(false);
      fetchFaqData();
    }
  };

  console.log({ faqData });

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>

          <div className="mt-4">
            <div className=" my-6  flex justify-end items-end ">
              <Button
                // loader={loader}
                type="button"
                label="Add Question"
                onClick={() => {
                  setSelectedQA({
                    a: "",
                    q: "",
                    id: null,
                    idx: null,
                    sectionId: id,
                  });
                  setIsOpenAddQuestionOpen(true);
                }}
                loader={loader}
              />
            </div>
            <div className=" flex justify-center items-center my-3">
              <h1 className="text-lg text-gilroy-regular">
                FAQ Questions : {data?.subHeading}
              </h1>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div>
                {loader ? (
                  <LoaderSpin />
                ) : data?.questions?.length > 0 ? (
                  <ReactTableWithoutBorder
                    defaultData={data?.questions}
                    columns={columns}
                  />
                ) : (
                  <div className="flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
              <button
                className="my-2 py-2 px-4 rounded-lg"
                type="button"
                onClick={() => navigate(-1)}
              >
                &lt; Back
              </button>
            </div>
          </div>
          {IsOpenAddQuestionOpen && (
            <AddEditQuestionAnswers
              onClose={closeAddQuestion}
              editData={selectedQA}
              loader={loader}
              apiCall={handleAPICall}
            />
          )}
        </div>
      }
    />
  );
};

export default CMSWebsiteContentInternalPageTwoQuestions;
