import React, { FC, InputHTMLAttributes, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import InputFields from './InputField'
import uploadSvg from "../../assets/images/export.svg"
import searchButton from '../../assets/images/search-normal.svg';
import directboxSend1 from '../../assets/images/directboxSend1.svg';
interface Props extends InputHTMLAttributes<HTMLInputElement> {
    filename : string;
    imageSrc:string;
}

const UploadButton : FC<Props> = forwardRef<HTMLInputElement,Props>(({filename,imageSrc,...props}, ref) => {
    const handleClick = (event : any) => {
        const input = document.getElementById('inputButton');
        input?.click();
        event.preventDefault();
    }

    const classname = ' top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none justify-between align-middle]'
    return <>
    <div className='relative w-full'>
        <input
            {...props}
            ref={ref}
            className={classname}
            type="file"
            id='inputButton'
        />
        <button
        onClick={handleClick} 
        className={' top-0 w-full z-20 bg-[#1c468e] text-white form-input border px-[16px] py-[8px] flex   rounded-[8px] focus:outline-none focus:ring-1 focus:ring-gray-100 justify-center align-middle overflow-hidden'}>  
        <img src={imageSrc} alt="" className='mr-1 rounded-lg'/>
        <span className='relative'>{filename ? filename : "Browse files"}</span>
        </button>
    </div>
    </>
}) 

export default UploadButton