import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import Swal from "sweetalert2";
import add from "../../assets/images/add.svg";
import useWebContentStore from "../../state/useWebContentStore";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  content: string;
  link: string;
  image: File | null;
}

interface Props {
  onClose: () => void;
  currentEditData?: any;
  data?: any;
  apiCall?: () => void;
}

const EntityRegistrationOtpContentFive: React.FC<Props> = ({
  onClose,
  currentEditData,
  apiCall,
  data,
}) => {
  const { downloadsData } = useWebContentStore();
  const [loader, setLoader] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [dataToEdit, setDataToEdit] = useState<any>(undefined);

  useEffect(() => {
    switch (currentEditData?.section) {
      case "downloads":
        const downloads = downloadsData?.data?.map((d: any) => {
          return {
            ...d?.content?.data[0]?.downloadPageData?.downloads[
              currentEditData?.idx
            ],
            language: d?.language,
            error: "",
          };
        });
        setDataToEdit(downloads);
        break;
      case "add":
        const downloadsToAdd = downloadsData?.data?.map((d: any) => {
          return {
            ...d?.content?.data[0]?.downloadPageData?.downloads[
              currentEditData?.idx
            ],
            language: d?.language,
            error: "",
            text: "",
            img: "",
            link: "",
            name: "text",
          };
        });
        setDataToEdit(downloadsToAdd);
        break;

      case "heading":
        const headings = downloadsData?.data?.map((d: any) => ({
          ...d?.content?.data[0]?.downloadPageData?.heading[0],
          language: d?.language,
          error: "",
        }));
        setDataToEdit(headings);
        break;
      case "button":
        const button =
          downloadsData?.data[0]?.content?.data[0]?.downloadPageData?.button[0];
        const labels = downloadsData?.data?.map((d: any) => ({
          ...d?.content?.data[0]?.downloadPageData?.button[0],
          language: d?.language,
          error: "",
        }));
        setDataToEdit({ button, labelData: labels });
        break;
      default:
        break;
    }
  }, [currentEditData]);

  const handleValidations = async (): Promise<boolean> => {
    let error = 0;
    switch (currentEditData?.section) {
      case "add":
      case "downloads":
      case "heading":
        let updated = dataToEdit?.map((d: any) => {
          if (d?.text?.trim() === "") {
            error += 1;
            return { ...d, error: "Field cannot be empty!" };
          } else {
            return { ...d, error: "" };
          }
        });
        setDataToEdit(updated);
        break;
      case "button":
        let updatedbuttonLabels = dataToEdit?.labelData?.map((d: any) => {
          if (d?.text?.trim() === "") {
            error += 1;
            return { ...d, error: "Field cannot be empty!" };
          } else {
            return { ...d, error: "" };
          }
        });
        setDataToEdit(updatedbuttonLabels);
        break;
      default:
        break;
    }

    return error === 0;
  };

  const handleFormSubmit = async (event: any) => {
    event?.preventDefault();
    // setLoader(true);
    let error = await handleValidations();
    console.log({ error });

    if (!error) {
      return;
    }
    let updated = [...downloadsData?.data];
    setLoader(true);
    switch (currentEditData?.section) {
      case "downloads":
        updated = updated?.map((u: any, i) => {
          let downloads = u?.content?.data[0]?.downloadPageData?.downloads;
          let matchObj = dataToEdit?.find(
            (d: any) => d?.language === u?.language
          );
          delete matchObj.error;
          delete matchObj.language;
          downloads[currentEditData?.idx] = matchObj;
          downloads = downloads;
          return {
            ...u,
            content: {
              data: [
                {
                  downloadPageData: {
                    ...u?.content?.data[0]?.downloadPageData,
                    downloads,
                  },
                },
              ],
            },
          };
        });
        console.log({ updated });

        break;
      case "add":
        console.log("yoo");
        updated = updated?.map((u: any, i) => {
          let downloads = u?.content?.data[0]?.downloadPageData?.downloads;
          let matchObj = dataToEdit?.find(
            (d: any) => d?.language === u?.language
          );
          delete matchObj.error;
          delete matchObj.language;
          downloads = [...downloads, matchObj];
          return {
            ...u,
            content: {
              data: [
                {
                  downloadPageData: {
                    ...u?.content?.data[0]?.downloadPageData,
                    downloads,
                  },
                },
              ],
            },
          };
        });
        console.log({ updated });

        break;
      case "heading":
        updated = updated?.map((d: any) => {
          let headings = d?.content?.data[0]?.downloadPageData?.heading[0];

          let newHeading = dataToEdit?.find(
            (f: any) => f?.language === d?.language
          )?.text;
          return {
            ...d,
            content: {
              data: [
                {
                  downloadPageData: {
                    ...d?.content?.data[0]?.downloadPageData,
                    heading: [
                      {
                        ...headings,
                        text: newHeading,
                      },
                    ],
                  },
                },
              ],
            },
          };
        });
        console.log({ updated });
        break;
      case "button":
        updated = updated?.map((d: any) => {
          let button = d?.content?.data[0]?.downloadPageData?.button[0];

          let newButtonLabel = dataToEdit?.labelData?.find(
            (f: any) => f?.language === d?.language
          )?.text;
          return {
            ...d,
            content: {
              data: [
                {
                  downloadPageData: {
                    ...d?.content?.data[0]?.downloadPageData,
                    button: [
                      {
                        ...button,
                        text: newButtonLabel,
                        img: dataToEdit?.button?.img,
                      },
                    ],
                  },
                },
              ],
            },
          };
        });

        break;
      default:
        break;
    }

    setLoader(false);

    const response = axiosUAMInstance
      .post(`/websitecontent/update-many`, updated)
      .then((res) => {
        let data = res.data;
        if (data?.status === "success") {
          Swal.fire({
            icon: "success",
            text: data?.message,
            title: "Updated",
          });
          if (apiCall) {
            apiCall();
          }
        } else {
          Swal.fire({
            icon: "error",
            text: data?.message,
            title: "Error",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: data?.message,
          title: "Error",
        });
      })
      .finally(() => {
        setLoader(false);
        onClose();
      });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // setValue("image", file);
      const fileUrl = URL.createObjectURL(file);
      setImagePreviewUrl(fileUrl);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          const updatedData = [...dataToEdit];
          updatedData[index] = {
            ...updatedData[index],
            link: uploadResponse?.data?.data?.fileurl,
          };

          // Update the state with the new array
          setDataToEdit(updatedData);

          setImagePreviewUrl(uploadResponse?.data?.data?.fileurl);
          // setValue("link", uploadResponse?.data?.data?.fileurl);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    } else {
      // setValue("image", null);
      setImagePreviewUrl(null);
    }
  };
  const handleIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // setValue("image", file);
      const fileUrl = URL.createObjectURL(file);
      setImagePreviewUrl(fileUrl);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          setDataToEdit({
            ...dataToEdit,
            button: {
              ...dataToEdit?.button,
              img: uploadResponse?.data?.data?.fileurl,
            },
          });

          setImagePreviewUrl(uploadResponse?.data?.data?.fileurl);
          // setValue("link", uploadResponse?.data?.data?.fileurl);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    } else {
      // setValue("image", null);
      setImagePreviewUrl(null);
    }
  };

  const handleOnChange = (event: any, index: number) => {
    const { value } = event?.target;
    switch (currentEditData?.section) {
      case "downloads":
      case "add":
      case "heading":
        const updatedData = [...dataToEdit];
        updatedData[index] = {
          ...updatedData[index],
          text: value,
          error: value?.trim() === "" ? "Field cannot be empty" : "",
        };

        // Update the state with the new array
        setDataToEdit(updatedData);
        break;
      case "button":
        let labels = dataToEdit?.labelData;

        labels[index] = {
          ...labels[index],
          text: value,
          error: value?.trim() === "" ? "Field cannot be empty" : "",
        };

        setDataToEdit({
          ...dataToEdit,
          labelData: labels,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          onSubmit={handleFormSubmit}
          className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white"
        >
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Edit Download
          </h1>
          <div
            className="custom-scrollbar p-4"
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {(currentEditData?.section === "downloads" ||
              currentEditData?.section === "add") &&
              dataToEdit?.map((data: any, i: number) => {
                return (
                  <div className="my-1" key={i}>
                    <h1 className="bg-gray-400 rounded-xl p-2 text-center">
                      {data?.language}
                    </h1>
                    <div className="my-1">
                      <label
                        htmlFor="content"
                        className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                      >
                        Label&nbsp;<span className="text-red-400">*</span>:
                      </label>
                      <InputFields
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="type here"
                        value={data?.text}
                        onChange={(e) => handleOnChange(e, i)}
                      />
                      <span className="text-red-400">{data?.error}</span>
                    </div>
                    <div className="my-1">
                      <label
                        htmlFor="image"
                        className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                      >
                        Document:
                      </label>
                      <input
                        type="file"
                        onChange={(e) => handleImageChange(e, i)}
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                      {data?.link && (
                        <img
                          src={data?.link}
                          alt="Preview"
                          className="mt-4 w-10"
                        />
                      )}
                    </div>

                    <div className="my-1">
                      <label
                        htmlFor="link"
                        className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                      >
                        Link:
                      </label>
                      <InputFields
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="type here"
                        disabled={true}
                        value={data?.link}
                      />
                    </div>
                  </div>
                );
              })}
            {currentEditData?.section === "heading" &&
              dataToEdit?.map((data: any, i: number) => {
                return (
                  <div className="my-1" key={i}>
                    <h1 className="bg-gray-400 rounded-xl p-2 text-center">
                      {data?.language}
                    </h1>
                    <div className="my-1">
                      <label
                        htmlFor="content"
                        className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                      >
                        Heading&nbsp;<span className="text-red-400">*</span>:
                      </label>
                      <InputFields
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="type here"
                        value={data?.text}
                        onChange={(e) => handleOnChange(e, i)}
                      />
                      <span className="text-red-400">{data?.error}</span>
                    </div>
                  </div>
                );
              })}
            {currentEditData?.section === "button" && (
              <>
                <div className="my-1">
                  <div className="my-1">
                    <label
                      htmlFor="image"
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Upload Icon:
                    </label>
                    <input
                      type="file"
                      onChange={handleIconChange}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="my-1">
                    <label
                      htmlFor="link"
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link:
                    </label>
                    <InputFields
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="type here"
                      disabled={true}
                      value={dataToEdit?.button?.img}
                    />
                  </div>
                  <h1>Preview : </h1>
                  {dataToEdit?.button?.img && (
                    <img
                      src={dataToEdit?.button?.img}
                      alt="Preview"
                      className="mt-4 w-10"
                    />
                  )}
                </div>
                <h1 className="my-3">Labels : </h1>
                {dataToEdit?.labelData?.map((data: any, i: number) => {
                  return (
                    <div className="my-1" key={i}>
                      <h1 className="bg-gray-400 rounded-xl p-2 text-center">
                        {data?.language}
                      </h1>
                      <div className="my-1">
                        <label
                          htmlFor="content"
                          className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                        >
                          Label&nbsp;<span className="text-red-400">*</span>:
                        </label>
                        <InputFields
                          className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="type here"
                          value={data?.text}
                          onChange={(e) => handleOnChange(e, i)}
                        />
                      </div>
                      <span className="text-red-400">{data?.error}</span>
                    </div>
                  );
                })}
              </>
            )}
            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <ButtonComp onClose={onClose} loader={loader} />
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContentFive;
