// import React, { useEffect, useState, useRef } from "react";

// interface Option {
//   value: string;
//   label: string;
// }

// type Props = {
//   setOption: (value: string) => void;
//   options: Option[];
//   selectedOption?: string | null;
//   placeholder: string;
//   searchInputOnchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   searchInputValue?: string;
//   bgColor?: string;
//   mdWidth?: string;
//   borderColor?: string;
// };

// const SelectButtonSchemeMaster = ({
//   setOption,
//   options,
//   selectedOption,
//   placeholder,
//   searchInputOnchange,
//   searchInputValue,
//   bgColor = "#FFFFFF",
//   mdWidth = "md:w-[210px]",
//   borderColor,
// }: Props) => {
//   const [arrowDirectionToggle, setArrowDirectionToggle] = useState(false);
//   const dropdownRef = useRef<HTMLDivElement>(null);

//   const handleClickOutside = (event: MouseEvent) => {
//     if (
//       dropdownRef.current &&
//       !dropdownRef.current.contains(event.target as Node)
//     ) {
//       setArrowDirectionToggle(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     setArrowDirectionToggle(false);
//   }, [selectedOption]);

//   return (
//     <div className={`relative w-full min-w-[210px]`} ref={dropdownRef}>
//       <button
//         className={`w-full flex justify-between items-center border ${
//           borderColor ? "border-[#1C468E]" : "border-gray-300"
//         } rounded-md shadow-sm px-4 py-2 text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-gray-300 text-left`}
//         type="button"
//         onClick={() => setArrowDirectionToggle(!arrowDirectionToggle)}
//         style={{
//           height: "55px",
//           padding: "8px 16px",
//           marginTop: "8px",
//           borderRadius: "8px",
//           backgroundColor: bgColor,
//         }}
//       >
//         {selectedOption || placeholder}
//         <svg
//           className="w-4 h-4 ml-2 -mr-1"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth={2}
//             d={arrowDirectionToggle ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
//           />
//         </svg>
//       </button>
//       {arrowDirectionToggle && (
//         <div
//           className="absolute w-full rounded-md bg-white shadow-lg z-50"
//           role="menu"
//           aria-orientation="vertical"
//           aria-labelledby="options-menu"
//           style={{ backgroundColor: bgColor }}
//         >
//           <div
//             className="w-full overflow-auto max-h-40 custom-scrollbar"
//             role="menu"
//             aria-orientation="vertical"
//             aria-labelledby="options-menu"
//           >
//             {options.map((option, index) => (
//               <a
//                 key={index}
//                 onClick={() => setOption(option.value)}
//                 href="#"
//                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-50 hover:text-gray-900"
//                 role="menuitem"
//               >
//                 {option.label}
//               </a>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SelectButtonSchemeMaster;
import React, { useEffect, useState, useRef } from "react";

interface Option {
  value: string;
  label: string;
}

type Props = {
  setOption: (value: string) => void;
  options: Option[];
  selectedOption?: string | null;
  placeholder: string;
  searchInputOnchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInputValue?: string;
  bgColor?: string;
  mdWidth?: string;
  borderColor?: string;
  disabled?: boolean;
};

const SelectButtonSchemeMaster = ({
  setOption,
  options,
  selectedOption,
  placeholder,
  searchInputOnchange,
  searchInputValue,
  bgColor = "#ffffff",
  mdWidth = "md:w-[210px]",
  borderColor,
  disabled = false,
}: Props) => {
  const [arrowDirectionToggle, setArrowDirectionToggle] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setArrowDirectionToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setArrowDirectionToggle(false);
  }, [selectedOption]);

  return (
    // <div className={`relative w-[350px] min-w-[210px]`} ref={dropdownRef}>
    <div className={`relative min-w-[210px]`} ref={dropdownRef}>
      <button
        className={`w-full flex justify-between items-center border ${
          borderColor ? borderColor : "border-[#bbbaba]"
        } rounded-md shadow-sm px-4 py-2 text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-gray-300 text-left ${
          disabled ? "cursor-not-allowed" : ""
        }`}
        type="button"
        onClick={() =>
          !disabled && setArrowDirectionToggle(!arrowDirectionToggle)
        }
        style={{
          height: "55px",
          padding: "8px 16px",
          marginTop: "8px",
          borderRadius: "8px",
          backgroundColor: bgColor,
        }}
        disabled={disabled}
      >
        {selectedOption || placeholder}
        <svg
          className="w-4 h-4 ml-2 -mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={arrowDirectionToggle ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
          />
        </svg>
      </button>
      {arrowDirectionToggle && !disabled && (
        <div
          className="absolute w-full rounded-md bg-white shadow-lg z-50"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          style={{ backgroundColor: bgColor }}
        >
          <div
            className="w-full overflow-auto max-h-40 custom-scrollbar"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option, index) => (
              <a
                key={index}
                onClick={() => setOption(option.value)}
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-50 hover:text-gray-900"
                role="menuitem"
              >
                {option.label}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectButtonSchemeMaster;
