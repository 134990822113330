// import React, { useState } from "react";
// import Layout from "../components/layout/Layout";
// import DoubleBarChart from "../components/charts/DoubleBarChart";
// import TotalFoundationLineChart from "../components/charts/TotalFoundationLineChart";
// import DashboardTabsContainer from "../components/dashboardTabs/DashboardTabsContainer";
// import { axiosUAMInstance } from "../utils/axios";
// import { useEffect } from "react";

// type DashboardCTab = {
//   totalDepositTakerRegistered?: number;
//   totalDepositTakerApproved?: number;
//   totalDepositTakerPending?: number;
//   totalSchemeRegistered?: number;
//   totalSchemeBanned?: number;
//   totalSchemeUnderLetigation?: number;
// };

// const Dashboard = () => {
//   const [dashBoardTabs, setDashboardTabsData] = useState<DashboardCTab[]>([]);

//   useEffect(() => {
//     dashboardCaApi();
//   }, []);

//   const dashboardCaApi = () => {
//     // setLoader(true);
//     // setHomePageData(data.data.content)

//     axiosUAMInstance
//       .get(`/dashboard?type=admin`, {})
//       .then((response) => {
//         setDashboardTabsData(response?.data?.data);
//         console.log("response", response);
//         // setLoader(false);
//       })
//       .catch((error) => {
//         console.log(error);
//         // setLoader(false);
//       });
//   };

//   const tabsData = [
//     {
//       text: "Deposite Taker Registered",
//       value: dashBoardTabs[0]?.totalDepositTakerRegistered?.toString(),
//       bgColor: true,
//     },
//     {
//       text: "Total Deposite Taker Approved",
//       value: dashBoardTabs[1]?.totalDepositTakerApproved?.toString(),
//       bgColor: false,
//     },
//     {
//       text: "Deposite taker approval pending",
//       value: dashBoardTabs[2]?.totalDepositTakerPending?.toString(),
//       bgColor: true,
//     },
//     {
//       text: "Total Schemes Registered",
//       value: dashBoardTabs[3]?.totalSchemeRegistered?.toString(),
//       bgColor: false,
//     },
//     {
//       text: "Total Schemes Banned",
//       value: dashBoardTabs[4]?.totalSchemeBanned?.toString(),
//       bgColor: false,
//     },
//     {
//       text: "Total Schemes Under Litigation",
//       value: dashBoardTabs[5]?.totalSchemeUnderLetigation?.toString(),
//       bgColor: true,
//     },
//   ];

//   const chartData = [
//     {
//       name: "Jan",
//       uv: 4000,
//       pv: 2400,
//       amt: 2400,
//     },
//     {
//       name: "Feb",
//       uv: 3000,
//       pv: 1398,
//       amt: 2210,
//     },
//     {
//       name: "Mar",
//       uv: 2000,
//       pv: 9800,
//       amt: 2290,
//     },
//     {
//       name: "Apr",
//       uv: 2780,
//       pv: 3908,
//       amt: 2000,
//     },
//     {
//       name: "May",
//       uv: 1890,
//       pv: 4800,
//       amt: 2181,
//     },
//   ];
//   return (
//     <div>
//       <Layout
//         layout={
//           <div className="relative xl:ml-[20px] mb-5 p-5">
//             <h1 className="text-[25px] font-[400] mb-3">Business Statistics</h1>
//             <div className="mb-[20px]">
//               <DashboardTabsContainer tabsData={tabsData} />
//             </div>

//             <div className="w-[100%] gap-[20px]  flex justify-between flex-wrap">
//               <div className="w-[100%] sm:w-[48%] xl:w-[31.5%]">
//                 <DoubleBarChart chartData={chartData} />
//               </div>
//               <div className="w-[100%] sm:w-[48%] xl:w-[31.5%]">
//                 <TotalFoundationLineChart />
//               </div>
//               <div className="w-[100%] sm:w-[48%] xl:w-[31.5%]">
//                 <DoubleBarChart chartData={chartData} />
//               </div>
//             </div>
//           </div>
//         }
//       />
//     </div>
//   );
// };

// export default Dashboard;
import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import DoubleBarChart from "../components/charts/DoubleBarChart";
import TotalFoundationLineChart from "../components/charts/TotalFoundationLineChart";
import DashboardTabsContainer from "../components/dashboardTabs/DashboardTabsContainer";
import { axiosUAMInstance } from "../utils/axios";
import { useEffect } from "react";
import NewTotalFoundationLineChart from "../components/charts/newTotalFoundationLineChart";
import NewDoubleBarChart from "../components/charts/NewDoubleBarChart";

type DashboardCTab = {
  totalDepositTakerRegistered?: number;
  totalDepositTakerApproved?: number;
  totalDepositTakerPending?: number;
  totalSchemeRegistered?: number;
  totalSchemeBanned?: number;
  totalSchemeUnderLetigation?: number;
};

const DashboardPage = () => {
  const [dashBoardTabs, setDashboardTabsData] = useState<DashboardCTab[]>([]);
  const [timeframe, setTimeframe] = useState("annually"); // Default to 'annually'
  const timeframes = ["annually", "quarterly", "monthly"]; // List of timeframes

  useEffect(() => {
    dashboardCaApi();
  }, []);

  const dashboardCaApi = () => {
    // setLoader(true);
    // setHomePageData(data.data.content)

    axiosUAMInstance
      .get(`/dashboard?type=admin`, {})
      .then((response) => {
        setDashboardTabsData(response?.data?.data);
        console.log("response", response);
        // setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoader(false);
      });
  };

  const tabsData = [
    {
      text: "Deposit Taker Registered",
      value: dashBoardTabs[0]?.totalDepositTakerRegistered?.toString(),
      bgColor: true,
    },
    {
      text: "Total Deposit Taker Approved",
      value: dashBoardTabs[1]?.totalDepositTakerApproved?.toString(),
      bgColor: false,
    },
    {
      text: "Deposit taker approval pending",
      value: dashBoardTabs[2]?.totalDepositTakerPending?.toString(),
      bgColor: true,
    },
    {
      text: "Total Schemes Registered",
      value: dashBoardTabs[3]?.totalSchemeRegistered?.toString(),
      bgColor: false,
    },
    {
      text: "Total Schemes Banned",
      value: dashBoardTabs[4]?.totalSchemeBanned?.toString(),
      bgColor: false,
    },
    {
      text: "Total Schemes Under Litigation",
      value: dashBoardTabs[5]?.totalSchemeUnderLetigation?.toString(),
      bgColor: true,
    },
  ];
  const chartData = [
    { name: "Reg 08", clean: 70, others: 30 },
    { name: "Reg 03", clean: 60, others: 40 },
    { name: "Reg 06", clean: 40, others: 60 },
    { name: "Reg 07", clean: 100, others: 90 },
    { name: "Reg 01", clean: 90, others: 10 },
    { name: "Reg 09", clean: 90, others: 10 },
    { name: "Reg 02", clean: 80, others: 20 },
    { name: "Reg 05", clean: 20, others: 80 },
    { name: "Reg 04", clean: 10, others: 90 },
  ];
  const quarterlyChartData = [
    { name: "Q1", clean: 85, others: 15 },
    { name: "Q2", clean: 78, others: 22 },
    { name: "Q3", clean: 89, others: 11 },
    { name: "Q4", clean: 92, others: 80 },
  ];
  const annualChartData = [
    { name: "2023", clean: 86, others: 14 },
    { name: "2022", clean: 81, others: 19 },
    { name: "2021", clean: 88, others: 12 },
    { name: "2020", clean: 86, others: 14 },
    { name: "2019", clean: 81, others: 19 },
  ];

  const handleRadioChange = (event: any) => {
    setTimeframe(event.target.value);
  };
  return (
    <div>
      <Layout
        layout={
          <div className="relative xl:ml-[20px] mb-5 p-5">
            <h1 className="text-[25px] font-[400] mb-3">Business Statistics</h1>
            <div className="mb-[20px]">
              <DashboardTabsContainer tabsData={tabsData} />
            </div>
            <div className="w-[100%] gap-[20px]  flex justify-between flex-wrap">
              <div className="w-[100%] sm:w-[48%] md:order-1">
                <NewDoubleBarChart
                  chartData={chartData.slice(0, 5)}
                  title="Top 5 Regulator"
                  description="represent % deposit taker with all clean schemes"
                />
              </div>
              <div className="w-[100%] sm:w-[48%] md:order-1">
                <NewDoubleBarChart
                  chartData={chartData.slice(-5)}
                  title="Bottom 5 Regulator"
                  description="represent % deposit taker with all banned and under litigation schemes"
                />
              </div>
            </div>
            <div>
              <div className="mb-[20px] flex gap-[15px] md:order-2 mt-4">
                {timeframes.map((time) => (
                  <label key={time} className="flex items-center">
                    <input
                      type="radio"
                      name="timeframe"
                      value={time}
                      checked={timeframe === time}
                      onChange={handleRadioChange}
                    />
                    <span className="ml-[8px] capitalize">{time}</span>
                  </label>
                ))}
              </div>
              <div className="w-[100%] sm:w-[48%]  md:w-[100%] md:order-3">
                <NewTotalFoundationLineChart intervalType={timeframe} />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DashboardPage;
