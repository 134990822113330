import { useState, useEffect } from "react";
import CmsTabsItem from "./CmsTabsItem";
import { Link, useLocation } from "react-router-dom";

type Props = {};

const tabs = [
  { title: "Registration", url: "register", rurl: "/cms/register" },
  { title: "Scheme", url: "scheme", rurl: "/cms/scheme" },
  { title: "Templates", url: "templates", rurl: "/cms/templates" },

  { title: "Master", url: "master", rurl: "/cms/master/master" },

  {
    title: "Website Content",
    url: "websitecontent",
    rurl: "/cms/websitecontent",
  },
  // {
  //   title: "Website Notifications",
  //   url: "websitenotifications",
  //   rurl: "/cms/websitenotifications",
  // },
];

const CmsTabs = (props: Props) => {
  const [activeTab, setActiveTab] = useState<string>("Registration");
  const [url, setUrl] = useState<String>("");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const cmsPath = pathname.split("/")[2];
    setUrl(cmsPath);
  }, []);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <div className="flex-col justify-center items-start inline-flex border-b w-full">
      <ul className="justify-start items-center gap-5 md:gap-10 inline-flex flex-wrap">
        {tabs.map((menuItem, index) => (
          <Link to={menuItem?.rurl} key={index}>
            <CmsTabsItem
              key={index}
              text={menuItem?.title}
              isActive={menuItem?.url === url}
              onClick={() => handleTabClick(menuItem?.title)}
            />
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default CmsTabs;
