import { create } from 'zustand';

interface CMSFormControl {
    roleUpdate : boolean,
    handleRoleAddedState: () => void;
}
const uamStore = create<CMSFormControl>(set => ({
    roleUpdate: false,
    handleRoleAddedState : () => set(state => ({ roleUpdate : !state.roleUpdate}))
}));

export default uamStore;