import React, { useState, ChangeEvent, FormEvent } from "react";
import add from "../../assets/images/add.svg";
import TextArea from "../common/TextArea";
import SignUpFormItems from "./SignUpFormItems";
import ButtonComp from "./ButtonComp";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import addButton from "../../assets/images/addButton.svg";
import InputFields from "./InputField";
import uploadSvg from "../../assets/images/new_images/upload.png";
import NotificationFileUpload from "../common/NotificationFileUpload";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface Props {
  onClose: () => void;
}

const NotificationsModal: React.FC<Props> = ({ onClose }) => {
  const [text, setText] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const countWords = (text: string) => {
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/);
    return words.length;
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const tabs: string[] = ["ENTITY_TYPE", "OTP_CODE"];

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[36px] shadow-xl backdrop-blur-lg bg-white">
            <div
              className="flex flex-row justify-end mb-[12px] cursor-pointer"
              onClick={handleClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
              Notifications
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="md:px-[32px] px-[16px]">
                <label
                  htmlFor="documentName"
                  className=" text-black text-[16px] font-normal   text-gilroy-medium"
                >
                  Name
                </label>
                <InputFields
                  id="documentName"
                  className="mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] mb-[24px] border  border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                  placeholder="type here"
                />
                <label
                  htmlFor="bodyText"
                  className=" text-black text-[16px] font-normal  mb-[4px] text-gilroy-medium "
                >
                  Body Text
                </label>
                <TextArea
                  value={text}
                  id="bodyText"
                  onChange={handleChange}
                  placeholder="Type your reason here"
                  className="text-[16px] h-[90px] border  border-[#E6E6E6] rounded-[16px] w-[464px] mt-[4px] p-[16px] focus:outline-none focus:ring-1 focus:ring-gray-100 textarea-component"
                />
                <div className=" flex justify-end mt-[9px]">
                  <p className=" text-right text-black text-opacity-40 text-xs font-normal leading-[14px]">
                    {countWords(text)}/500
                  </p>
                </div>
                <div>
                  <h1 className="mt-[8px]  text-black text-base font-normal font-gilroy-medium">
                    Upload
                  </h1>
                  <NotificationFileUpload filename="Add File" icon={uploadSvg} />
                </div>
                <hr className="w-full bg-[#E6E6E6] mt-[32px] mb-[24px]"></hr>
                <ButtonComp onClose={handleClose} />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationsModal;
