import { createColumnHelper } from "@tanstack/react-table";
import add from "../../assets/images/add.svg";
import Button from "../common/Button";
import ReactTable from "../common/ReactTable";
import { Box, Modal } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ToggleSwitch from "../common/ToggleSwitch";
import LoaderSpin from "../LoaderSpin";
import useCmsFormControlStore from "../../state/cmsFormControl";
import UpArrow from "../../assets/images/BlueUpArrow.svg";
import DownArrow from "../../assets/images/BlueDownArrow.svg";
import { axiosUAMInstance } from "../../utils/axios";

interface Props {
  handleOpenClose: () => void;
  defaultData?: any[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

type Fields = {
  sq: number;
  label: string;
  addToSchemeCreation: boolean;
};
const manageFormFieldsHelper = createColumnHelper<Fields>();

const ManageFormFieldsForRegistrationScheme: FC<Props> = ({
  handleOpenClose,
  defaultData = [],
}) => {
  const [formFields, setDefaultData] = useState<any[]>(defaultData);
  const [data, setData] = useState<any[]>(
    defaultData
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((s, i) => ({ ...s, sq: i + 1 }))
  );
  const formFieldChangeState = useCmsFormControlStore(
    (state) => state.updatedFormField
  );
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const handleOnChange = (value: any, index: number, key: string) => {
    let tmp = formFields.map((obj) => {
      if (obj.id === index) {
        return { ...obj, [key]: value };
      } else {
        return obj;
      }
    });
    setDefaultData(tmp);
    setData([]);
    setTimeout(() => {
      setData(
        tmp
          ?.sort((a, b) => a.sortOrder - b.sortOrder)
          ?.map((s, i) => ({ ...s, sq: i + 1 }))
      );
    }, 500);
  };

  const handleSequenceChange = (id: number, operation: "up" | "down") => {
    let currentFormIndex = data?.findIndex((item: any) => item?.id === id);
    try {
      setLoading2(true);
      let formField1 = data[currentFormIndex];
      let formfield2 =
        data[
          operation === "down" ? currentFormIndex + 1 : currentFormIndex - 1
        ];
      let firstSortOrder = formField1?.sortOrder;
      let secondSortOrder = formfield2?.sortOrder;
      let tmp = formFields.map((obj) => {
        if (obj.id === formField1?.id) {
          return { ...obj, sortOrder: secondSortOrder };
        } else if (obj.id === formfield2?.id) {
          return { ...obj, sortOrder: firstSortOrder };
        } else {
          return obj;
        }
      });
      setDefaultData(tmp);
      setData([]);
      setTimeout(() => {
        setData(
          tmp
            ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
            ?.map((s, i) => ({ ...s, sq: i + 1 }))
        );
      }, 500);
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
    }
  };

  const handleSectionChange = (id: number) => {
    setLoading2(true);
    setData([]);
    let filtered = formFields
      ?.filter((f) => f?.sectionId === id)
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((s, i) => ({ ...s, sq: i + 1 }));
    setTimeout(() => {
      setData(filtered);
    }, 500);
    setLoading2(false);
  };

  const fieldColumns = [
    manageFormFieldsHelper.accessor("sq", {
      cell: (info) => info.renderValue(),
      header: () => <span>Sr. No.</span>,
    }),
    manageFormFieldsHelper.accessor("label", {
      cell: (info) => info.renderValue(),
      header: () => <span>KEY</span>,
    }),
    {
      accessorFn: (row: any) => row,
      id: "alignment",
      cell: (info: any) => {
        let currentColumn = info.getValue();
        console.log({ info });

        return (
          <div className="flex flex-col align-middle justify-center items-center">
            {currentColumn?.sq !== 1 && (
              <img
                onClick={() => handleSequenceChange(currentColumn?.id, "up")}
                src={UpArrow}
                alt=""
                className="hover:cursor-pointer"
                height={20}
                width={20}
              />
            )}
            {currentColumn?.sq !== data?.length && (
              <img
                onClick={() => handleSequenceChange(currentColumn?.id, "down")}
                src={DownArrow}
                alt=""
                className="hover:cursor-pointer"
                height={20}
                width={20}
              />
            )}
          </div>
        );
      },
      header: () => <span>Order</span>,
    },
    // manageFormFieldsHelper.accessor("label", {
    //   cell: (info : any) => {
    //     const index = info.cell.row.original.id
    //     const handleChange = (e : any) => {
    //       e.preventDefault();
    //       const {value} = e.target;
    //       handleOnChange(value, index, 'label')
    //     }
    //     return <InputFields onChange={handleChange} variant="rawInput" placeholder={info.getValue()}/>
    //   },
    //   header: () => <span>Field Name</span>,
    // }),
  ];

  const onSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    let result = formFields.map((f) => {
      return {
        id: f["id"],
        label: f["label"],
        addToSchemeCreation: f["addToSchemeCreation"],
        required: f["required"],
        sortOrder: f["sortOrder"],
      };
    });

    axiosUAMInstance
      .put(`/scheme/manage-form-fields`, {
        fields: result,
      })
      .then((response) => {
        if (response.data?.success) {
          formFieldChangeState();
          handleOpenClose();
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        handleOpenClose();
      });
  };

  return (
    <Modal
      open={true}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-gilroy-medium w-[360px] md:w-[1000px] bg-white rounded-2xl p-3 md:pl-[8px] md:pr-[12px] border shadow-2xl">
          <div
            className="flex flex-row justify-end cursor-pointer"
            onClick={handleOpenClose}
          >
            <img src={add} className="w-6 h-6" alt="icon" />
          </div>
          <h1 className="text-center text-black text-2xl font-normal mb-6">
            Manage Form Fields
          </h1>
          <form onSubmit={onSubmit} className="flex flex-col">
            <div className="flex flex-row justify-center w-full bg-[#86B3FF] rounded-t mb-3"></div>
            <div className="h-[30rem] overflow-y-auto overflow-x-hidden scrollbar">
              {loading2 ? (
                <LoaderSpin />
              ) : (
                data?.length > 0 && (
                  <ReactTable defaultData={data} columns={fieldColumns} />
                )
              )}
            </div>
            <div className="flex flex-row justify-end mt-3 ">
              <div className="flex flex-row w-full md:justify-end justify-around">
                <div className="w-[35%] md:w-[15%] mr-6 flex flex-row">
                  <Button
                    label={"Cancel"}
                    type="button"
                    variant="outline"
                    onClick={handleOpenClose}
                  />
                </div>
                <div className="w-[35%] md:w-[15%] flex flex-row">
                  <Button
                    label={loading ? <LoaderSpin /> : "Save"}
                    type="button"
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};
export default ManageFormFieldsForRegistrationScheme;
