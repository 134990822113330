// src/store.ts
import create from "zustand";

interface CollapseState {
  collapse: boolean;
  setCollapse: (value: boolean) => void;
}

const useCollapseStore = create<CollapseState>((set) => ({
  collapse: false,
  setCollapse: (value: boolean) => set({ collapse: value }),
}));

export default useCollapseStore;
