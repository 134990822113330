import React, { FC, useState, useEffect } from "react";
import add from "../../assets/images/add.svg";
import InputFields from "./InputField";
import SelectButton from "./SelectButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import LoaderSpin from "../LoaderSpin";
import "./EditModel.css";
import useCmsFormControlStore from "../../state/cmsFormControl";
import { axiosUAMInstance } from "../../utils/axios";

interface Option {
  value: string;
  label: string;
  id: number;
}

interface FileType {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface EntitySection {
  id: number;
  entityId: number;
  sectionName: string;
  createdAt: string;
  updatedAt: string;
}

interface FormData {
  documentName: string;
  fileSizeLimit: string;
  minDPI: string;
}

interface Props {
  handleOpenClose: () => void;
  heading: string;
  registerId?: number;
  singleDocument?: any;
  apiCall?: () => void;
  fileTypeData?: FileType[];
  entitySectionsData?: EntitySection[];
  setData?: () => {};
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const EditDocument: FC<Props> = ({
  handleOpenClose,
  heading,
  fileTypeData,
  entitySectionsData,
  singleDocument = {},
  registerId,
  apiCall,
  setData,
}: Props) => {
  const documentFieldUpdated = useCmsFormControlStore(
    (state) => state.updatedDocumentField
  );

  const { documentName, fileSizeLimit, minDpi, fileType, sectionId } =
    singleDocument as any;

  const filterFileType = fileTypeData?.filter((fileTypeItem) => {
    return fileTypeItem.id === fileType;
  });

  const filterSectionId = entitySectionsData?.filter((sectionIdItem) => {
    return sectionIdItem.id === sectionId;
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      documentName: documentName || "",
      fileSizeLimit: fileSizeLimit || "",
      minDPI: minDpi || "",
    },
  });

  const options: Option[] = fileTypeData
    ? fileTypeData.map((fileType) => ({
        value: fileType.name,
        label: fileType.name,
        id: fileType.id,
      }))
    : [];

  const options1: Option[] = entitySectionsData
    ? entitySectionsData.map((section) => ({
        value: section.sectionName,
        label: section.sectionName,
        id: section.id,
      }))
    : [];

  const [selectedOption1, setSelectedOption1] = useState<Option | null>(
    filterFileType && filterFileType[0]
      ? {
          value: filterFileType[0].name,
          label: filterFileType[0].name,
          id: filterFileType[0].id,
        }
      : null
  );

  const [selectedOption2, setSelectedOption2] = useState<Option | null>(
    filterSectionId && filterSectionId[0]
      ? {
          value: filterSectionId[0].sectionName,
          label: filterSectionId[0].sectionName,
          id: filterSectionId[0].id,
        }
      : null
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [apiError, setApiErro] = useState<string>("");
  const [formChanged, setFormChanged] = useState<boolean>(false);

  // useEffect(() => {
  //   if (isDirty) {
  //     setFormChanged(true);
  //   } else {
  //     setFormChanged(false);
  //   }
  // }, [isDirty]);

  const handleSetOption1 = (option: Option) => {
    setSelectedOption1(option);
  };

  const handleSetOption2 = (option: Option) => {
    setSelectedOption2(option);
  };

  const handleFormSubmit: SubmitHandler<FormData> = (data) => {
    const fileType = selectedOption1 ? selectedOption1.id : null;
    const sectionId = selectedOption2 ? selectedOption2.id : null;

    const requestData = {
      documentName: data.documentName,
      fileType: fileType,
      fileSizeLimit: parseInt(data.fileSizeLimit),
      minDpi: parseInt(data.minDPI),
      sectionId: sectionId,
      documentTypeId: singleDocument.id,
    };

    setLoader(true);

    axiosUAMInstance
      .post(`/registration/add-document-field`, requestData)
      .then((response) => {
        reset();
        setLoader(false);
        documentFieldUpdated();
        handleOpenClose();
      })
      .catch((error) => {
        setApiErro(error.message);
        setLoader(false);
      });
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleOpenClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="md:h-auto rounded-2xl p-[8px] text-gilroy-medium shadow-xl bg-white w-[350px] md:w-[500x] lg:w-[550px] ">
            <div
              className="flex flex-row justify-end cursor-pointer"
              onClick={handleOpenClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mt-[12px] text-center text-black text-[24px] font-normal">
              {heading}
            </h1>

            <p className="text-red-500 text-center ">{apiError}</p>

            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="p-4 md:px-[24px]"
            >
              <div
                className="custom-scrollbar"
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="my-4">
                  <label
                    htmlFor="documentName"
                    className=" text-black text-[16px] font-normal  mb-[8px] text-gilroy-medium"
                  >
                    Document Name:
                  </label>
                  <InputFields
                    type="text"
                    id="documentName"
                    placeholder="type here"
                    {...register("documentName", {
                      required: "Document Name is required",
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-5">
                    {errors?.documentName?.message}
                  </p>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    File Types:
                  </label>

                  <SelectButton
                    setOption={handleSetOption1}
                    options={options}
                    selectedOption={selectedOption1}
                    placeholder="Select"
                  />
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    Section id:
                  </label>

                  <SelectButton
                    setOption={handleSetOption2}
                    options={options1}
                    selectedOption={selectedOption2}
                    placeholder="Select"
                  />
                </div>

                <div className="my-4 relative">
                  <label
                    htmlFor="fileSizeLimit"
                    className=" text-black text-base font-normal text-gilroy-medium mb-[8px] "
                  >
                    File Size Limit:
                  </label>
                  <InputFields
                    type="text"
                    id="fileSizeLimit"
                    placeholder="type here"
                    {...register("fileSizeLimit", {
                      required: "File Size Limit is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "File Size Limit must be a number",
                      },
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <div className="absolute top-9 right-0 pr-5 pt-3 py-4 pl-2 rounded-r-2xl bg-white">
                    KB
                  </div>
                  <p className="text-red-500 -mt-5">
                    {errors?.fileSizeLimit?.message}
                  </p>
                </div>

                <div className="my-4">
                  <label
                    htmlFor="minDPI"
                    className=" text-black text-base font-normal text-gilroy-medium mb-[8px]"
                  >
                    Min DPI
                  </label>
                  <InputFields
                    type="text"
                    id="minDPI"
                    placeholder="type here"
                    {...register("minDPI", {
                      required: "Min DPI is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Min DPI must be a number",
                      },
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[38px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-10">
                    {errors?.minDPI?.message}
                  </p>
                </div>
              </div>

              <div>
                <hr className="w-full "></hr>
                <div className="flex flex-row justify-around my-4">
                  <button
                    onClick={handleOpenClose}
                    className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1c468e] text-[#1c468e]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loader}
                    className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] rounded-xl ${
                      loader
                        ? "opacity-50 cursor-not-allowed bg-[#1c468e] text-white"
                        : "bg-[#1c468e] text-white"
                    }`}
                  >
                    {loader ? <LoaderSpin /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditDocument;
