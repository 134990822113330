import React, { FC, InputHTMLAttributes, forwardRef, useState } from "react";
import { axiosUAMInstance } from "../../utils/axios";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon: string;
  // setFileId: () => void;
  setFileId: (fileId: string) => void;
}

const FileUploadTwo: FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ setFileId, icon, ...props }, ref) => {
    const [filename, setFilename] = useState("Browse files");
    const handleClick = (event: any) => {
      const input = document.getElementById("inputButton");
      input?.click();
      event.preventDefault();
    };

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files ? event.target.files[0] : null;
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axiosUAMInstance.post(
          `/openkm/save/approval/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setFileId(response?.data?.data[0].fileId); // Uncomment and use if fileId is needed externally
        // console.log(response);
        setFilename(file.name);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };

    const buttonStyle = {
      backgroundColor: "white", // Changed background color
      padding: "10px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #E6E6E6", // Changed border style
    };

    const iconStyle = {
      marginRight: "8px",
    };

    const filenameStyle = {
      color: "gray",
      fontSize: "16px",
    };

    const classname =
      "mb-[8px] top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle]";

    return (
      <div className="relative w-full">
        <input
          {...props}
          ref={ref}
          className={classname}
          type="file"
          id="inputButton"
          onChange={handleFileChange}
        />
        <button
          onClick={handleClick}
          className="top-0 w-full z-20 bg-white form-input border h-[50px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle overflow-hidden"
          style={buttonStyle}
        >
          {icon && (
            <span className="relative" style={filenameStyle}>
              {filename ? filename : "Browse files"}
            </span>
          )}
          <img
            src={icon}
            alt=""
            className="mr-4 rounded-lg w-8"
            style={iconStyle}
          />
        </button>
      </div>
    );
  }
);

export default FileUploadTwo;
