import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import add from "../../assets/images/add.svg";
import useWebContentStore from "../../state/useWebContentStore";
import Swal from "sweetalert2";
import InputFields from "../../components/form/InputField";
import ButtonComp from "../../components/cms/ButtonComp";
import LoaderSpin from "../../components/LoaderSpin";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  heading: string;
  Question: string;
  answer: string;
}

interface Props {
  onClose: () => void;
  sectionIdx: any;
}

const EditQuestionSection: React.FC<Props> = ({ onClose, sectionIdx }) => {
  const { faqData, updateRefresh } = useWebContentStore();
  const [loader, setLoader] = useState<boolean>(false);
  const [sectionName, setSectionName] = useState("");
  const [error, setError] = useState("");
  const [dataToEdit, setDataToEdit] = useState<any>(undefined);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    let error = 0;
    let updated = dataToEdit?.map((d: any) => {
      if (d?.subheading?.trim() === "") {
        error += 1;
        return { ...d, error: "Field cannot be empty!" };
      } else {
        return { ...d, error: "" };
      }
    });
    setDataToEdit(updated);

    if (error > 0) {
      setLoader(false);
      return;
    }
    try {
      updated = faqData?.alldata?.map((faq: any, index: number) => {
        let questionSections =
          faq?.content?.data?.faqPageData?.questionSections;
        let tempObj = {
          ...dataToEdit?.find((qa: any) => qa?.language === faq?.language),
        };
        questionSections = questionSections?.map((s: any, i: number) => {
          if (sectionIdx == i) {
            return { ...s, subHeading: tempObj?.subheading };
          } else {
            return s;
          }
        });

        return {
          ...faq,
          content: {
            data: {
              faqPageData: {
                ...faq?.content?.data?.faqPageData,
                questionSections,
              },
            },
          },
        };
      });

      const res = await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        updated
      );

      onClose();
      let data = res.data;
      if (data?.status === "success") {
        Swal.fire({
          icon: "success",
          text: data?.message,
          title: "Success",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: data?.message,
          title: "Error",
        });
      }
    } catch (error: any) {
      onClose();
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
        title: "Error",
      });
    } finally {
      setLoader(false);
      updateRefresh();
    }

    // setLoader(true);
  };

  useEffect(() => {
    const sections = faqData?.alldata?.map((d: any) => ({
      subheading:
        d?.content?.data?.faqPageData?.questionSections[sectionIdx]?.subHeading,
      language: d?.language,
      error: "",
    }));
    setDataToEdit(sections);
  }, [faqData]);

  const handleSectionNameChange = (event: any) => {
    const { value } = event?.target;
    setSectionName(value);
  };

  const handleChange = (event: any, index: number) => {
    const { value } = event?.target;

    const updatedData = [...dataToEdit];
    updatedData[index] = {
      ...updatedData[index],
      subheading: value,
      error: value?.trim() === "" ? "Field cannot be empty" : "",
    };

    // Update the state with the new array
    setDataToEdit(updatedData);
  };
  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Add Section
          </h1>

          <div
            className="custom-scrollbar p-4"
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {dataToEdit?.map((data: any, i: number) => {
              return (
                <div className="my-1" key={i}>
                  <h1 className="bg-gray-400 rounded-xl p-2 text-center">
                    {data?.language}
                  </h1>
                  <div className="my-1">
                    <label
                      htmlFor="content"
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Heading&nbsp;<span className="text-red-400">*</span>:
                    </label>
                    <InputFields
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[10px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="type here"
                      value={data?.subheading}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <span className="text-red-400">{data?.error}</span>
                  </div>
                </div>
              );
            })}

            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <div className="flex flex-row justify-around">
              <button
                className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1c468e] text-[#1c468e]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type={"button"}
                className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] rounded-xl text-white ${
                  loader
                    ? "bg-gray-300 cursor-not-allowed text-white"
                    : "bg-[#1c468e]"
                }`}
                onClick={handleFormSubmit}
                disabled={loader}
              >
                {loader ? <LoaderSpin /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditQuestionSection;
