import React, { FC, forwardRef } from "react";

interface ToggleSwitchProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  enabled: boolean;
  setEnabled?: (enable: boolean) => void;
}

const ToggleSwitch: FC<ToggleSwitchProps> = forwardRef<
  HTMLButtonElement,
  ToggleSwitchProps
>((props, ref) => {
  const { enabled, setEnabled } = props;

  return (
    <button
      ref={ref}
      role="switch"
      aria-checked={enabled}
      onClick={() => {
        if (setEnabled) {
          setEnabled(!enabled);
        }
      }}
      className={`${
        enabled ? "bg-[#E7F0FF]" : "bg-gray-200"
      } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
      {...props}
    >
      <span
        className={`${
          enabled ? "translate-x-6 bg-[#1c468e]" : "translate-x-1 bg-white"
        } inline-block w-4 h-4 transform rounded-full transition-transform`}
      />
    </button>
  );
});
export default ToggleSwitch;
