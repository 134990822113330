import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import InputFields from "./InputField";
import add from "../../assets/images/add.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import ButtonComp from "../cms/ButtonComp";
import { axiosUAMInstance } from "../../utils/axios";
import axios from "axios";

interface UploadDocumentModalProps {
  onClose: () => void;
  onSave: () => void;
  apiCallLanguage?: () => void;
}

interface FormData {
  languageName: string;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const UploadLanguage: React.FC<UploadDocumentModalProps> = ({
  onClose,
  onSave,
  apiCallLanguage,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [loader, setLoader] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<"success" | "error">(
    "success"
  );
  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoader(true);
      const response = await axiosUAMInstance.post(`/language/add`, {
        isRTL: false,
        languageName: data.languageName,
        displayLabel: data.languageName,
        updatedBy: 1,
      });

      setMessage(response.data.message || "Language successfully uploaded.");
      setMessageType("success");
      setLoader(false);

      setTimeout(() => {
        onClose();
        onSave();
      }, 3000);
      if (apiCallLanguage) {
        apiCallLanguage();
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        setMessage(
          error.response.data.message ||
            "Unable to upload language due to an internal server error."
        );
      } else {
        setMessage("Network or server error. Please try again later.");
      }
      setMessageType("error");
      setLoader(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleCloseModal}
    >
      <Box sx={style}>
        <div className="md:flex">
          <div className="m-[16px] md:m-16 w-[400px] md:w-[600px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
            <div
              className="flex flex-row justify-end mb-[12px] cursor-pointer"
              onClick={handleCloseModal}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
              Upload Language
            </h1>

            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="md:px-[32px] px-[16px]">
                <label
                  htmlFor="languageName"
                  className="text-black text-[16px] font-normal text-gilroy-medium"
                >
                  Label value:
                </label>
                <div className="relative">
                  <InputFields
                    {...register("languageName", {
                      required: "Language name is required",
                      minLength: {
                        value: 3,
                        message:
                          "Language name must be at least 3 characters long",
                      },
                    })}
                    id="languageName"
                    className="capitalize mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] mb-[24px] border border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                    placeholder="Type here"
                  />
                  {errors.languageName && (
                    <p className="text-red-500 absolute top-[70px]">
                      {errors.languageName.message}
                    </p>
                  )}
                </div>
                {message && (
                  <p
                    className={`text-${
                      messageType === "success" ? "green" : "red"
                    }-500 text-center`}
                  >
                    {message}
                  </p>
                )}
                <div>
                  <hr className="w-full bg-[#E6E6E6] mt-[40px] mb-[24px]"></hr>
                  <ButtonComp onClose={onClose} loader={loader} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UploadLanguage;
