import { create } from 'zustand';

interface MasterEntityStateTypes {
    formSubmitted : boolean,
    searching : boolean,
    handleFormSubmit: (data : boolean) => void;
    handleSearchInputChange : () => void
    setSearchingFalse : () => void
}
const useMasterEntitiesStore = create<MasterEntityStateTypes>(set => ({
    formSubmitted : false,
    searching : false,
    handleSearchInputChange : () => set(state => ({ searching : true})),
    setSearchingFalse : () => set(state => ({searching : false})),
    handleFormSubmit : (data : boolean) => set(state => ({ formSubmitted : data}))
}));

export default useMasterEntitiesStore;