import React from "react";
import DscButton from "../components/form/Dscbutton";
import SelectButtonSchemeMaster from "../components/SchemaMaster/SelectButtonSchemeMaster";
import DatePicker from "../components/common/DatePicker";
import TextArea from "../components/common/TextArea";
import InputFields from "../components/form/InputField";
import LoaderSpin from "../components/LoaderSpin";
import moment from "moment";

type Props = {
  formFields: any[];
  fieldTypes: any[];
  loader?: boolean;
};

const useRenderDynamicFields = ({ formFields, fieldTypes, loader }: Props) => {
  const renderInputField = (field: any) => {
    const fieldType = fieldTypes?.find(
      (type: any) => type?.id === field?.typeId
    )?.name;

    switch (fieldType) {
      case "text":
      case "number":
      case "password":
      case "phone_number":
      case "email":
        return (
          <div key={field.id}>
            <label
              htmlFor={field.key}
              className="text-base font-normal text-gilroy-medium"
            >
              {field.label}
            </label>
            <div className="mt-2">
              <InputFields
                placeholder={field.placeholder}
                // width="350px"
                value={field.userInput || ""}
                disabled={field.disabled}
                type={fieldType}
                id={field?.label}
              />
            </div>
          </div>
        );
      case "textarea":
        return (
          <div key={field.id}>
            <label
              htmlFor={field.key}
              className="text-base font-normal text-gilroy-medium"
            >
              {field.label}
            </label>
            <div className="mt-2">
              <TextArea
                placeholder={field.placeholder}
                // mdWidth="415px"
                bgColor="#f2f2f2"
                value={field.userInput || ""}
                disabled={field.disabled}
              />
            </div>
          </div>
        );
      case "date_picker":
        return (
          <div key={field.id}>
            <label
              htmlFor={field.key}
              className="text-base font-normal text-gilroy-medium"
            >
              {field.label}
            </label>
            <div className="mt-2">
              <DatePicker
                onChange={() => {}}
                userValue={moment(field?.userInput).format("DD/MM/YYYY")}
                disabled={field.disabled}
              />
            </div>
          </div>
        );
      case "select":
      case "select_with_search":
        return (
          <div key={field.id}>
            <label
              htmlFor={field.key}
              className="text-base font-normal text-gilroy-medium"
            >
              {field.label}
            </label>
            <div className="mt-2">
              <SelectButtonSchemeMaster
                setOption={() => {}}
                // options={options}
                options={field?.dropdown_options?.options?.map((d: any) => ({
                  value: d?.name,
                  label: d?.name,
                  id: d?.id,
                }))}
                selectedOption={field?.userInput}
                placeholder={field.placeholder}
                bgColor="#ffffff"
                // mdWidth="md:w-[417px]"
                disabled={field.disabled}
              />
            </div>
          </div>
        );
      // case "DSC":
      //   return (
      //     <div className="flex flex-col">
      //       <label
      //         htmlFor={field?.label}
      //         className="block text-[#000000] text-base font-normal text-gilroy-medium"
      //       >
      //         {field?.label}
      //       </label>

      //       <DscButton
      //         fileName={field?.dscFileNAme}
      //         disabled={field?.disabled ? field?.disabled : false}
      //       />
      //     </div>
      //   );
      default:
        return null;
    }
  };
  return (
    <form className="grid grid-cols-1  md:grid-cols-3 gap-4">
      {/* <form className="flex items-center gap-4 flex-wrap"> */}
      {loader ? (
        <LoaderSpin />
      ) : (
        formFields?.map((field: any) => renderInputField(field))
      )}
    </form>
  );
};

export default useRenderDynamicFields;
