// import React, { useState, ChangeEvent, FormEvent } from "react";
// import add from "../../assets/images/add.svg";
// import TextArea from "../common/TextArea";
// import ButtonComp from "../cms/ButtonComp";
// import Modal from "@mui/material/Modal";
// import { Box } from "@mui/material";
// import addButton from "../../assets/images/addButton.svg";
// import SelectButton from "../common/SelectButton";
// import InputFields from "../form/InputField";
// import { axiosUAMInstance } from "../../utils/axios";
// import Swal from "sweetalert2";
// import uamStore from "../../state/uamStore";
// import LoaderSpin from "../LoaderSpin";

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   p: 4,
// };

// interface Props {
//   onClose: () => void;
//   functionalities: any[];
//   selectedFuncs?: any[];
//   roleId?: number;
//   roleName?: string;
//   isActive?: boolean;
// }

// const AddNewRoleModal: React.FC<Props> = ({
//   onClose,
//   functionalities,
//   selectedFuncs = [],
//   roleId,
//   roleName,
//   isActive,
// }) => {
//   console.log({ roleId });

//   const handleRoleAddedState = uamStore((state) => state.handleRoleAddedState);
//   const [submit, setSubmit] = useState<boolean>(false);
//   const [selectedFunctionalities, setSelectedFunctionalities] =
//     useState<any[]>(selectedFuncs);

//   const [nameOfRole, setNameOfRole] = useState<string>(
//     roleName ? roleName : ""
//   );

//   const [errors, setErrors] = useState<{ roleName: string; dropdown: string }>({
//     roleName: "",
//     dropdown: "",
//   });
//   const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//   };

//   // const handleChangeRoleName = (event: any) => {
//   //   const { value } = event.target;
//   //   if (value === "") {
//   //     setErrors((prev) => ({ ...prev, roleName: "Please enter name" }));
//   //   } else if (value.length < 2) {
//   //   } else {
//   //     setErrors((prev) => ({ ...prev, roleName: "" }));
//   //   }
//   //   setNameOfRole(value);
//   // };

//   const handleChangeRoleName = (event: ChangeEvent<HTMLInputElement>) => {
//     const { value } = event.target;
//     let errorMessage = "";

//     // Check if the input is less than 2 characters
//     if (value.length < 2) {
//       errorMessage = "Please enter at least 2 characters.";
//     }
//     // Check if the input contains only special characters
//     const specialCharsRegex = /^[!@#$%^&*(),.?":{}|<>~`_+=\[\]\\\/'“”‘’]*$/;
//     if (specialCharsRegex.test(value)) {
//       errorMessage =
//         "Please enter a valid role name without only special characters.";
//     }
//     const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
//     if (!alphanumericRegex.test(value) && !specialCharsRegex.test(value)) {
//       errorMessage = "Role name can contain letters, numbers, and spaces.";
//     }

//     // // Regex to identify if the input contains emojis
//     // const emojiRegex = /[\p{Emoji}]/u;
//     // if (emojiRegex.test(value)) {
//     //   errorMessage = "Please do not use emojis.";
//     // }

//     // Update error state and role name
//     setErrors((prev) => ({ ...prev, roleName: errorMessage }));
//     setNameOfRole(value);
//   };

//   const handleClose = () => {
//     onClose();
//   };

//   const remove = (data: any) => {
//     const filtered = selectedFunctionalities.filter(
//       (f) => f.value !== data.value
//     );
//     if (filtered.length === 0) {
//       setErrors((prev) => ({
//         ...prev,
//         dropdown: "Please select at least 1 functionality",
//       }));
//     } else {
//       setErrors((prev) => ({ ...prev, dropdown: "" }));
//     }
//     setSelectedFunctionalities(filtered);
//   };

//   const handleSetOption1 = (value: any) => {
//     if (
//       functionalities.length > 0 &&
//       !selectedFunctionalities.find((f) => f.value === value.value)
//     ) {
//       const selected = functionalities.find((f) => f.value === value.value);
//       setSelectedFunctionalities((prev) => [...prev, selected]);
//     }
//   };
//   // const handleOnSubmit = () => {
//   //   let hasErrors = false;

//   //   // Check for functionality selection errors
//   //   if (selectedFunctionalities.length === 0) {
//   //     setErrors((prev) => ({
//   //       ...prev,
//   //       dropdown: "Please select at least 1 functionality",
//   //     }));
//   //     hasErrors = true;
//   //   }

//   //   // Check for role name errors
//   //   if (
//   //     nameOfRole === "" ||
//   //     nameOfRole.length < 2 ||
//   //     /[\p{Emoji}]/u.test(nameOfRole)
//   //   ) {
//   //     setErrors((prev) => ({
//   //       ...prev,
//   //       roleName:
//   //         nameOfRole.length < 2
//   //           ? "Please enter at least 2 characters."
//   //           : "Please do not use emojis.",
//   //     }));
//   //     hasErrors = true;
//   //   }

//   //   if (!hasErrors) {
//   //     const resultObject = roleId
//   //       ? {
//   //           compositeRoleName: nameOfRole,
//   //           basicRoleIds: selectedFunctionalities.map((f) => f.value),
//   //           description: "Admin Role With all access",
//   //           compositeRoleId: roleId,
//   //           isActive: isActive,
//   //         }
//   //       : {
//   //           compositeRoleName: nameOfRole,
//   //           basicRoleIds: selectedFunctionalities.map((f) => f.value),
//   //           description: "Admin Role With all access",
//   //         };

//   //     setSubmit(true);
//   //     axiosUAMInstance[roleId ? "put" : "post"](
//   //       `/admin/role/${roleId ? "update" : "add"}/`,
//   //       resultObject
//   //     )
//   //       .then((res: any) => {
//   //         if (res.status === 200) {
//   //           handleClose();
//   //           handleRoleAddedState();
//   //           Swal.fire({
//   //             title: `Role ${roleId ? "updated" : "added"} successfully`,
//   //             icon: "success",
//   //           });
//   //         }
//   //         setSubmit(false);
//   //         setNameOfRole("");
//   //       })
//   //       .catch((e) => {
//   //         setSubmit(false);
//   //         handleClose();
//   //         Swal.fire({
//   //           title: "Something went wrong!",
//   //           icon: "error",
//   //         });
//   //       });
//   //   }
//   // };

//   const handleOnSubmit = () => {
//     if (selectedFunctionalities.length === 0) {
//       setErrors((prev) => ({
//         ...prev,
//         dropdown: "Please select at least 1 functionality",
//       }));
//     } else {
//       setErrors((prev) => ({ ...prev, dropdown: "" }));
//     }

//     if (nameOfRole === "") {
//       setErrors((prev) => ({ ...prev, roleName: "Please enter name" }));
//     } else {
//       setErrors((prev) => ({ ...prev, roleName: "" }));
//     }

//     let resultObject;
//     if (roleId) {
//       resultObject = {
//         compositeRoleName: nameOfRole,
//         basicRoleIds: selectedFunctionalities.map((f) => f.value),
//         description: "Admin Role With all access",
//         compositeRoleId: roleId,
//         isActive: isActive,
//       };
//     } else {
//       resultObject = {
//         compositeRoleName: nameOfRole,
//         basicRoleIds: selectedFunctionalities.map((f) => f.value),
//         description: "Admin Role With all access",
//       };
//     }

//     if (
//       resultObject.compositeRoleName !== "" &&
//       resultObject.basicRoleIds.length > 0
//     ) {
//       setSubmit(true);

//       axiosUAMInstance[roleId ? "put" : "post"](
//         `/admin/role/${roleId ? "update" : "add"}/`,
//         resultObject
//       )
//         .then((res: any) => {
//           if (res.status === 200) {
//             handleClose();
//             handleRoleAddedState();
//             Swal.fire({
//               title: `Role ${roleId ? "updated" : "added"} successfully`,
//               icon: "success",
//             });
//           }
//           setSubmit(false);
//           setNameOfRole("");
//         })
//         .catch((e: any) => {
//           console.log(e, "err");
//           setSubmit(false);
//           handleClose();
//           Swal.fire({
//             title: e?.response?.data?.error?.errorMessage,
//             icon: "error",
//           });
//         });
//     }
//   };
//   console.log(roleId, "roleId");

//   return (
//     <>
//       <Modal
//         open={true}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="m-1 md:m-[0px] w-[350px] md:w-[544px] rounded-2xl p-4 md:p-[32px] text-gilroy-medium pb-3 shadow-xl backdrop-blur-lg bg-white">
//             <h1 className=" text-black text-[24px] font-normal">
//               {/* Add new role */}
//               {roleId ? "Edit role" : "Add new role"}
//             </h1>

//             <hr className="w-full bg-[#E6E6E6] mt-3 mb-[24px]"></hr>
//             <form onSubmit={handleFormSubmit}>
//               <div className="mb-[24px]">
//                 <label
//                   htmlFor="documentName"
//                   className=" text-black text-[16px]  mb-[8px] text-gilroy-medium font-bold "
//                 >
//                   Role
//                   <span className="text-red-400 ml-1">*</span>
//                 </label>
//                 <InputFields
//                   onChange={handleChangeRoleName}
//                   type="text"
//                   value={nameOfRole}
//                   name="roleName"
//                   id="documentName"
//                   placeholder="Type Role Name"
//                   disabled={!!roleId}
//                   className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px]  mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 />
//                 <span className="text-red-500">{errors["roleName"]}</span>
//               </div>
//               <div>
//                 <label
//                   htmlFor="fileTypes"
//                   className=" text-black text-base font-bold mb-[8px] text-gilroy-medium"
//                 >
//                   Functionalities mapped
//                   <span className="text-red-400 ml-1">*</span>
//                 </label>
//                 <div className="mt-[8px]">
//                   <SelectButton
//                     setOption={handleSetOption1}
//                     options={functionalities}
//                     placeholder="Select"
//                     multiselect={true}
//                     allSelectedOptions={selectedFunctionalities}
//                     remove={remove}
//                     className="relative"
//                   />
//                   <span className="text-red-400 ml-1">
//                     {errors["dropdown"]}
//                   </span>
//                 </div>
//               </div>
//               <div className="mt-2">
//                 <div className="flex flex-row justify-around">
//                   <button
//                     className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1C468E] text-[#1C468E]"
//                     onClick={() => handleClose()}
//                   >
//                     Cancel
//                   </button>
//                   <button
//                     type="submit"
//                     className="ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1C468E] rounded-xl text-white"
//                     onClick={handleOnSubmit}
//                   >
//                     {submit ? <LoaderSpin /> : "Save"}
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default AddNewRoleModal;
import React, { useState, ChangeEvent, FormEvent } from "react";
import add from "../../assets/images/add.svg";
import TextArea from "../common/TextArea";
import ButtonComp from "../cms/ButtonComp";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import addButton from "../../assets/images/addButton.svg";
import SelectButton from "../common/SelectButton";
import InputFields from "../form/InputField";
import { axiosUAMInstance } from "../../utils/axios";
import Swal from "sweetalert2";
import uamStore from "../../state/uamStore";
import LoaderSpin from "../LoaderSpin";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface Props {
  onClose: () => void;
  functionalities: any[];
  selectedFuncs?: any[];
  roleId?: number;
  roleName?: string;
  isActive?: boolean;
}

const AddNewRoleModal: React.FC<Props> = ({
  onClose,
  functionalities,
  selectedFuncs = [],
  roleId,
  roleName,
  isActive,
}) => {
  const handleRoleAddedState = uamStore((state) => state.handleRoleAddedState);
  const [submit, setSubmit] = useState<boolean>(false);
  const [selectedFunctionalities, setSelectedFunctionalities] =
    useState<any[]>(selectedFuncs);

  const [nameOfRole, setNameOfRole] = useState<string>(
    roleName ? roleName : ""
  );

  const [errors, setErrors] = useState<{ roleName: string; dropdown: string }>({
    roleName: "",
    dropdown: "",
  });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleOnSubmit();
  };

  const handleChangeRoleName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let errorMessage = "";

    // Check if the input is less than 2 characters
    if (value.length < 2) {
      errorMessage = "Please enter at least 2 characters.";
    }
    // Check if the input contains only special characters
    const specialCharsRegex = /^[!@#$%^&*(),.?":{}|<>~`_+=\[\]\\\/'“”‘’]*$/;
    if (specialCharsRegex.test(value)) {
      errorMessage =
        "Please enter a valid role name without only special characters.";
    }
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (!alphanumericRegex.test(value) && !specialCharsRegex.test(value)) {
      errorMessage = "Role name can contain letters, numbers, and spaces.";
    }

    setErrors((prev) => ({ ...prev, roleName: errorMessage }));
    setNameOfRole(value);
  };

  const handleClose = () => {
    onClose();
  };

  const remove = (data: any) => {
    const filtered = selectedFunctionalities.filter(
      (f) => f.value !== data.value
    );
    if (filtered.length === 0) {
      setErrors((prev) => ({
        ...prev,
        dropdown: "Please select at least 1 functionality",
      }));
    } else {
      setErrors((prev) => ({ ...prev, dropdown: "" }));
    }
    setSelectedFunctionalities(filtered);
  };

  const handleSetOption1 = (value: any) => {
    if (
      functionalities.length > 0 &&
      !selectedFunctionalities.find((f) => f.value === value.value)
    ) {
      const selected = functionalities.find((f) => f.value === value.value);
      setSelectedFunctionalities((prev) => [...prev, selected]);

      // Clear the dropdown error immediately if at least one functionality is selected
      if (selectedFunctionalities.length >= 0) {
        setErrors((prev) => ({ ...prev, dropdown: "" }));
      }
    }
  };

  const handleOnSubmit = () => {
    let hasErrors = false;

    if (selectedFunctionalities.length === 0) {
      setErrors((prev) => ({
        ...prev,
        dropdown: "Please select at least 1 functionality",
      }));
      hasErrors = true;
    } else {
      setErrors((prev) => ({ ...prev, dropdown: "" }));
    }

    if (nameOfRole === "" || nameOfRole.length < 2) {
      setErrors((prev) => ({
        ...prev,
        roleName: "Please enter a valid role name.",
      }));
      hasErrors = true;
    } else {
      setErrors((prev) => ({ ...prev, roleName: "" }));
    }

    if (!hasErrors) {
      const resultObject = roleId
        ? {
            compositeRoleName: nameOfRole,
            basicRoleIds: selectedFunctionalities.map((f) => f.value),
            description: "Admin Role With all access",
            compositeRoleId: roleId,
            isActive: isActive,
          }
        : {
            compositeRoleName: nameOfRole,
            basicRoleIds: selectedFunctionalities.map((f) => f.value),
            description: "Admin Role With all access",
          };

      setSubmit(true);
      axiosUAMInstance[roleId ? "put" : "post"](
        `/admin/role/${roleId ? "update" : "add"}/`,
        resultObject
      )
        .then((res: any) => {
          if (res.status === 200) {
            handleClose();
            handleRoleAddedState();
            Swal.fire({
              title: `Role ${roleId ? "updated" : "added"} successfully`,
              icon: "success",
            });
          }
          setSubmit(false);
          setNameOfRole("");
        })
        .catch((e: any) => {
          console.log(e, "err");
          setSubmit(false);
          handleClose();
          Swal.fire({
            title:
              e?.response?.data?.error?.errorMessage || "Something went wrong!",
            icon: "error",
          });
        });
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-1 md:m-[0px] w-[350px] md:w-[544px] rounded-2xl p-4 md:p-[32px] text-gilroy-medium pb-3 shadow-xl backdrop-blur-lg bg-white">
          <h1 className=" text-black text-[24px] font-normal">
            {roleId ? "Edit role" : "Add new role"}
          </h1>
          <hr className="w-full bg-[#E6E6E6] mt-3 mb-[24px]"></hr>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-[24px]">
              <label
                htmlFor="documentName"
                className=" text-black text-[16px]  mb-[8px] text-gilroy-medium font-bold "
              >
                Role
                <span className="text-red-400 ml-1">*</span>
              </label>
              <InputFields
                onChange={handleChangeRoleName}
                type="text"
                value={nameOfRole}
                name="roleName"
                id="documentName"
                placeholder="Type Role Name"
                disabled={!!roleId}
                className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px]  mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <span className="text-red-500">{errors.roleName}</span>
            </div>
            <div>
              <label
                htmlFor="fileTypes"
                className=" text-black text-base font-bold mb-[8px] text-gilroy-medium"
              >
                Functionalities mapped
                <span className="text-red-400 ml-1">*</span>
              </label>
              <div className="mt-[8px]">
                <SelectButton
                  setOption={handleSetOption1}
                  options={functionalities}
                  placeholder="Select"
                  multiselect={true}
                  allSelectedOptions={selectedFunctionalities}
                  remove={remove}
                  className="relative"
                />
                <span className="text-red-400 ml-1">{errors.dropdown}</span>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex flex-row justify-around">
                <button
                  type="button"
                  className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1C468E] text-[#1C468E]"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1C468E] rounded-xl text-white"
                >
                  {submit ? <LoaderSpin /> : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddNewRoleModal;
