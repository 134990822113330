import { ReactNode, useState } from "react";
import ToggleSwitch from "./ToggleSwitch";

type Props = {
  label: string;
  children?: ReactNode;
};

const TreeNode = ({ label, children }: Props) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  return (
    <div>
      <div className={`py-2 mb-[15px] `}>
        <ToggleSwitch enabled={enabled} setEnabled={setEnabled} />
        <span
          className={`pl-[13px] ${label === "Dashboard" && "font-semibold"}  ${
            label === "User access management" && "font-semibold"
          }  ${
            label === "Scheme management" && "font-semibold"
          }  text-black text-base text-gilroy-regular`}
        >
          {label}
        </span>
        <div>
          {children && (
            <div className="flex flex-row]">
              <div className="ml-[16px] mt-[4px]">
                <div className="border-l border-b w-[12px] h-[90px] border-dashed border-[#666666]">
                  <div className="border-b pt-[33px] border-dashed border-[#666666]"></div>
                </div>
              </div>
              <div
                className={`ml-[4px] pl-[4px] border-dashed border-[#666666] ${
                  children === "Create Role" ? "mt-[18px]" : "mt-[18px]"
                }`}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeNode;
