import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import add from "../../assets/images/add.svg";
import useWebContentStore from "../../state/useWebContentStore";
import Swal from "sweetalert2";
import InputFields from "../../components/form/InputField";
import ButtonComp from "../../components/cms/ButtonComp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  Name: string;
  Text: string;
  Link: string;
  Image: File | null;
}

interface Props {
  onClose: () => void;
  apiCall?: () => void;
  content: any;
}

const AddHeading: React.FC<Props> = ({ onClose, apiCall, content }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const { webcontentData } = useWebContentStore();
  console.log(content, "webcontent data");
  const { register } = useForm<FormValues>({
    defaultValues: {
      Text: "",
    },
  });

  const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={handleImageClick}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Add Heading
          </h1>

          <form className="p-4 md">
            <div
              className="custom-scrollbar p-4"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="my-4">
                <label
                  htmlFor="Text"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Text:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Text")}
                  placeholder="type here"
                />
              </div>
              <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
              <ButtonComp onClose={onClose} loader={loader} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddHeading;
