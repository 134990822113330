import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import add from "../../assets/images/add.svg";
import useWebContentStore from "../../state/useWebContentStore";
import Swal from "sweetalert2";
import InputFields from "../../components/form/InputField";
import ButtonComp from "../../components/cms/ButtonComp";
import { axiosUAMInstance } from "../../utils/axios";
import { on } from "stream";

interface Props {
  onClose: () => void;
  apiCall?: () => void;
  content: any;
}

interface FormValues {
  Name: string;
  text: string;
  Link: string;
  Image: File | null;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const Adddownload: React.FC<Props> = ({ onClose, apiCall, content }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const { webcontentData } = useWebContentStore();
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      text: "",
      Link: "",
      Name: "",
      Image: null,
    },
  });

  const imageFile = watch("Image");

  useEffect(() => {
    if (imageFile instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImagePreviewUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  const handleFormSubmit = async (formData: FormValues) => {
    setLoader(true);

    const newDownloadItem = {
      name: formData.text || "Text",
      text: formData.text,
      link: formData.Link || null,
      img: imagePreviewUrl,
    };

    // Push the new download item into the existing downloads array
    const updatedContent = { ...content };
    if (updatedContent.downloads) {
      updatedContent.downloads.push(newDownloadItem);
    } else if (updatedContent.downloadPageData?.downloads) {
      updatedContent.downloadPageData.downloads.push(newDownloadItem);
    }

    const payload = {
      id: 5,
      name: "Home",
      language_name: "ಮುಖಪುಟ",
      language_alingment: "left",
      downloadPageData: {
        ...updatedContent,
      },
    };
    console.log({ payload });

    try {
      const response = await axiosUAMInstance.post(
        `/websitecontent/update/${5}`,
        payload
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Website content updated successfully!",
        });
        // setTimeout(() => {
        //   onClose();
        //   if (apiCall) apiCall();
        //   window.location.reload();
        // }, 1000);
        onClose();
        if (apiCall) apiCall();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update website content. Please try again.",
      });
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setValue("Image", file);
      const fileUrl = URL.createObjectURL(file);
      setImagePreviewUrl(fileUrl);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          setImagePreviewUrl(uploadResponse?.data?.data?.fileurl);
          setValue("Image", uploadResponse?.data?.data?.fileurl);
          setValue("Link", uploadResponse?.data?.data?.fileurl);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    } else {
      setValue("Image", null);
      setImagePreviewUrl(null);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Add Download
          </h1>

          <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 md">
            <div
              className="custom-scrollbar p-4"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {/* <div className="my-4">
                <label
                  htmlFor="Name"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Name:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Name")}
                  placeholder="type here"
                />
              </div> */}

              <div className="my-4">
                <label
                  htmlFor="Text"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Text:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("text")}
                  placeholder="type here"
                />
              </div>

              <div className="my-4">
                <label
                  htmlFor="Image"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Document:
                </label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    className="mt-4 w-10"
                  />
                )}
              </div>

              <div className="my-4">
                <label
                  htmlFor="Link"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Link:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Link")}
                  placeholder="type here"
                  disabled={true}
                />
              </div>

              <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
              <ButtonComp onClose={onClose} loader={loader} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default Adddownload;
