import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import InputFields from "../../components/common/InputField";
import { Button } from "@mui/material";
import ReactTable from "../../components/common/ReactTable";
import addCircle from "../../assets/images/new_images/add-circle.png";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import DeleteButton from "../../assets/images/delete.svg";
import UploadButton from "../../components/common/UploadButton";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import EditRegistrationOtpModalTwo from "../../components/cms/EditRegistrationOtpModalTwo";
import directboxSend from "../../assets/images/directboxSend1.svg";
import NotificationsModal from "../../components/cms/NotificationsModal";

type TableType = {
  sr_no: string;
  documentName: string;
  addToRegistration: Date;
  addToProfile: Date;
  required: boolean;
};

const columnHelper = createColumnHelper<TableType>();

const defaultData: TableType[] = [
  {
    sr_no: "01",
    documentName: "About us",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
  {
    sr_no: "02",
    documentName: "FAQ",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
  {
    sr_no: "03",
    documentName: "External Links",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
  {
    sr_no: "04",
    documentName: "Website Policy",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
  {
    sr_no: "05",
    documentName: "Disclaimer",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
  {
    sr_no: "06",
    documentName: "Privacy Policy",
    addToRegistration: new Date("2024-12-02T22:56:00"),
    addToProfile: new Date("2024-12-02T22:56:00"),
    required: false,
  },
];

const CmsWebsiteNotifications = () => {
  const columns = [
    columnHelper.accessor("sr_no", {
      cell: (info) => info.renderValue(),
      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("documentName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("addToRegistration", {
      cell: (info) => info.row.original.addToProfile.toLocaleString(),
      header: () => <span>Added Date</span>,
    }),
    columnHelper.accessor("addToProfile", {
      cell: (info) => info.row.original.addToProfile.toLocaleString(),
      header: () => <span>Modify Date</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();

        return (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-1 lg:gap-6">
            <div>
              <ToggleSwitch enabled={false} />
            </div>
            <div>
              <ActionButton
                variant="edit"
                onClick={() => setModalOpenClose(true)}
              />
            </div>
            <div>
              <img src={DeleteButton} alt="icon" />
            </div>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const [modalOpenClose, setModalOpenClose] = useState<boolean>(false);

  const handleModal = () => {
    setModalOpenClose(false);
  };

  return (
    <Layout
      layout={
        <div className="pb-[44px] text-gilroy-medium">
          <div className="mt-4 md:pl-[42px] pl-4">
            <CmsTabs />
          </div>
          <div className="mt-[24px] md:pl-[40px] px-[16px] ">
            <h1 className="mb-[8px] text-black text-sm text-gilroy-medium font-bold">
              Notification Search
            </h1>
            <div className=" flex flex-col lg:flex-row flex-wrap lg:items-center gap-[8px] w-full md:w-auto ">
              <InputFields
                placeholder="Search by Unique ID/name"
                className="w-full lg:w-[621px] h-[40px] pl-[15px] border border-grey-300 rounded-[8px] focus:outline-none"
              />
              <div className="flex flex-row items-center flex-wrap gap-1">
                <div className="w-[181px] mr-1">
                  <UploadButton filename="Upload" imageSrc={directboxSend} />
                </div>
                <div className="w-52 h-[40px] border-[2px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] border-[#1c468e] flex justify-center items-center">
                  <img src={addCircle} alt="icon" />
                  <span className="ml-1 text-[14px] md:text-base font-normal text-[#1c468e] lg:text-[16px] text-gilroy-medium ">
                    Add Notifications
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[48px] md:px-[42px] px-4">
            <div className="mb-20">
              <ReactTable defaultData={defaultData} columns={columns} />
            </div>
          </div>
          <div className="md:px-[40px] px-2 mt-[88px]">
            {/* <CustomPagination
              totalItems={defaultData.length}
              itemsPerPage={5}
              maxPageNumbersToShow={5}
            /> */}
          </div>

          {modalOpenClose && <NotificationsModal onClose={handleModal} />}
        </div>
      }
    />
  );
};
export default CmsWebsiteNotifications;
