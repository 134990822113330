import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import ReactTable from "../../components/common/ReactTable";
import LoaderSpin from "../../components/LoaderSpin";
import { ColumnDef } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContentSix from "../../components/cms/EntityRegistrationOtpContentSix";
import { axiosUAMInstance } from "../../utils/axios";

interface ContentItem {
  id: number;
  img: string | null;
  link: string | null;
  name: string;
  text: string;
}

interface ContentSection {
  [key: string]: ContentItem[];
}

interface TableDataRow {
  section: string;
  id: number;
  text: string;
  link: string;
  img: string;
}

const CmsWebsiteContentInternalPageSix = () => {
  const [tableData, setTableData] = useState<TableDataRow[]>([]);
  const [loader, setLoader] = useState(true);
  const [isOpenModalOpen, setIsModalOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState<TableDataRow | null>(
    null
  );
  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/get/name?wcname=Contact Us`
      );
      const formattedData = formatData(response.data.data.content.data);
      setTableData(formattedData);
      setLoader(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatData = (data: ContentSection): TableDataRow[] => {
    return Object.entries(data).reduce<TableDataRow[]>((acc, [key, value]) => {
      value.forEach((item) => {
        acc.push({
          section: key,
          id: item.id,
          text: item.text,
          link: item.link || "N/A",
          img: item.img || "N/A",
        });
      });
      return acc;
    }, []);
  };

  const columns: ColumnDef<TableDataRow>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "section", header: "Section" },
    { accessorKey: "text", header: "Text" },
    { accessorKey: "link", header: "Link" },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: ({ row }: { row: { original: TableDataRow } }) => (
        <ActionButton
          variant="edit"
          onClick={() => {
            setIsModalOpen(true);
            setCurrentEditData(row.original);
          }}
        />
      ),
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="mt-4">
            <div className="flex justify-center items-center my-3">
              <h1 className="text-lg text-gilroy-regular">Contact Us</h1>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              {loader ? (
                <LoaderSpin />
              ) : tableData.length > 0 ? (
                <ReactTable defaultData={tableData} columns={columns} />
              ) : (
                <div className="flex justify-center items-center">
                  <h1>No data available</h1>
                </div>
              )}
            </div>
          </div>
          {isOpenModalOpen && (
            <EntityRegistrationOtpContentSix
              onClose={closeModal}
              currentEditData={currentEditData || {}}
              apiCall={fetchData}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPageSix;
