import create from "zustand";
import { CmsMasterBlockType } from "../Types/CmsMasterBlock";

type CmsMasterBlockState = {
  showPopup: boolean;
  openModel: boolean;
  loader: boolean;
  data: CmsMasterBlockType[];
  page: number;
  pageSize: number;
  searchText: string;
  total: number;
  setShowPopup: (show: boolean) => void;
  setShowModel: (show: boolean) => void;
  setLoader: (isLoading: boolean) => void;
  setData: (data: CmsMasterBlockType[]) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchText: (searchText: string) => void;
  setTotal: (total: number) => void;
};

const useCmsMasterBlockStore = create<CmsMasterBlockState>((set) => ({
  showPopup: false,
  openModel: false,
  loader: false,
  data: [],
  page: 1,
  pageSize: 10,
  searchText: "",
  total:0,
  setShowPopup: (show) => set({ showPopup: show }),
  setShowModel: (show) => set({ openModel: show }),
  setLoader: (isLoading) => set({ loader: isLoading }),
  setData: (data) => set({ data: data }),
  setPage: (page) => set({ page: page }),
  setPageSize: (pageSize) => set({ pageSize: pageSize }),
  setSearchText: (searchText) => set({ searchText: searchText }),
  setTotal: (total) => set({ total: total }),
}));

export default useCmsMasterBlockStore;
