import React, {
  FC,
  InputHTMLAttributes,
  forwardRef,
  KeyboardEvent,
} from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string;
  padding?: string;
  width?: string;
  widthLg?: string;
  widthMd?: string;
  error?: boolean;
  onEnterPress?: () => void;
}

const InputFieldSearch: FC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      height = "56px",
      padding = "8px 16px",
      width = "100%",
      widthLg,
      widthMd,
      className,
      error,
      onEnterPress,
      ...rest
    },
    ref
  ) => {
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onEnterPress) {
        onEnterPress();
      }
    };

    const baseClasses =
      "form-input border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-300";
    const errorClasses = error
      ? "border-red-500 text-red-500"
      : "border-[#1C468E] border-[1px]";

    const responsiveClasses = `
        ${width ? `w-[${width}]` : "w-full"} 
        ${widthMd ? `md:w-[${widthMd}]` : ""} 
        ${widthLg ? `lg:w-[${widthLg}]` : ""}
      `;

    return (
      <input
        type="text"
        className={`${baseClasses} ${errorClasses} ${responsiveClasses} ${className}`}
        style={{
          height,
          padding,
        }}
        {...rest}
        ref={ref}
        onKeyPress={handleKeyPress}
      />
    );
  }
);

export default InputFieldSearch;
