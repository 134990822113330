import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import LoaderSpin from "../../components/LoaderSpin";
import { ColumnDef } from "@tanstack/table-core";
import ReactTable from "../../components/common/ReactTable";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContentTwo from "../../components/cms/EntityRegistrationOtpContentTwo";
import Button from "../../components/form/Button";
import AddQuestionSection from "./AddQuestionSection";
import AddHeading from "./AddHeading";
import useWebContentStore from "../../state/useWebContentStore";
import { useNavigate } from "react-router-dom";
import EditQuestionSection from "./EditQuestionSection";
import { axiosUAMInstance } from "../../utils/axios";

interface WebContentData {
  id: number;
  name: string;
  text: string;
  img: string | null;
  link: string | null;
  section: string;
}
interface FAQSections {
  id: number;
  subHeading: string;
  // text: string;
  // img: string | null;
  // link: string | null;
  // section: string;
}

interface FaqData {
  status: string;
  message: string;
  code: number;
  data: any; // Adjusted for dynamic structure
  content: any; // Adjusted for dynamic structure
}

const CmsWebsiteContentInternalPageTwo = () => {
  const navigate = useNavigate();
  const { faqData, setFaqData, refresh } = useWebContentStore();
  const [sectionIdToEdit, setSectionIdToEdit] = useState<number>();
  const [loader, setLoader] = useState<boolean>(true);
  const [isOpenModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [IsOpenAddQuestionOpen, setIsOpenAddQuestionOpen] =
    useState<boolean>(false);
  const [IsOpenEditQuestionOpen, setIsOpenEditQuestionOpen] =
    useState<boolean>(false);
  const [currentEditData, setCurrentEditData] = useState<WebContentData | null>(
    null
  );
  const [isHeadingOpen, setIsHeadingOpen] = useState<boolean>(false);

  const [content, setContent] = useState<any>({});

  useEffect(() => {
    const fetchFaqData = async () => {
      setLoader(true);
      try {
        const response = await axiosUAMInstance.get(
          `/websitecontent/content/faq`
        );
        const data = response?.data?.data?.find(
          (d: any) => d?.language === "english"
        );
        console.log({ data });
        let modifiedFields =
          data?.content?.data?.faqPageData?.questionSections?.map(
            (subHead: any, i: number) => {
              return {
                id: i + 1,
                questions: subHead?.questions?.map((q: any, i: number) => {
                  return {
                    id: i + 1,
                    ...q,
                  };
                }),
                subHeading: subHead?.subHeading,
              };
            }
          );

        console.log({ modifiedFields });

        setFaqData({
          alldata: response?.data?.data,
          questionSections: modifiedFields,
        });
      } catch (error) {
        console.error("Failed to fetch FAQ data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchFaqData();
  }, [refresh]);

  const columns: ColumnDef<FAQSections>[] = [
    { accessorKey: "id", header: "ID" },
    // { accessorKey: "section", header: "Section" },
    {
      accessorKey: "subHeading",
      header: "Name",
      cell: (info: any) => {
        let name = info.getValue();
        return (
          <div>
            {name}{" "}
            <span
              className="text-blue-400 hover:cursor-pointer text-sm italic"
              onClick={() => {
                setSectionIdToEdit(info?.row?.index);
                setIsOpenEditQuestionOpen(true);
              }}
            >
              Edit
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: ({ row }) => (
        <div>
          <ActionButton
            variant="edit"
            onClick={() => {
              navigate(
                "/cms/websitecontent/internal/2/questions/" + row?.index
              );
            }}
          />
        </div>
      ),
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
    setIsHeadingOpen(false);
  };
  const closeAddQuestion = () => {
    setIsOpenAddQuestionOpen(false);
  };
  const closeEditQuestion = () => {
    setIsOpenEditQuestionOpen(false);
  };

  console.log({ faqData });

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>

          <div className="mt-4">
            <div className=" my-6  flex justify-end items-end ">
              <Button
                // loader={loader}
                type="button"
                label="Add  Question section"
                onClick={() => setIsOpenAddQuestionOpen(true)}
                loader={loader}
              />
            </div>
            <div className=" flex justify-center items-center my-3">
              <h1 className="text-lg text-gilroy-regular">FAQ Sections</h1>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div>
                {loader ? (
                  <LoaderSpin />
                ) : faqData?.questionSections?.length > 0 ? (
                  <ReactTable
                    defaultData={faqData?.questionSections}
                    columns={columns}
                  />
                ) : (
                  <div className="flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isHeadingOpen && (
            <AddHeading onClose={closeModal} content={content} />
          )}
          {isOpenModalOpen && (
            <EntityRegistrationOtpContentTwo
              onClose={closeModal}
              currentEditData={currentEditData || {}}
              data={faqData}
            />
          )}

          {IsOpenAddQuestionOpen && (
            <AddQuestionSection onClose={closeAddQuestion} content={faqData} />
          )}
          {IsOpenEditQuestionOpen && (
            <EditQuestionSection
              onClose={closeEditQuestion}
              sectionIdx={sectionIdToEdit}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPageTwo;
