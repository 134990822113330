import { create } from 'zustand';

interface CMSFormControl {
    formFieldUpdated : boolean,
    documentFieldUpdated : boolean,
    updatedFormField: () => void;
    updatedDocumentField: () => void;
}
const useCmsFormControlStore = create<CMSFormControl>(set => ({
    formFieldUpdated: false,
    documentFieldUpdated : false,
    updatedFormField : () => set(state => ({formFieldUpdated : !state.formFieldUpdated})),
    updatedDocumentField : () => set(state => ({ documentFieldUpdated : !state.documentFieldUpdated}))
}));

export default useCmsFormControlStore;