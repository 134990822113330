import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import add from "../../assets/images/add.svg";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface Props {
  onClose: () => void;
  apicall?: () => void;
}

const EntityRegistrationOtpContentFour: React.FC<Props> = ({
  onClose,
  apicall,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const { register, handleSubmit, setValue, getValues } = useForm();

  const [languages, setLanguages] = useState<any[]>([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axiosUAMInstance.get(
          `/websitecontent/content/Operating Guidelines`
        );
        if (response.data.status === "success") {
          setLanguages(response.data.data);
          response.data.data.forEach((lang: any) => {
            setValue(`name_${lang.id}`, lang.name);
            setValue(`link_${lang.id}`, lang.content.link);
          });
        }
      } catch (error) {
        console.error("Failed to fetch languages", error);
      }
    };
    fetchLanguages();
  }, [setValue]);

  const handleImageChange = async (langId: any, event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (uploadResponse.data.status === "success") {
          const newLink = uploadResponse.data.data.fileurl;
          setLanguages(
            languages.map((lang) => {
              if (lang.id === langId) {
                return { ...lang, content: { ...lang.content, link: newLink } };
              }
              return lang;
            })
          );
          setValue(`link_${langId}`, newLink);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    }
  };

  const handleFormSubmit = async () => {
    const data = languages.map((lang) => ({
      id: lang.id,
      language: lang.language,
      name: getValues(`name_${lang.id}`),
      language_name: lang.language_name,
      language_alignment: lang.language_alignment || "Right",
      content: {
        id: lang.content.id,
        link: getValues(`link_${lang.id}`),
        name: "link",
        text: lang.content.text,
      },
    }));

    console.log(data); // For debugging, see what is being sent to the server

    try {
      const response = await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        data
      );
      if (response.data.status === "success") {
        Swal.fire("Success", "Content updated successfully!", "success");

        onClose();
        if (apicall) {
          apicall();
        }
      } else {
        Swal.fire(
          "Error",
          "Failed to update content. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Failed to update content", error);
      Swal.fire("Error", "An error occurred during the update.", "error");
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Edit Website Content
          </h1>
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="custom-scrollbar p-4"
            style={{ maxHeight: "450px", overflowY: "auto" }}
          >
            {languages.map((lang: any) => (
              <div key={lang.id} className="my-4">
                <h3 className="text-xl font-bold mb-2">
                  Content: ({lang.language})
                </h3>
                {/* <InputFields
                  defaultValue={lang.name}
                  {...register(`name_${lang.id}`)}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Document Name"
                /> */}
                <input
                  type="file"
                  onChange={(e) => handleImageChange(lang.id, e)}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {lang.content.link && (
                  <img
                    src={lang.content.link}
                    alt="Preview"
                    className="mt-4 w-9"
                  />
                )}
                <InputFields
                  defaultValue={lang.content.link}
                  {...register(`link_${lang.id}`)}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled={true}
                />
              </div>
            ))}
            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <ButtonComp onClose={onClose} loader={loader} />
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContentFour;
