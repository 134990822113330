// stateStore.ts
import create from "zustand";
import { CmsMasterStateType } from "../Types/CmsMasterState";

type State = {
  showPopup: boolean;
  openModel: boolean;
  loader: boolean;
  data: CmsMasterStateType[];
  page: number;
  pageSize: number;
  searchText: string;
  message: string;
  total: number;
  setShowPopup: (show: boolean) => void;
  setShowModel: (show: boolean) => void;
  setLoader: (isLoading: boolean) => void;
  setData: (data: CmsMasterStateType[]) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchText: (text: string) => void;
  setMessage: (message: string) => void;
  setTotal: (total: number) => void;
};

const useCmsMasterStateStore = create<State>((set) => ({
  showPopup: false,
  openModel: false,
  loader: false,
  data: [],
  page: 1,
  pageSize: 10,
  searchText: "",
  message: "",
  total:0,
  setShowPopup: (show) => set({ showPopup: show }),
  setShowModel: (show) => set({ openModel: show }),
  setLoader: (isLoading) => set({ loader: isLoading }),
  setData: (data) => set({ data: data }),
  setPage: (page) => set({ page: page }),
  setPageSize: (pageSize) => set({ pageSize: pageSize }),
  setSearchText: (text) => set({ searchText: text }),
  setMessage: (text) => set({ message: text }),
  setTotal: (total) => set({ total: total })
}));

export default useCmsMasterStateStore;
