import React, { useRef, useState } from "react";
import LeftIcon from "../../assets/images/Left Icons.png";
import Swal from "sweetalert2";
import LoaderSpin from "../LoaderSpin";
import { axiosTraceIdInstance } from "../../utils/axios";

type Props = {
  languageId: string;
};

const FileUpload = ({ languageId }: Props) => {
  const [loader, setLoader] = useState(false);
  const inputRef = useRef<any>();
  const handleFileUpload = (event: any) => {
    setLoader(true);
    const file = event.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axiosTraceIdInstance
      .post(`/language/label/bulk-upload/${languageId}`, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        let data = res.data;
        if (data?.success) {
          Swal.fire({
            text: data?.message,
            title: "Success",
            icon: "success",
          });
          return;
        } else {
          Swal.fire({
            text: data?.message,
            title: "Error",
            icon: "error",
          });
          return;
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Error uploading file, Try Later!",
        });
      })
      .finally(() => setLoader(false));
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div className="flex items-center justify-center">
      {loader ? (
        <LoaderSpin />
      ) : (
        <img
          src={LeftIcon}
          alt="Attachment"
          className=" ml-1 w-5 h-5 hover:cursor-pointer"
          onClick={handleClick}
        />
      )}
      <input
        disabled={loader}
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleFileUpload}
        accept=".csv, .xls, .xlsx"
      />
    </div>
  );
};

export default FileUpload;
