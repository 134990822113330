// import React, { FC, useRef, useState } from "react";
// import Calender from "../../../src/assets/images/calender.svg";
// import { dateFormattor } from "../../utils/helperFunctions";

// interface DatePickerProps {
//   onChange: (date: Date) => void;
//   userValue?: string;
//   disabled?: boolean;
// }

// const DatePicker: FC<DatePickerProps> = ({ onChange, disabled, userValue }) => {
//   const hiddenDateInput = useRef<HTMLInputElement>(null);
//   const [dateSelected, setDateSelected] = useState<any>(undefined);

//   const handleDateButtonClick = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     event.preventDefault(); // Prevent the default form submission behavior
//     const element: any = document.getElementById("datePicker");
//     if (element && "showPicker" in element) {
//       element?.showPicker();
//     }
//     if (hiddenDateInput.current) {
//       hiddenDateInput.current.showPicker();
//     }
//   };

//   const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value } = event.target;
//     if (value) {
//       const selectedDate = new Date(value);
//       setDateSelected(dateFormattor(selectedDate));
//       onChange(selectedDate);
//     }
//   };

//   return (
//     <div className="flex justify-start items-center w-[100%] border rounded-md">
//       <button
//         onClick={handleDateButtonClick}
//         className="flex justify-between h-[56px] w-full px-[8px] py-[16px] rounded-lg text-gray-600 bg-[#f2f2f2] hover:bg-[#f2f2f2] focus:outline-none"
//       >
//         {dateSelected ? dateSelected : "Select Date"}
//         <div>
//           <img src={Calender} alt="calender" />
//         </div>
//       </button>
//       <input
//         disabled={disabled ? disabled : false}
//         type="date"
//         ref={hiddenDateInput}
//         defaultValue={userValue}
//         id="datePicker"
//         className="absolute -z-10"
//         onChange={onChangeHandler}
//       />
//     </div>
//   );
// };

// export default DatePicker;
import React, { FC, useRef, useState } from "react";
import Calender from "../../../src/assets/images/calender.svg";
import { dateFormattor } from "../../utils/helperFunctions";

interface DatePickerProps {
  onChange: (date: Date) => void;
  userValue?: string;
  disabled?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({ onChange, disabled, userValue }) => {
  const hiddenDateInput = useRef<HTMLInputElement>(null);
  const [dateSelected, setDateSelected] = useState<any>(userValue);

  const handleDateButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (disabled) return; // Prevent interaction if disabled
    const element: any = document.getElementById("datePicker");
    if (element && "showPicker" in element) {
      element?.showPicker();
    }
    if (hiddenDateInput.current) {
      hiddenDateInput.current.showPicker();
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      const selectedDate = new Date(value);
      setDateSelected(dateFormattor(selectedDate));
      onChange(selectedDate);
    }
  };

  return (
    // <div className="flex justify-start items-center w-[350px] border border-[#7e7e7e] rounded-md">
    <div className="flex justify-start items-center border border-[#7e7e7e] rounded-md">
      <button
        onClick={handleDateButtonClick}
        className={`flex justify-between h-[56px] w-full px-[8px] py-[16px] rounded-lg text-gray-600 ${
          disabled ? " cursor-not-allowed" : "bg-[#f2f2f2] hover:bg-[#f2f2f2]"
        } focus:outline-none`}
        disabled={disabled} // Disable the button if disabled prop is true
      >
        {dateSelected ? dateSelected : "Select Date"}
        <div>
          <img src={Calender} alt="calender" />
        </div>
      </button>
      <input
        disabled={disabled ? disabled : false}
        type="date"
        ref={hiddenDateInput}
        defaultValue={userValue}
        id="datePicker"
        className="absolute -z-10"
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default DatePicker;
