import * as Yup from 'yup';

const AddFormFieldValidationModalschemaScheme = Yup.object().shape({
  fieldName: Yup.string()
    .required('Field name is required'),
  placeholder: Yup.string()
    .required('Placeholder is required'),
  schemeCreator: Yup.number()
    .required('Scheme Creator is required'),
  typeId: Yup.number()
    .required('Field Type is required')
});

export default AddFormFieldValidationModalschemaScheme;
