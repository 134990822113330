// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import ErrorCircle from "../../assets/images/error-circle.svg";
// import add from "../../assets/images/add.svg";
// import TextArea from "../common/TextArea";
// import SelectButton from "../form/SelectButton";
// import ButtonComp from "../cms/ButtonComp";
// import { axiosUAMInstance } from "../../utils/axios";
// import { useLocation, useNavigate } from "react-router-dom";

// interface ReturnModelPopupProps {
//   onClose: () => void;
//   onSave: () => void;
// }

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   p: 4,
// };

// const ReferModelPopup: React.FC<ReturnModelPopupProps> = ({
//   onClose,
//   onSave,
// }) => {
//   const handleCloseModal = () => {
//     // Call onClose function passed from parent component to close the modal
//     onClose();
//   };
//   const countWords = (text: string) => {
//     const trimmedText = text.trim();
//     const words = trimmedText.split(/\s+/);
//     return words.length;
//   };
//   const [text, setText] = useState<string>("");
//   const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
//   const [searchInputValue1, setSearchInputValue1] = useState<string>("");
//   const [isSelected, setSelected] = useState<boolean>(false);
//   const [istextEntered, setTextEntered] = useState<boolean>(false);
//   const [loader, setLoader] = useState<boolean>(false);
//   const [isApiSucess, setApiSuccess] = useState<boolean>(false);
//   const [isApiError, setApiError] = useState<boolean>(false);
//   const location = useLocation();
//   const depositTakerId = location.state?.depositTakerId;
//   const navigate = useNavigate();
//   const approverId = parseInt(sessionStorage.getItem("userId") || "0", 10);

//   useEffect(() => {
//     setSelected(false);
//   }, [selectedOption1]);

//   useEffect(() => {
//     setTextEntered(false);
//   }, [text]);

//   const options1 = [
//     { value: "Pvt Ltd", label: "Pvt Ltd" },
//     { value: "LLP", label: "LLP" },
//     { value: "Sole Partnership", label: "Sole Partnership" },
//   ];
//   const handleSetOption1 = (option: any) => {
//     setSelectedOption1(option.value);
//   };
//   const handleSearchInputChange1 = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     setSearchInputValue1(event.target.value);
//   };

//   const handleChange = (event: any) => {
//     setText(event.target.value);
//   };

//   const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     try {
//       setLoader(true);
//       const response = await axiosUAMInstance.post(
//         `/approval-engine/update-status`,
//         {
//           uniqueId: depositTakerId,
//           status: "REFER_TO_REGULATOR",
//           approverId: approverId,
//         }
//       );
//       setLoader(false);
//       console.log(response, "response");
//       // if (response.status === 201) {
//       if (response) {
//         setApiSuccess(true);
//         setApiError(false);

//         setTimeout(() => {
//           onClose();
//           onSave();
//           navigate("/mytask/deposit");
//         }, 2500);
//       } else {
//       }
//     } catch (error) {
//       setLoader(false);
//       setApiError(true);
//       setApiSuccess(false);
//     }

//     // onClose();
//     // onSave();
//   };

//   return (
//     <>
//       <Modal
//         open={true}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         onClose={handleCloseModal}
//       >
//         <Box sx={style}>
//           <div className="md:flex">
//             <div className="m-[16px] md:m-16 w-[350px] md:w-[500px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
//               <div
//                 className="flex flex-row justify-end mb-[12px] cursor-pointer"
//                 onClick={handleCloseModal}
//               >
//                 <img src={add} className="w-6 h-6" alt="icon" />
//               </div>
//               <div className=" flex flex-col  justify-center items-center">
//                 <img
//                   src={ErrorCircle}
//                   alt="ErrorCircle "
//                   className="w-12 my-2"
//                 />
//                 <h1 className="text-xl font-normal text-gilroy-medium">
//                   Are you sure you want to refer ?
//                 </h1>
//                 {isApiSucess && (
//                   <p className="text-green-700 absolute top-[120px]">
//                     {/* Deposit taker referred to regulator successfully. */}
//                     Deposit taker referred to regulator successfully.
//                   </p>
//                 )}
//                 {isApiError && (
//                   <p className="text-red-500 absolute top-[120px]">
//                     Internal Server Error
//                   </p>
//                 )}
//               </div>
//               <form onSubmit={handleFormSubmit}>
//                 <div>
//                   <hr className="w-full bg-[#E6E6E6] mt-[27px] mb-[24px]"></hr>
//                   <ButtonComp onClose={onClose} title="Refer" loader={loader} />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default ReferModelPopup;
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorCircle from "../../assets/images/error-circle.svg";
import add from "../../assets/images/add.svg";
import ButtonComp from "../cms/ButtonComp";
import { axiosUAMInstance } from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface ReturnModelPopupProps {
  onClose: () => void;
  onSave: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ReferModelPopup: React.FC<ReturnModelPopupProps> = ({
  onClose,
  onSave,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();
  const depositTakerId = location.state?.depositTakerId;
  const status = location?.state?.status;
  console.log(typeof status, "status");

  const navigate = useNavigate();
  const approverId = parseInt(sessionStorage.getItem("userId") || "0");

  const handleCloseModal = () => {
    onClose();
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoader(true);

      const response = await axiosUAMInstance.post(
        `/approval-engine/update-status`,
        {
          uniqueId: depositTakerId,
          // status: "REFER_TO_REGULATOR",
          status:
            status === "MOD_PENDING" || status === "MOD_TRANSIT"
              ? "MOD_REFER_TO_REGULATOR"
              : "REFER_TO_REGULATOR",
          approverId: approverId,
        }
      );
      setLoader(false);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deposit taker referred to regulator successfully.",
        });

        setTimeout(() => {
          onClose();
          onSave();
          navigate("/mytask/deposit");
        }, 2500);
      }
    } catch (error: any) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Internal Server Error",
      });
    }
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}
      >
        <Box sx={style}>
          <div className="md:flex">
            <div className="m-[16px] md:m-16 w-[350px] md:w-[500px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
              <div
                className="flex flex-row justify-end mb-[12px] cursor-pointer"
                onClick={handleCloseModal}
              >
                <img src={add} className="w-6 h-6" alt="icon" />
              </div>
              <div className=" flex flex-col  justify-center items-center">
                <img
                  src={ErrorCircle}
                  alt="ErrorCircle "
                  className="w-12 my-2"
                />
                <h1 className="text-xl font-normal text-gilroy-medium">
                  Are you sure you want to refer?
                </h1>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <hr className="w-full bg-[#E6E6E6] mt-[27px] mb-[24px]"></hr>
                  <ButtonComp onClose={onClose} title="Refer" loader={loader} />
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReferModelPopup;
