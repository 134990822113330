import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import LoaderSpin from "../../components/LoaderSpin";
import { ColumnDef } from "@tanstack/table-core";
import ReactTable from "../../components/common/ReactTable";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContent from "../../components/cms/EntityRegistrationOtpContent";
import useWebContentStore from "../../state/useWebContentStore";
import { useLocation } from "react-router-dom";
import Button from "../../components/form/Button";
import Addcarousel from "./Addcarousel";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

interface WebContentData {
  id: number;
  name: string;
  text: string;
  img: string | null;
  link: string | null;
  section: string;
}

const CmsWebsiteContentInternalPage = () => {
  const location = useLocation();
  const id = location.state?.id;
  const Name = location.state?.ame;

  const {
    webcontentData,
    loader,
    isModalOpen,
    currentEditData,
    setWebContentData,
    setLoader,
    setIsModalOpen,
    setCurrentEditData,
    resetModal,
    isOpenCarouselOpen,
    setIsOpenCarouselOpen,
  } = useWebContentStore();
  // console.log({ data });

  const [content, setContent] = useState<any>({});

  const apicall = async () => {
    setLoader(true);
    try {
      const data = await axiosUAMInstance.get(
        `/websitecontent/get/name?wcname=Home`
      );

      const content = data?.data?.data?.content?.updatedStructure;
      setContent(content);

      setWebContentData(transformDataToTableFormat(content));
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    apicall();
  }, []);

  const transformDataToTableFormat = (content: any): WebContentData[] => {
    const { homePageData } = content;
    const allData: any = [];

    Object.keys(homePageData).forEach((section) => {
      homePageData[section].forEach((item: any) => {
        allData.push({
          ...item,
          section: section,
        });
      });
    });

    return allData;
  };

  const closeModal = () => {
    resetModal();
  };
  // Function to remove a carousel item by id
  const removeCarouselItem = async (carouselId: any) => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/Home`
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch website content.");
      }

      const contentData = response.data.data;
      contentData.forEach((content: any) => {
        if (content.content.updatedStructure.homePageData.carousel) {
          content.content.updatedStructure.homePageData.carousel =
            content.content.updatedStructure.homePageData.carousel.filter(
              (item: any) => item.id !== carouselId
            );
        }
      });
      console.error({ content }, "content........ remove");

      const updateResponse = await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        contentData
      );

      if (updateResponse.status === 200) {
        Swal.fire("Success", "Carousel item removed successfully!", "success");
        apicall();
      } else {
        throw new Error("Failed to update website content.");
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "Failed to update website content. Please try again.",
        "error"
      );
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const columns: ColumnDef<WebContentData>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "section", header: "Section" },
    {
      accessorKey: "text",
      header: "Text",
      cell: ({ getValue }) => (getValue() ? getValue() : "N/A"),
    },
    {
      accessorKey: "img",
      header: "Image",
      cell: ({ getValue }) =>
        getValue() ? (
          <img
            src={getValue() as string}
            alt="img"
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          "No Image"
        ),
    },
    {
      accessorKey: "link",
      header: "Link",
      cell: ({ getValue }: any) =>
        getValue() ? <div className="max-w-[200px]">{getValue()}</div> : "N/A",
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <ActionButton
            variant="edit"
            onClick={() => {
              setIsModalOpen(true);
              setCurrentEditData(row?.original);
            }}
          />
          {row.original.section === "carousel" && (
            <ActionButton
              variant="delete"
              onClick={() => {
                // console.error(row?.original?.id, ".....delete icon");
                removeCarouselItem(row?.original?.id);
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>

          <div className="mt-4">
            <div className=" my-6  flex justify-end items-end ">
              <Button
                // loader={loader}
                type="button"
                label="Add carousel"
                onClick={() => setIsOpenCarouselOpen(true)}
              />
            </div>
            <div className=" flex justify-center items-center my-3">
              <h1 className="text-lg text-gilroy-regular">Home</h1>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div>
                {loader ? (
                  <LoaderSpin />
                ) : webcontentData?.length > 0 ? (
                  <ReactTable defaultData={webcontentData} columns={columns} />
                ) : (
                  <div className="flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isOpenCarouselOpen && (
            <Addcarousel
              onClose={closeModal}
              apiCall={apicall}

              // content={content}
            />
          )}
          {isModalOpen && (
            <EntityRegistrationOtpContent
              onClose={closeModal}
              data={currentEditData || {}}
              apiCall={apicall}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPage;
