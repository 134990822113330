import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import add from "../../assets/images/add.svg";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  Name: string;
  Text: string;
  Link: string;
}

interface Props {
  onClose: () => void;
  currentEditData?: any;
  data?: any;
  apiCall?: () => void;
}

const EntityRegistrationOtpContentTwo: React.FC<Props> = ({
  onClose,
  currentEditData,
  apiCall,
  data,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      Name: currentEditData?.name || "",
      Text: currentEditData?.text || "",
      Link: currentEditData?.link || "",
    },
  });

  useEffect(() => {
    if (currentEditData) {
      setValue("Name", currentEditData.name);
      setValue("Text", currentEditData.text);
      setValue("Link", currentEditData.link);
    }
  }, [currentEditData, setValue]);

  const handleFormSubmit = async (formData: FormValues) => {
    setLoader(true);
    const updatedSections = data?.map((section: any) => {
      if (section?.id === currentEditData?.id) {
        return {
          ...section,
          name: formData?.Name,
          text: formData?.Text,
          link: formData?.Link,
        };
      }
      return section;
    });
    console.log({ updatedSections }, "update section");

    const payload = {
      id: 2,
      faqPageData: {
        arrows: updatedSections
          .filter((s: any) => s?.section === "arrows")
          .map((arrow: any) => ({
            name: arrow?.name,
            text: arrow?.text,
            img: arrow?.img,
            link: arrow?.link,
          })),
        heading: updatedSections
          .filter((s: any) => s?.section === "heading")
          .map((heading: any) => ({
            text: heading?.text,
          })),
        questionSections: updatedSections
          .filter((s: any) => s?.section.includes("About CKYC"))
          .map((question: any) => ({
            subHeading: [{ text: question?.section }],
            questions: question?.questions?.map((q: any) => ({
              question: q?.question,
              answer: q?.answer,
            })),
          })),
      },
    };

    try {
      const updatedContent = { ...data };

      if (currentEditData.section === "arrows") {
        updatedContent.faqPageData.arrows =
          updatedContent.faqPageData.arrows.map((item: any) =>
            item.name === currentEditData.name
              ? {
                  ...item,
                  name: formData.Name,
                  text: formData.Text,
                  link: formData.Link,
                }
              : item
          );
      } else if (currentEditData.section === "heading") {
        updatedContent.faqPageData.heading =
          updatedContent.faqPageData.heading.map((item: any) =>
            item.text === currentEditData.text
              ? { ...item, text: formData.Text }
              : item
          );
      } else {
        updatedContent.faqPageData.questionSections =
          updatedContent.faqPageData.questionSections.map((section: any) => {
            if (section.subHeading[0].text === currentEditData.section) {
              return {
                ...section,
                questions: section.questions.map((q: any) => {
                  if (
                    q.question.name === currentEditData.name ||
                    q.answer === currentEditData.text
                  ) {
                    return {
                      ...q,
                      question: {
                        ...q.question,
                        name: formData.Name.includes("Q")
                          ? formData.Name
                          : q.question.name,
                        text: formData.Name.includes("Q")
                          ? formData.Text
                          : q.question.text,
                      },
                      answer: formData.Name.includes("A")
                        ? formData.Text
                        : q.answer,
                    };
                  }
                  return q;
                }),
              };
            }
            return section;
          });
      }

      console.log({ updatedContent }, "update content");

      setLoader(false);
      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 1000);
    } catch (error) {
      // Swal.fire("Error", "Failed to update content", "error");
      // console.error("Failed to update content:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Website Content
          </h1>

          <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 md">
            <div
              className="custom-scrollbar p-4"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="my-4">
                <label
                  htmlFor="Name"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Name:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Name")}
                  placeholder="type here"
                />
              </div>

              <div className="my-4">
                <label
                  htmlFor="Text"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Text:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Text")}
                  placeholder="type here"
                />
              </div>

              <div className="my-4">
                <label
                  htmlFor="Link"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Link:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Link")}
                  placeholder="type here"
                />
              </div>

              <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
              <ButtonComp onClose={onClose} loader={loader} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContentTwo;
