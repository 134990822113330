import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import ReactTable from "../../components/common/ReactTable";
import LoaderSpin from "../../components/LoaderSpin";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContentFour from "../../components/cms/EtityRegistrationOtpContentFour";
import { axiosUAMInstance } from "../../utils/axios";

const CmsWebsiteContentInternalPageFour = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [loader, setLoader] = useState(true);
  const [isOpenModalOpen, setIsModalOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);

  const fetchContent = async () => {
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/get/name?wcname=Operating Guidelines`
      );
      const apiData = response.data.data;
      setTableData([
        {
          id: apiData.id,
          name: apiData.name,
          link: apiData.content.link,
          text: apiData.content.text,
        },
      ]);
    } catch (error) {
      console.error("Failed to fetch data", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const columns = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "name", header: "Name" },
    {
      accessorKey: "link",
      header: "Link",
      cell: (info: any) => (
        <a
          href={info.getValue() || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {info.getValue() ? "Visit Link" : "No Link"}
        </a>
      ),
    },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: ({ row }: any) => (
        <ActionButton
          variant="edit"
          onClick={() => {
            setIsModalOpen(true);
            setCurrentEditData(row.original);
          }}
        />
      ),
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="mt-4">
            <h1 className=" flex justify-center items-center text-lg text-gilroy-regular my-4">
              Operating Guidelines
            </h1>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              {loader ? (
                <LoaderSpin />
              ) : tableData.length > 0 ? (
                <ReactTable defaultData={tableData} columns={columns} />
              ) : (
                <div className="flex justify-center items-center">
                  <h1>No data available</h1>
                </div>
              )}
            </div>
          </div>
          {isOpenModalOpen && (
            <EntityRegistrationOtpContentFour
              onClose={closeModal}
              // currentEditData={currentEditData || {}}
              // data={tableData}
              apicall={fetchContent}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPageFour;
