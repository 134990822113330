import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoute";
import DashboardPage from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import SetPasswordPage from "./pages/SetPasswordPage";
import CmsRegister from "./pages/cms/CmsRegister";
import EntityMaster from "./pages/EntityMaster/EntityMaster";
import Mis from "./pages/Mis";
import MyTask from "./pages/myTask/MyTask";
import SchemeMaster from "./pages/SchemeMaster/SchemeMaster";
// import UserManagement from "./pages/UserManagement";
import CmsRegisterFormControl from "./pages/cms/CmsRegisterFormControl";
import CmsRegisterTree from "./pages/cms/CmsRegisterTree";
import CmsScheme from "./pages/cms/CmsScheme";
import CmsTemplate from "./pages/cms/cmsTemplate";

import CmsMaster from "./pages/cms/CmsMaster";
import CmsMasterCAuthority from "./pages/cms/CmsMasterCAuthority";

import CmsWebsiteContent from "./pages/cms/CmsWebsiteContent";
import CmsWebsiteNotifications from "./pages/cms/CmsWebsiteNotifications";
import CmsMasterCAuthorityForm from "./pages/cms/CmsMasterCAuthorityForm";
import CmsMasterdesignatedcourt from "./pages/cms/CmsMasterdesignatedcourt";
import CmsMasterdesignatedcourtForm from "./pages/cms/CmsMasterdesignatedcourtForm";
import CmsMasterApprovedDocument from "./pages/cms/CmsMasterApprovedDocument";
import CsmMasterLanguage from "./pages/cms/CsmMasterLanguage";
// import UserManage from "./pages/UserManagement";
import CmsMasterState from "./pages/cms/CmsMasterState";
import CmsMasterDistrict from "./pages/cms/CmsMasterDistrict";
import CmsMasterBlock from "./pages/cms/CmsMasterBlock";
import MyTaskRegulator from "./pages/myTask/MyTaskRegulator";
import MyTaskCompetentAuthority from "./pages/myTask/MyTaskCompetentAuthority";
import MyTaskDesignatedCourt from "./pages/myTask/MyTaskDesignatedCourt";
import MyTaskForm from "./pages/myTask/MyTaskForm";
import MyTaskRegulatorForm from "./pages/myTask/MyTaskRegulatorForm";
import MyTaskCompetentAuthorityForm from "./pages/myTask/MyTaskCompetentAuthorityForm";
import MyTaskDesignatedCourtForm from "./pages/myTask/MyTaskDesignateCourtForm";
import EntityMasterRegulator from "./pages/EntityMaster/EntityMasterRegulator";
import EntityMasterCompetentAuthority from "./pages/EntityMaster/EntityMasterCompetentAuthority";
import EntityMasterDesignateCourt from "./pages/EntityMaster/EntityMasterDesignateCourt";
import EntityMasterForm from "./pages/EntityMaster/EntityMasterForm";
import EntityMasterRegulatorForm from "./pages/EntityMaster/EntityMasterRegulatorForm";
import EntityMasterCompetentAuthorityForm from "./pages/EntityMaster/EntityMasterCompetentAuthorityForm";
import EntityMasterDesignateCourtForm from "./pages/EntityMaster/EntityMasterDesignateCourtForm";
import SchemeMasterForm from "./pages/SchemeMaster/SchemeMasterForm";
import UserCreation from "./pages/UserManagement/UserCreation";
import AddUser from "./pages/UserManagement/AddUser";
import RoleCreation from "./pages/UserManagement/RoleCreation";
import EditUser from "./pages/UserManagement/EditUser";
import CmsMasterRegulatorForm from "./pages/cms/CmsMasterRegulatorForm";
import CmsMasterRegulators from "./pages/cms/CmsMasterRegulators";
import SetNewPasswordModel from "./components/common/SetNewPasswordModel";
import OtpModel from "./components/common/OtpModel";
import CmsWebsiteContentInternalPage from "./pages/cms/CmsWebsiteContentInternalPage";
import CmsWebsiteContentInternalPageTwo from "./pages/cms/CmsWebsiteContentInternalPageTwo";
import CmsWebsiteContentInternalPageThree from "./pages/cms/CmsWebsiteContentInternalPageThree";
import CmsWebsiteContentInternalPageFour from "./pages/cms/CmsWebsiteContentInternalPageFour";
import CmsWebsiteContentInternalPageFive from "./pages/cms/CmsWebsiteContentInternalPageFive";
import CmsWebsiteContentInternalPageSix from "./pages/cms/CmsWebsiteContentInternalPageSix";
import CMSWebsiteContentInternalPageTwoQuestions from "./pages/cms/CMSWebsiteContentInternalPageTwoQuestions";
import UpdateDSC3 from "./pages/UserManagement/UpdateDSC3";
import ResetPassword from "./pages/UserManagement/ResetPassword";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<LoginPage />} path="/" />
          {/* <Route element={<SetPasswordPage />} path="/setpassword" /> */}
          <Route element={<SetNewPasswordModel />} path="/setpassword" />
          <Route element={<OtpModel />} path="/otp-verification" />
          <Route element={<PrivateRoutes />}>
            <Route element={<DashboardPage />} path="/dashboard" />
            <Route element={<UpdateDSC3 />} path="/updatedsc3" />
            <Route element={<ResetPassword />} path="/resetpassword" />
            <Route element={<EntityMaster />} path="/entitymaster/deposit" />
            <Route
              element={<EntityMasterForm />}
              path="/entitymaster/deposit/form"
            />
            <Route
              element={<EntityMasterRegulator />}
              path="/entitymaster/regulator"
            />
            <Route
              element={<EntityMasterRegulatorForm />}
              path="/entitymaster/regulator/form"
            />
            <Route
              element={<EntityMasterCompetentAuthority />}
              path="/entitymaster/authority"
            />
            <Route
              element={<EntityMasterCompetentAuthorityForm />}
              path="/entitymaster/authority/form"
            />
            <Route
              element={<EntityMasterDesignateCourt />}
              path="/entitymaster/designatedcourt"
            />
            <Route
              element={<EntityMasterDesignateCourtForm />}
              path="/entitymaster/designatedcourt/form"
            />
            <Route element={<Mis />} path="/mis" />
            <Route element={<MyTask />} path="/mytask/deposit" />
            <Route element={<MyTaskForm />} path="/mytask/deposit/form" />
            <Route element={<MyTaskRegulator />} path="/mytask/regulator" />
            <Route
              element={<MyTaskRegulatorForm />}
              path="/mytask/regulator/form"
            />
            <Route
              element={<MyTaskCompetentAuthority />}
              path="/mytask/authority"
            />
            <Route
              element={<MyTaskCompetentAuthorityForm />}
              path="/mytask/authority/form"
            />
            <Route
              element={<MyTaskDesignatedCourt />}
              path="/mytask/designatedcourt"
            />
            <Route
              element={<MyTaskDesignatedCourtForm />}
              path="/mytask/designatedcourt/form"
            />
            <Route element={<SchemeMaster />} path="/schememaster" />
            <Route element={<SchemeMasterForm />} path="/schememaster/form" />
            {/* <Route element={<UserManagement />} path="/usermanagement" /> */}
            <Route element={<CmsRegister />} path="/cms/register" />
            <Route element={<CmsScheme />} path="/cms/scheme" />
            <Route element={<CmsTemplate />} path="/cms/templates" />
            {/* <Route element={<CmsMaster />} path="/cms/master" /> */}
            <Route element={<CmsMasterState />} path="/cms/master/master" />
            <Route
              element={<CmsMasterDistrict />}
              path="/cms/master/master/district/:sid"
            />
            <Route
              element={<CmsMasterBlock />}
              path="/cms/master/master/block/:did"
            />
            <Route
              element={<CmsMasterCAuthority />}
              path="/cms/master/authority"
            />
            <Route
              element={<CmsMasterCAuthorityForm />}
              path="/cms/master/authority/form"
            />
            <Route
              element={<CmsMasterdesignatedcourt />}
              path="/cms/master/designatedcourt"
            />
            <Route
              element={<CmsMasterRegulators />}
              path="/cms/master/regulator"
            />
            <Route
              element={<CmsMasterdesignatedcourtForm />}
              path="/cms/master/designatedcourt/form"
            />
            <Route
              element={<CmsMasterRegulatorForm />}
              path="/cms/master/regulator/form"
            />
            <Route
              element={<CmsMasterApprovedDocument />}
              path="/cms/master/approved-document"
            />
            <Route
              element={<CsmMasterLanguage />}
              path="/cms/master/languages"
            />
            <Route element={<CmsWebsiteContent />} path="/cms/websitecontent" />
            <Route
              element={<CmsWebsiteContentInternalPage />}
              path="/cms/websitecontent/internal/1"
            />
            <Route
              element={<CmsWebsiteContentInternalPageTwo />}
              path="/cms/websitecontent/internal/2"
            />
            <Route
              element={<CMSWebsiteContentInternalPageTwoQuestions />}
              path="/cms/websitecontent/internal/2/questions/:id"
            />
            <Route
              element={<CmsWebsiteContentInternalPageThree />}
              path="/cms/websitecontent/internal/3"
            />
            <Route
              element={<CmsWebsiteContentInternalPageFour />}
              path="/cms/websitecontent/internal/4"
            />
            <Route
              element={<CmsWebsiteContentInternalPageFive />}
              path="/cms/websitecontent/internal/5"
            />
            <Route
              element={<CmsWebsiteContentInternalPageSix />}
              path="/cms/websitecontent/internal/6"
            />
            <Route
              element={<CmsWebsiteNotifications />}
              path="/cms/websitenotifications"
            />
            <Route
              element={<CmsWebsiteNotifications />}
              path="/cms/websitenotifications"
            />
            <Route element={<RoleCreation />} path="/usermanagement/role" />
            <Route element={<UserCreation />} path="/usermanagement/user" />
            <Route element={<AddUser />} path="/usermanagement/adduser" />
            <Route element={<EditUser />} path="/usermanagement/edituser" />
            <Route
              element={<CmsRegisterFormControl />}
              path="/cms/register/formcontrol"
            />
            <Route element={<CmsRegisterTree />} path="/cms/register/tree" />{" "}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
