import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import add from "../../assets/images/add.svg";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  [key: string]: any;
}

interface Props {
  onClose: () => void;
  currentEditData?: any;
  apiCall?: () => void;
}

const EntityRegistrationOtpContentThree: React.FC<Props> = ({
  onClose,
  currentEditData,
  apiCall,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>([]);
  const [textInputs, setTextInputs] = useState<any[]>([]);
  const [imagePreviews, setImagePreviews] = useState<{
    [key: string]: string | null;
  }>({});
  const [linkDisabled, setLinkDisabled] = useState<{ [key: string]: boolean }>(
    {}
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/Notification`
      );
      setOriginalData(response.data);
      const data = flattenAndFilterData(response.data);
      setTextInputs(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      Swal.fire("Error", "Failed to load content data.", "error");
    }
    setLoader(false);
  };

  const flattenAndFilterData = (apiData: any) => {
    let flatData: any = [];
    apiData.data.forEach((item: any) => {
      item.content.data.forEach((content: any) => {
        ["notifications", "heading", "button"].forEach((section) => {
          content.notificationsPageData[section].forEach((entry: any) => {
            flatData.push({
              ...entry,
              section: section,
              language: item.language,
            });
          });
        });
      });
    });

    return flatData.filter((entry: any) => {
      return (
        entry.section === currentEditData?.section &&
        (entry.text === currentEditData?.text ||
          entry.id === currentEditData?.id)
      );
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setValue(`img-${language}`, file as unknown as string);
      const fileUrl = URL.createObjectURL(file);
      setImagePreviews((prev) => ({ ...prev, [language]: fileUrl }));

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          const uploadedImageUrl = uploadResponse?.data?.data?.fileurl;
          setImagePreviews((prev) => ({
            ...prev,
            [language]: uploadedImageUrl,
          }));
          setValue(`link-${language}`, uploadedImageUrl);
          setLinkDisabled((prev) => ({ ...prev, [language]: true }));
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    } else {
      setValue(`img-${language}`, undefined);
      setImagePreviews((prev) => ({ ...prev, [language]: null }));
      setLinkDisabled((prev) => ({ ...prev, [language]: false }));
    }
  };

  const handleFormSubmit = async (formData: FormValues) => {
    setLoader(true);
    const updatedData = textInputs.map((item) => ({
      ...item,
      text: formData[`text-${item.id}-${item.language}`] || item.text,
      link: formData[`link-${item.language}`] || item.link,
    }));

    const newData = originalData.data.map((langData: any) => {
      const updatedContentData = langData.content.data.map((content: any) => {
        const updatedNotificationsPageData: any = {};
        ["notifications", "heading", "button"].forEach((section) => {
          updatedNotificationsPageData[section] = content.notificationsPageData[
            section
          ].map((entry: any) => {
            const updatedEntry = updatedData.find(
              (upd: any) =>
                upd.id === entry.id && upd.language === langData.language
            );
            return updatedEntry || entry;
          });
        });
        return {
          notificationsPageData: updatedNotificationsPageData,
        };
      });
      return {
        ...langData,
        content: {
          data: updatedContentData,
        },
      };
    });

    const payload = {
      data: newData,
    };

    try {
      await axiosUAMInstance.post(`/websitecontent/update-many`, newData);
      Swal.fire("Success", "Content updated successfully", "success");
      onClose();
      if (apiCall) apiCall();
    } catch (error) {
      console.error("Failed to update data:", error);
      Swal.fire("Error", "Failed to update content data.", "error");
    }
    setLoader(false);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Website Content
          </h1>
          <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 md">
            <div
              className="custom-scrollbar p-4"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {textInputs.map((item, index) => (
                <div
                  key={`${item.id}-${item.language}-${index}`}
                  className="my-4"
                >
                  <label
                    htmlFor={`text-${item.id}-${item.language}`}
                    className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                  >
                    {`${
                      item.section.charAt(0).toUpperCase() +
                      item.section.slice(1)
                    } :(${item.language})`}
                  </label>
                  <InputFields
                    {...register(`text-${item.id}-${item.language}`)}
                    defaultValue={item.text}
                    className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Type here"
                  />
                  <div className="my-4">
                    <label
                      htmlFor={`link-${item.language}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({item.language}):
                    </label>
                    <InputFields
                      {...register(`link-${item.language}`)}
                      defaultValue={item.link}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Type here"
                      disabled={linkDisabled[item.language]}
                    />
                  </div>
                  <div className="my-4">
                    <label
                      htmlFor={`image-${item.language}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Document ({item.language}):
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, item.language)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {imagePreviews[item.language] && (
                      <img
                        src={imagePreviews[item.language]!}
                        alt="Preview"
                        className="mt-4 w-10"
                      />
                    )}
                  </div>
                </div>
              ))}
              <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
              <ButtonComp type="submit" onClose={onClose} loader={loader} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContentThree;
