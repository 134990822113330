import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import SubTabs from "../../components/cms/SubTabs";
import InputFields from "../../components/form/InputField";
import FileUploadOne from "../../components/common/FileUploadOne";
import Add from "../../assets/images/new_images/add-circle.png";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import DeleteIcon from "../../assets/images/delete.svg";
import AddStateModal from "../../components/cms/AddStateModal";
import EditModal from "../../components/cms/EditModal";
import UploadIcon from "../../assets/images/UploadIcon.png";
import AddDistrictModal from "../../components/cms/AddDistrictModal";
import { Link, useParams } from "react-router-dom";
import LoaderSpin from "../../components/LoaderSpin";
import formatDate from "../../utils/formatDate";
import { CmsMasterDistrictType } from "../../Types/CmsMasterDistrict";
import useCmsMasterDistrictStore from "../../state/useCmsMasterDistrictStore";
import CustomPaginationV2 from "../../components/cms/Pagination/CustomPaginationv2";
import { axiosUAMInstance } from "../../utils/axios";

// type CmsMasterDistrict = {
//   id: number;
//   name: string;
//   created_at: string;
//   updated_at: string;
//   is_active: boolean;
//   action: boolean;
// };

const columnHelper = createColumnHelper<CmsMasterDistrictType>();

const CmsMasterDistrict = () => {
  // const [showPopup, setShowPopup] = useState<boolean>(false);
  // const [openModel, setShowModel] = useState<boolean>(false);
  // const [loader, setLoader] = useState<boolean>(false);
  // const [data, setData] = useState<CmsMasterDistrict[]>();
  // const [page, setPage] = useState<number>(1);
  // const [pageSize, setPageSize] = useState<number>(10);
  // const [searchText, setSearchText] = useState<string>("");
  const { sid } = useParams();
  const {
    showPopup,
    setShowPopup,
    openModel,
    setShowModel,
    loader,
    setLoader,
    data,
    setData,
    page,
    setPage,
    pageSize,
    setPageSize,
    searchText,
    setSearchText,
    total,
    setTotal,
  } = useCmsMasterDistrictStore();

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>Sr. No.</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => {
        const district = info.row.original.name;
        const did = info.row.original.id;
        return (
          <Link to={`/cms/master/master/block/${did}`}>
            <span className="text-blue-500">{district}</span>
          </Link>
        );
      },
      header: () => <span>District</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => formatDate(info.row.original.created_at),
      header: () => <span>Added Date</span>,
    }),
    columnHelper.accessor("updated_at", {
      cell: (info) => formatDate(info.row.original.updated_at),
      header: () => <span>Modify Date</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();
        const isActive = info?.row?.original?.is_active;

        return (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-1 md:gap-2">
            <div>
              <ToggleSwitch enabled={isActive} />
            </div>
            <div>
              <ActionButton variant="edit" onClick={() => setShowModel(true)} />
            </div>
            <div className="md:-mt-1 lg:-md-1">
              <img src={DeleteIcon} alt="icon" />
            </div>
            {/* <div className="md:-mt-1 lg:-md-1">
              <Link to="/cms/master/block">
                <img src={Add} alt="add" />
              </Link>
            </div> */}
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];
  const getDistrict = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/location/district/${sid}`, {
        params: {
          page: page,
          pagesize: pageSize,
          searchtext: searchText,
        },
      })
      .then((response) => {
        console.log(response, "district");
        setData(response?.data?.data?.list);
        setTotal(response?.data?.data?.totalCount);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   getDistrict();
  // }, [page, pageSize, searchText]);
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    // Debounce function to delay the execution of getState
    const delaySearch = setTimeout(() => {
      getDistrict();
    }, 400);

    return () => clearTimeout(delaySearch); // Cleanup function to clear the timeout
  }, [page, pageSize, searchText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative "
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="my-6">
            <SubTabs />
          </div>
          <div className="">
            <div className=" flex  space-x-2  items-center flex-wrap">
              <div>
                <div className="mb-2">
                  <label
                    htmlFor="District Search"
                    className="text-base font-normal text-gilroy-medium "
                  >
                    District Search
                  </label>
                </div>

                <InputFields
                  height="40px"
                  width="500px"
                  padding="10px"
                  placeholder="Search by Unique ID/name"
                  value={searchText}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" flex items-center mt-5">
                <div className=" w-44">
                  <FileUploadOne filename="Upload" icon={UploadIcon} />
                </div>
                <div className="mt-2">
                  <button
                    className="w-40 text-[#1c468e] h-10 rounded-lg border border-[#1c468e] text-base text-gilroy-medium flex  items-center justify-center"
                    onClick={() => setShowPopup(true)}
                  >
                    <img src={Add} alt="Add" className="mr-2 " />
                    Add District
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="mb-10">
              {loader ? (
                <LoaderSpin />
              ) : data && data?.length > 0 ? (
                <ReactTable defaultData={data} columns={columns} />
              ) : (
                <div className="flex justify-center items-center mt-5">
                  <p className="text-xl text-gilroy-mediumItalic ">
                    {searchText.length > 0
                      ? "No Record Found"
                      : " No Data Available"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-10 absolute bottom-0">
            {data && data.length >= 0 && (
              <CustomPagination
                currentPage={page}
                setCurrentPage={setPage}
                totalItems={total}
                itemsPerPage={pageSize}
                maxPageNumbersToShow={5}
              />
            )}
          </div>

          {showPopup && (
            <AddDistrictModal onClose={() => setShowPopup(false)} />
          )}
          {openModel && (
            <EditModal
              heading="Edit"
              handleOpenClose={() => setShowModel(false)}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsMasterDistrict;
