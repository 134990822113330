import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CmsTabsItem from "../cms/CmsTabsItem";
import TaskTabsItem from "./TaskTabItems";

type Props = {};

const tabs = [
  { title: "Deposit Taker", url: "deposit", rurl: "/mytask/deposit" },
  { title: "Regulator", url: "regulator", rurl: "/mytask/regulator" },
  { title: "Competent Authority", url: "authority", rurl: "/mytask/authority" },
  {
    title: "Designated Court",
    url: "designatedcourt",
    rurl: "/mytask/designatedcourt",
  },
];

const TaskTabs = (props: Props) => {
  const [activeTab, setActiveTab] = useState<string>("Deposit Taker");
  const [url, setUrl] = useState<String>("");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const cmsPath = pathname.split("/")[2];
    setUrl(cmsPath);
  }, [pathname]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <div className="flex-col justify-center items-start inline-flex border-b w-full">
      <ul className="justify-start items-center gap-5 md:gap-10 inline-flex flex-wrap">
        {tabs.map((menuItem, index) => (
          <Link to={menuItem.rurl} key={index}>
            <TaskTabsItem
              key={index}
              text={menuItem.title}
              isActive={menuItem.url === url}
              onClick={() => handleTabClick(menuItem.title)}
            />
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default TaskTabs;
