import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import Swal from "sweetalert2";
import add from "../../assets/images/add.svg";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface FormValues {
  [key: string]: string;
}

interface Props {
  onClose: () => void;
  currentEditData?: any;
  apiCall?: () => void;
}

const EntityRegistrationOtpContentSix: React.FC<Props> = ({
  onClose,
  currentEditData,
  apiCall,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [textInputs, setTextInputs] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<{ data: any[] }>({
    data: [],
  });
  console.log({ textInputs });

  const [imagePreviews, setImagePreviews] = useState<{
    [key: string]: string | null;
  }>({});

  console.log({ currentEditData });

  const { register, handleSubmit, setValue } = useForm<FormValues>();

  useEffect(() => {
    fetchData();
  }, [currentEditData]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/Contact Us`
      );
      setOriginalData(response?.data);
      const flattenedData = flattenData(response.data.data, currentEditData);

      setTextInputs(flattenedData);
      flattenedData.forEach((input: any) => {
        setValue(input.name, input.text);
        setValue(`link-${input.language}`, input.link || "N/A");
      });

      // Counting languages in the filtered section
      const languages = new Set(flattenedData.map((item) => item.language));
      console.log(
        `Number of languages for the section ${currentEditData?.section}:`,
        languages.size
      );
    } catch (error) {
      console.error("Failed to fetch data:", error);
      Swal.fire("Error", "Failed to load content data.", "error");
    }
    setLoader(false);
  };
  const flattenData = (apiData: any, currentEditData: any) => {
    console.log({ apiData });
    const flatData: any[] = [];
    const uniqueKeys = new Set();
    const matchingIds = new Set();

    // First pass: identify all IDs across languages that match the currentEditData.id
    apiData.forEach((item: any) => {
      Object.entries(item.content.data).forEach(
        ([sectionKey, sectionValue]: [string, any]) => {
          if (!currentEditData || sectionKey === currentEditData.section) {
            sectionValue.forEach((element: any) => {
              if (element.id === currentEditData.id) {
                matchingIds.add(element.id);
              }
            });
          }
        }
      );
    });

    // Second pass: collect all data for these IDs in all languages
    apiData.forEach((item: any) => {
      Object.entries(item.content.data).forEach(
        ([sectionKey, sectionValue]: [string, any]) => {
          sectionValue.forEach((element: any) => {
            const uniqueKey = `${sectionKey}_${element.name}_${item.language}`;
            if (matchingIds.has(element.id) && !uniqueKeys.has(uniqueKey)) {
              uniqueKeys.add(uniqueKey);
              flatData.push({
                id: element.id,
                section: sectionKey,
                name: uniqueKey,
                text: element.text,
                link: element.link || "N/A",
                language: item.language,
              });
            }
          });
        }
      );
    });

    return flatData;
  };

  // const flattenData = (apiData: any, currentEditData: any) => {
  //   console.log({ apiData });
  //   const flatData: any[] = [];
  //   const uniqueKeys = new Set();

  //   apiData.forEach((item: any) => {
  //     Object.entries(item.content.data).forEach(
  //       ([sectionKey, sectionValue]: [string, any]) => {
  //         if (
  //           !currentEditData ||
  //           (sectionKey === currentEditData.section && currentEditData.id)
  //         ) {
  //           sectionValue.forEach((element: any) => {
  //             const uniqueKey = `${sectionKey}_${element.name}_${item.language}`;
  //             if (!uniqueKeys.has(uniqueKey)) {
  //               uniqueKeys.add(uniqueKey);
  //               flatData.push({
  //                 id: element.id,
  //                 section: sectionKey,
  //                 name: uniqueKey,
  //                 text: element.text,
  //                 link: element.link || "N/A",
  //                 language: item.language,
  //               });
  //             }
  //           });
  //         }
  //       }
  //     );
  //   });
  //   return flatData;
  // };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          const uploadedImageUrl = uploadResponse?.data?.data?.fileurl;
          setImagePreviews((prev) => ({
            ...prev,
            [language]: uploadedImageUrl,
          }));
          setValue(`link-${language}`, uploadedImageUrl);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    }
  };

  const handleFormSubmit = async (formData: FormValues) => {
    setLoader(true);

    // Map updated data from the form with unique identifiers
    const updatedData = textInputs.map((item) => ({
      id: item.id,
      section: item.section,
      name: item.name,
      language: item.language,
      text: formData[item.name],
      link: formData[`link-${item.language}`],
    }));
    console.log({ updatedData });

    // Map the new data with original data including unique identifiers
    const newData = originalData.data.map((langData) => {
      const newContentData = { ...langData };
      Object.keys(langData.content.data).forEach((section) => {
        newContentData.content.data[section] = langData.content.data[
          section
        ].map((item: any) => {
          const updatedItem = updatedData.find((updItem) => {
            // Identify the item by id, section, name, and language
            return (
              updItem.id === item.id &&
              updItem.section === section &&
              updItem.name === `${section}_${item.name}_${langData.language}`
            );
          });
          return updatedItem ? { ...item, ...updatedItem } : item;
        });
      });
      return newContentData;
    });
    console.log({ newData });

    try {
      await axiosUAMInstance.post(`/websitecontent/update-many`, newData);
      Swal.fire("Success", "Content updated successfully", "success");
      onClose();
      if (apiCall) {
        apiCall();
      }
    } catch (error) {
      console.error("Failed to update data:", error);
      Swal.fire("Error", "Failed to update content data.", "error");
    }

    setLoader(false);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white"
        >
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Edit Content
          </h1>
          <div
            className="custom-scrollbar p-4"
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {textInputs?.map((input: any, index: number) => (
              <div key={index} className="my-4">
                <label
                  htmlFor={input?.name}
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Content:({input?.language})
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register(input?.name)}
                  placeholder="type here"
                  defaultValue={input?.text}
                />
                {input?.section === "residentailProcessDetails" && (
                  <>
                    <label
                      htmlFor={`link-${input?.language}`}
                      className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                    >
                      Link ({input?.language}):
                    </label>
                    <InputFields
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      {...register(`link-${input?.language}`)}
                      defaultValue={input.link}
                      placeholder="No link available"
                      disabled
                    />
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, input?.language)}
                      className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <img
                      src={imagePreviews[input.language] || ""}
                      alt="Preview"
                      className="mt-4 w-10"
                    />
                  </>
                )}
              </div>
            ))}
            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <ButtonComp onClose={onClose} loader={loader} />
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EntityRegistrationOtpContentSix;
