import create from "zustand";
import { CmsMasterDistrictType } from "../Types/CmsMasterDistrict";

type CmsMasterDistrictState = {
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  openModel: boolean;
  setShowModel: (show: boolean) => void;
  loader: boolean;
  setLoader: (isLoading: boolean) => void;
  data: CmsMasterDistrictType[] | null;
  setData: (data: CmsMasterDistrictType[] | null) => void;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  total: number;
  setTotal: (total: number) => void;
};

const useCmsMasterDistrictStore = create<CmsMasterDistrictState>((set) => ({
  showPopup: false,
  setShowPopup: (show) => set({ showPopup: show }),
  openModel: false,
  setShowModel: (show) => set({ openModel: show }),
  loader: false,
  setLoader: (isLoading) => set({ loader: isLoading }),
  data: null,
  setData: (data) => set({ data: data }),
  page: 1,
  setPage: (page) => set({ page: page }),
  pageSize: 10,
  setPageSize: (pageSize) => set({ pageSize: pageSize }),
  searchText: "",
  setSearchText: (searchText) => set({ searchText: searchText }),
  total: 0,
  setTotal: (total) => set({ total: total }),
}));

export default useCmsMasterDistrictStore;
