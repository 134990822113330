import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/form/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputFields from "./InputField";
import { axiosTraceIdInstance } from "../../utils/axios";

import LoginPageIcon from "../../assets/images/Login-bud.svg";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import MobileIcon from "../../assets/images/MobileIcon.svg";
import Swal from "sweetalert2";

interface ForgetPasswordModelProps {
  closeForgetPasswordModel: () => void;
  closeForgetPasswordandshowLogin: () => void;
  closeForgetPasswordAndshowRegisterMail: () => void;
}

const ForgetPasswordModel: React.FC<ForgetPasswordModelProps> = ({
  closeForgetPasswordModel,
  closeForgetPasswordandshowLogin,
  closeForgetPasswordAndshowRegisterMail,
}) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });

  const handleFormSubmit = async (data: any) => {
    setLoader(true);
    try {
      const response = await axiosTraceIdInstance.post(
        `/admin/user/forgotpassword`,
        {
          username: data.email,
        }
      );
      setLoader(false);
      if (response.data.success) {
        // closeForgetPasswordAndshowRegisterMail();
        if (closeForgetPasswordAndshowRegisterMail) {
          closeForgetPasswordAndshowRegisterMail();
        }
      } else {
        // setErrorMessage(response.data.message || "Failed to update");
        Swal.fire({
          icon: "error",
          text: response.data.message || "An error occurred. Please try again",
        });
      }
    } catch (error: any) {
      setLoader(false);
      // setErrorMessage(
      //   error?.response.data.message || "An unexpected error occurred"
      // );
      Swal.fire({
        icon: "error",
        text:
          error?.response?.data?.message ||
          "An error occurred. Please try again",
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(""); // Clear error message when user starts typing
    setValue("email", event.target.value); // Update form value
  };

  const handleClose = () => {
    closeForgetPasswordModel();
  };

  const handleNavigateLogin = () => {
    closeForgetPasswordandshowLogin();
  };
  const emailValue = watch("email");

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <div className="bg-black bg-opacity-30 absolute inset-0 flex justify-center items-center shadow-lg">
          <div className="bg-white p-3 rounded-lg md:w-[946px] w-full grid grid-cols-1 md:grid-cols-2 gap-4 shadow-lg m-4">
            <div className="order-1 md:order-2 mt-3">
              {/* <div className="flex justify-end mt-[4px]">
                <img
                  src={CrossIcon}
                  alt="Close"
                  className="cursor-pointer"
                  onClick={handleClose}
                />
              </div> */}
              <div className="w-full text-center">
                <h1 className="text-[24px] font-bold">Forgot password?</h1>
                <p className="text-[#646464]">
                  No worries, we will send you reset instructions.
                </p>
              </div>
              {/* {errorMessage && (
                <p className="text-red-500 text-center">{errorMessage}</p>
              )} */}

              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="mt-5 md:mt-[60px] px-4 md:px-[40px]">
                  <label htmlFor="email" className="text-base font-normal my-3">
                    Email ID / Mobile No.
                  </label>
                  <InputFields
                    {...register("email")}
                    placeholder="Enter your email or mobile number"
                    onChange={handleChange}
                  />
                  <div className="flex justify-center items-center mt-14">
                    <Button
                      type="submit"
                      loader={loader}
                      label={!loader ? "Submit" : "Processing..."}
                      disabled={!emailValue || loader}
                    />
                  </div>
                  <div className="mt-14 flex justify-center items-center">
                    <p
                      className="text-[#1C468E] cursor-pointer"
                      onClick={handleNavigateLogin}
                    >
                      Back to Login
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="md:order-1 hidden md:flex justify-center items-center">
              <img src={LoginPageIcon} alt="Login Page" />
            </div>
            <div className="md:order-1 flex justify-center items-center md:hidden">
              <img src={MobileIcon} alt="Mobile Icon" />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ForgetPasswordModel;
