import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import InputFields from "../../components/common/InputField";
import ReactTable from "../../components/common/ReactTable";
import addCircle from "../../assets/images/new_images/add-circle.png";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import DeleteButton from "../../assets/images/delete.svg";
import UploadButton from "../../components/common/UploadButton";
import UploadIcon from "../../assets/images/new_images/UploadIcon.png";
import EntityRegistrationOtpContent from "../../components/cms/EntityRegistrationOtpContent";
import LoaderSpin from "../../components/LoaderSpin";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InputFieldSearch from "../../components/common/InputSearch";
import { axiosUAMInstance } from "../../utils/axios";

type TableType = {
  id: any;
  name: any;
  created_at: any;
  updated_at: any;
};

const columnHelper = createColumnHelper<TableType>();

const CmsWebsiteContent = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  const [dataList, setDataList] = useState([]);
  console.log({ dataList });
  const [modalData, setModalData] = useState(null);

  const navigate = useNavigate();

  const handleApiCall = useCallback(() => {
    setLoader(true);
    axiosUAMInstance
      .get(`/websitecontent/list`, {
        params: { page, pageSize, searchtext: searchText },
      })
      .then((response: any) => {
        setDataList(response?.data?.data?.list);
        setTotal(response?.data?.data?.totalCount);
        setLoader(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoader(false);
      });
  }, [page, pageSize, searchText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      handleApiCall();
    }, 400);

    return () => clearTimeout(delayDebounce);
  }, [handleApiCall]);

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },

      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.renderValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => moment(info.renderValue()).format("DD/MM/YYYY"),
      header: () => <span>Added Date</span>,
    }),
    columnHelper.accessor("updated_at", {
      cell: (info) => moment(info.renderValue()).format("DD/MM/YYYY"),
      header: () => <span>Modify Date</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        // const value = info.getValue();
        const { id, name } = info.getValue();

        return (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-1 lg:gap-6">
            {/* <div>
              <ActionButton
                variant="eye"
                onClick={() => setModalOpenClose(true)}
              />
            </div> */}
            <div>{/* <img src={DeleteButton} alt="icon" /> */}</div>
            <div>
              <ActionButton variant="edit" onClick={() => editPage(id, name)} />
            </div>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const editPage = (id: number, name: string) => {
    if (name === "Home" || id === 1) {
      navigate(`/cms/websitecontent/internal/${id}`, {
        state: {
          id: id,
          name: name,
        },
      });

      // setModalOpenClose(true);
    } else if (name === "FAQ") {
      navigate(`/cms/websitecontent/internal/${2}`, {
        state: {
          id: id,
          name: name,
        },
      });
    } else if (name === "Notification") {
      navigate(`/cms/websitecontent/internal/${3}`, {
        state: {
          id: id,
          name: name,
        },
      });

      // setModalData(data);
    } else if (name === "Operating Guidelines") {
      navigate(`/cms/websitecontent/internal/${4}`, {
        state: {
          id: id,
          name: name,
        },
      });

      // setModalData(data);
    } else if (name === "Downloads") {
      navigate(`/cms/websitecontent/internal/${5}`, {
        state: {
          id: id,
          name: name,
        },
      });

      // setModalData(data);
    } else if (name === "Contact Us") {
      navigate(`/cms/websitecontent/internal/${6}`, {
        state: {
          id: id,
          name: name,
        },
      });

      // setModalData(data);
    }
  };

  const [modalOpenClose, setModalOpenClose] = useState<boolean>(false);
  const handleModal = () => {
    setModalOpenClose(false);
  };
  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          {/* <div className="mt-[24px] ">
            <h1 className="mb-[8px] text-black text-sm font-bold text-gilroy-medium">
              Content Search
            </h1>
            <div className=" flex flex-col lg:flex-row flex-wrap lg:items-center gap-[8px] w-full md:w-auto ">
              <InputFields
                placeholder="Search by Unique ID/name"
                className="w-full lg:w-[621px] pl-[15px] h-[40px] border border-grey-300 rounded-[8px] focus:outline-none"
              />
              <div className="flex flex-row items-center flex-wrap gap-1">
                <div className="w-[181px] mr-1">
                  <UploadButton filename="Upload" imageSrc={UploadIcon} />
                </div>
                <div className="w-40 h-[40px] border-[2px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] border-[#1c468e] flex justify-center items-center">
                  <img src={addCircle} alt="icon" />
                  <span className="ml-1 text-[14px] md:text-base font-normal text-[#1c468e] lg:text-[16px] text-gilroy-medium ">
                    Add content
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          <div className=" flex justify-between items-center flex-wrap">
            <div>
              <div className="mb-2">
                <label
                  htmlFor="Country Search"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Content Search
                </label>
              </div>
              <div className="md:w-[480px] xl:w-[700px] 2xl:w-[750px] w-[300px]">
                <InputFieldSearch
                  height="40px"
                  // width="600px"
                  padding="10px"
                  placeholder="Search by Unique ID/name"
                  value={searchText}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex items-center  mt-8">
              <div className=" w-40 mr-7">
                <UploadButton filename="Upload" imageSrc={UploadIcon} />
              </div>
              <div className="h-[40px] border-[2px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] border-[#1c468e] flex justify-center items-center">
                <img src={addCircle} alt="icon" />
                <span className="ml-1 text-[14px] md:text-base font-normal text-[#1c468e] lg:text-[16px] text-gilroy-medium ">
                  Add content
                </span>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-20">
            {loader ? (
              <LoaderSpin />
            ) : dataList.length > 0 ? (
              <ReactTable
                defaultData={dataList}
                columns={columns}
                borderB={false}
              />
            ) : (
              <div className=" flex justify-center items-center">
                <h1>No data available</h1>
              </div>
            )}
          </div>

          <div className="mt-10 absolute bottom-0">
            {dataList && dataList?.length > 0 && (
              <CustomPagination
                bottomLine={true}
                currentPage={page}
                setCurrentPage={setPage}
                totalItems={total}
                itemsPerPage={pageSize}
                maxPageNumbersToShow={5}
              />
            )}
          </div>

          {modalOpenClose && (
            <EntityRegistrationOtpContent
              onClose={handleModal}
              data={modalData}
            />
          )}
        </div>
      }
    />
  );
};
export default CmsWebsiteContent;
