import React from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import Tree from "../../components/cms/Tree";

const CmsRegisterTree = () => {
  return (
    <Layout
      layout={
        <div>
          <div className="mt-4 ml-4 xl:ml-[40px]">
            <CmsTabs />
          </div>
          <div className="mt-4">
            <Tree />
          </div>
        </div>
      }
    />
  );
};

export default CmsRegisterTree;
