import InputFields from "../../components/common/InputField";
import { useForm } from "react-hook-form";
import SelectButtonUserManagement from "./SelectButtonUserManagement";
import Button from "../common/Button";
import ArrowIcon from "../../assets/images/BackArrow.svg";
import InfoCircle from "../../assets/images/info-circle (1).svg";
import InputWithCountryCode from "../common/InputWithCountryCode";
import { useEffect, useState } from "react";
import { axiosUAMInstance } from "../../utils/axios";
import InputFieldPassword from "../form/InputFieldPassword";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DscButton from "../form/Dscbutton";
import { convertFileToBase64 } from "../../utils/fileConversion";
import { useScreenWidth } from "../../utils/ScreenSize";

const UserManagement = () => {
  const [optionData, setOptionData] = useState([]);
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  // console.log(selectedFunc, "selectFun");
  const navigate = useNavigate();
  const [isRoleSelected, setIsRoleSelected] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isUniqueIDTouched, setIsUniqueIDTouched] = useState<boolean>(false);
  const [isEmptyPhonNumber, setEmptyPhonNumber] = useState<boolean>(false);
  const [isValidPhnNum, setValidPhmNum] = useState<boolean>(false);
  const [isValidPhnNumPattern, setValidPhmNumPattern] =
    useState<boolean>(false);

  const [selectedFunId, setSelectFunId] = useState<number | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [base64Data, setBase64Data] = useState<string>("");
  const [hexData, setHexData] = useState("");
  const [fileName, setFileName] = useState<string | undefined>("");
  const screenWidth = useScreenWidth();

  const handleSetFunc = (data: any) => {
    // console.log(data, "datain form");
    setSelectedFunc(data.label);
    setSelectFunId(data.value);
    // setIsRoleSelected(true);
  };

  useEffect(() => {
    if (selectedFunId) {
      setIsRoleSelected(false);
    }
  }, [selectedFunId]);

  const [phone, setPhone] = useState<string | null>("");
  // console.log(phone, "phone");

  const validatePhoneNumber = (value: string) => {
    const phonePattern = /^[6-9]\d{9}$/;
    const phnPat = phonePattern.test(value) || false;
    return phnPat;
  };

  const handleOnChange = (value: string) => {
    setPhone(value);

    if (validatePhoneNumber(value)) {
      setValidPhmNumPattern(false);
    } else {
      setValidPhmNumPattern(true);
      return;
    }
    if (value === "") {
      setEmptyPhonNumber(true);
    } else {
      setEmptyPhonNumber(false);
    }
    setIsUniqueIDTouched(true);
    if (value.length === 10 || value === "") {
      setValidPhmNum(false);
    } else {
      setValidPhmNum(true);
    }
  };

  const getAdminRoleList = () => {
    axiosUAMInstance
      .get(`/admin/role/list`, {
        params: {
          page: 1,
          pageSize: 100,
        },
      })
      .then((response) => {
        // console.log(response?.data.roles, "admin/role/list");
        setOptionData(
          response?.data?.roles?.map((f: any) => ({
            value: f.id,
            label: f.compositeRoleName,
          }))
        );
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAdminRoleList();
  }, []);

  const countryCodes = [{ code: "+91", country: "India" }];
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setIsFormSubmitted(true);

    // if (!isRoleSelected) {
    //   return; // Do nothing if role is not selected
    // }
    // if (!isUniqueIDTouched) {
    //   return; // Do nothing if
    // }

    const { firstName, LastName, MiddleName, email } = data;
    setLoader(true);

    axiosUAMInstance
      .post(`/admin/user/add`, {
        first_name: firstName,
        middle_name: MiddleName,
        last_name: LastName,
        role_id: selectedFunId,
        mobile: phone,
        email_id: email,
        dscCertificateFile: base64Data,
      })
      .then((response) => {
        setLoader(false);
        console.log(response.status);

        if (response.status === 200) {
          Swal.fire({
            title: `User creation created successfully`,
            icon: "success",
          });
          navigate("/usermanagement/user");
          reset();
          setSelectedFunc(null);
          setPhone("");
        }
      })
      .catch((error) => {
        // console.log(error?.response?.data?.message, "-----error");
        setLoader(false);
        Swal.fire({
          title:
            error?.response?.data?.error?.errorMessage ||
            error?.response?.data?.message ||
            "please try again",
          icon: "error",
        });
      });
  };

  const handleFileUpload = (file: File | null) => {
    setFileName(file?.name);
    if (file) {
      setIsFileUploaded(true);

      convertFileToBase64(
        file,
        (hex) => {
          setHexData(hex);
        },
        (base64) => {
          setBase64Data(base64);
        }
      );
    } else {
      setIsFileUploaded(false);
      setBase64Data(""); // Clear the base64 data if no file is uploaded
      setHexData(""); // Clear the hex data as well
    }
  };

  const handleSubmit2 = () => {
    if (phone === "") {
      setEmptyPhonNumber(true);
    }
    if (selectedFunId === null) {
      setIsRoleSelected(true);
    }
  };

  return (
    <div className="mt-6 flex flex-col h-full justify-between  ">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-3 gap-4"
      >
        <div>
          <label
            htmlFor="firstName"
            className="text-base font-normal text-gilroy-medium"
          >
            First name <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <InputFields
              {...register("firstName", {
                required: "First name is required",
                minLength: {
                  value: 3,
                  message: "First name must be at least 3 characters",
                },
                maxLength: {
                  value: 20,
                  message: "First name must not exceed 20 characters",
                },
                pattern: {
                  value:
                    /^(?!^\d+$)(?!^[!@#$%^&*()_+={}[\]:;"'<>,.?/~`|\\-]+$).+$/,
                  message:
                    "First name must contain at least one alphabetic character and cannot be only numbers or special characters.",
                },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.firstName?.message &&
              typeof errors.firstName.message === "string" && (
                <p className="text-red-500">{errors.firstName.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="MiddleName"
            className="text-base font-normal text-gilroy-medium"
          >
            Middle name
          </label>
          <div className="mt-2">
            <InputFields
              {...register("MiddleName", {
                // required: "middle is required",
                // minLength: {
                //   value: 3,
                //   message: "middle name must be at least 3 characters",
                // },
                pattern: {
                  value:
                    /^(?!^\d+$)(?!^[!@#$%^&*()_+={}[\]:;"'<>,.?/~`|\\-]+$).+$/,
                  message:
                    "First name must contain at least one alphabetic character and cannot be only numbers or special characters.",
                },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.MiddleName?.message &&
              typeof errors.MiddleName.message === "string" && (
                <p className="text-red-500">{errors.MiddleName.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="LastName"
            className="text-base font-normal text-gilroy-medium"
          >
            Last name <span className="text-red-500"> * </span>
          </label>
          <div className="mt-2">
            <InputFields
              {...register("LastName", {
                required: "LastName  is required",
                minLength: {
                  value: 3,
                  message: "Last name must be at least 3 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Last name must not exceed 20 characters",
                },
                pattern: {
                  value:
                    /^(?!^\d+$)(?!^[!@#$%^&*()_+={}[\]:;"'<>,.?/~`|\\-]+$).+$/,
                  message:
                    "First name must contain at least one alphabetic character and cannot be only numbers or special characters.",
                },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.LastName?.message &&
              typeof errors.LastName.message === "string" && (
                <p className="text-red-500">{errors.LastName.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="role"
            className="text-base font-normal text-gilroy-medium"
          >
            Role <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <SelectButtonUserManagement
              setOption={handleSetFunc}
              options={optionData || []}
              selectedOption={selectedFunc}
              placeholder="Type input"
              mdWidth="md:w-[350px]"
            />
            {isRoleSelected && (
              <p className="text-red-500">Please select a role.</p>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="text-base font-normal text-gilroy-medium"
          >
            Email ID <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <InputFields
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.email?.message &&
              typeof errors.email.message === "string" && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="uniqueID"
            className="text-base font-normal text-gilroy-medium"
          >
            <div className="flex">
              Mobile no <span className="text-red-500">*</span>
              <img src={InfoCircle} alt=" InfoCircle " className="w-5 mx-2" />
            </div>
          </label>
          <div className="mt-2">
            <InputWithCountryCode
              initialCountryCode="+91"
              initialPhoneNumber=""
              countryCodes={countryCodes}
              onCountryCodeChange={(code) =>
                console.log("Selected country code:", code)
              }
              // onPhoneNumberChange={(phoneNumber) =>
              //   console.log("Phone number changed:", phoneNumber)
              // }
              onPhoneNumberChange={(phoneNumber) => handleOnChange(phoneNumber)}
            />
            {isEmptyPhonNumber && (
              <p className="text-red-500">please enter your phone number</p>
            )}
            {isValidPhnNum && (
              <p className="text-red-500">
                Phone number should be in 10 digits
              </p>
            )}
            {isValidPhnNumPattern && (
              <p className="text-red-500">
                Mobile No. must be a valid phone number
              </p>
            )}
          </div>
        </div>
        {/* <div>
          <label
            htmlFor="password"
            className="text-base font-normal text-gilroy-medium"
          >
            Password <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <InputFieldPassword
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              placeholder="Type"
              width="350px"
            />
            {errors.password?.message &&
              typeof errors.password.message === "string" && (
                <p className="text-red-500">{errors.password.message}</p>
              )}
          </div>
        </div> */}
        {/* <div>
          <DscButton
            onFileUpload={handleFileUpload}
            disabled={false}
            fileName={fileName}
          />
        </div> */}
        <div className="col-span-1 md:col-span-3 flex justify-between items-center  p-2  mt-40">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <img src={ArrowIcon} alt="ArrowIcon" className="w-6 h-6 mr-2" />
            <h1 className="text-sm font-normal text-black">Back</h1>
          </div>
          <div className="flex-grow"></div>{" "}
          <div className="w-24 " onClick={handleSubmit2}>
            <Button
              loader={loader}
              type="submit"
              label="Submit"
              className=" h-10"
              variant="basic"
            />
          </div>
        </div>
      </form>
      <div className="">
        <div className="border-[#E6E6E6] border-[1px] w-full"></div>
        <div className="text-gilroy-light text-[#24222B] text-xs cursor-pointer h-10 flex items justify-center items-center">
          <div>© 2024 Protean BUDs, All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
