import React from "react";
import InputFields from "./InputField";
import add from "../../assets/images/add.svg";
import FileUploadOne from "../common/FileUploadOne";
import ButtonComp from "./ButtonComp";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import UploadIcon from "../../assets/images/Upload-Icon.svg";
import FileUploadTwo from "../common/FileUploadTwo";
import SelectButtonUserManagement from "../UserManagement/SelectButtonUserManagement";
import { useState } from "react";
// import axios from "axios";
import { axiosUAMInstance } from "../../utils/axios";
import { useEffect } from "react";
import { axiosTraceIdInstance } from "../../utils/axios";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import Swal from "sweetalert2";

interface UploadDocumentModalProps {
  onClose: () => void;
  onSave: () => void;
  setDocumentAdded: (added: boolean) => void;
  isDocumentAdded: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  onClose,
  onSave,
  setDocumentAdded,
  isDocumentAdded,
}) => {
  const [optionData, setOptionData] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | undefined>("");
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  const [selectedRegId, setSelectRegId] = useState<number | null>(null);
  const [isEmptyDocument, setEmptyDocument] = useState<boolean>(false);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [isFileSelected, setFileSelected] = useState<boolean>(false);
  const [isAllFieldsTrue, setAllFieldsTrue] = useState<boolean>(false);

  const userId = sessionStorage.getItem("userId");

  // console.log(optionData, "option dataaaaa");

  useEffect(() => {
    setSelected(false);
  }, [selectedFunc]);

  useEffect(() => {
    setFileSelected(false);
  }, [fileId]);

  useEffect(() => {
    if (documentName && selectedRegId && fileId) {
      setAllFieldsTrue(true);
    }
  }, [documentName, selectedRegId, fileId]);

  const apiCall = () => {
    // setLoader(true);
    axiosTraceIdInstance
      // .get(`/master-entities/RG?page=${page}&pagesize=10`)
      .get("/master-entities/RG", {
        params: {
          page: 1,
          pageSize: 10,
        },
      })
      .then((response: any) => {
        if (response.data.success) {
          setOptionData(
            response?.data?.data?.data?.map((f: any) => ({
              value: f.masterEntityId,
              label: f.name,
            }))
          );
        }

        // setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        // setLoader(false);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Add logic to handle form submission
    if (!documentName) {
      setEmptyDocument(true);
    }
    if (!selectedRegId) {
      setSelected(true);
    }
    if (!fileId) {
      setFileSelected(true);
    }

    if (!selectedRegId || !documentName || !fileId) {
      return;
    }
    try {
      setLoader(true);
      const response = await axiosUAMInstance.post(`/approval-documents`, {
        name: documentName,
        attachment: fileId,
        userId: parseInt(userId || ""),
        regulatorUniqueId: selectedRegId,
      });
      setLoader(false);
      setDocumentAdded(!isDocumentAdded);
      onClose();
      onSave();
    } catch (error) {
      setLoader(false);
      onClose();
      Swal.fire({
        icon: "error",
        title: "Internal Server Error",
        text: "Unable to add document",
      });
    }
  };

  const handleCloseModal = () => {
    // Call onClose function passed from parent component to close the modal
    onClose();
  };

  const onChangeDocumentName = (e: any) => {
    setDocumentName(e.target.value);
    if (e.target.value) {
      setEmptyDocument(false);
    } else {
      setEmptyDocument(true);
    }
  };

  const handleSetFunc = (data: any) => {
    // console.log(data, "datain form");
    setSelectedFunc(data.label);
    setSelectRegId(data.value);
    // setIsRoleSelected(true);
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}
      >
        <Box sx={style}>
          <div className="md:flex">
            <div className="m-[16px] md:m-16 w-[400px] md:w-[600px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
              <div
                className="flex flex-row justify-end mb-[12px] cursor-pointer"
                onClick={handleCloseModal}
              >
                <img src={add} className="w-6 h-6" alt="icon" />
              </div>
              <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
                Upload Document
              </h1>

              <form onSubmit={handleFormSubmit}>
                <div className="md:px-[32px] px-[16px]">
                  <label
                    htmlFor="documentName"
                    className=" text-black text-[16px] font-normal   text-gilroy-medium"
                  >
                    Document Name:
                  </label>
                  <div className="relative">
                    <InputFields
                      id="documentName"
                      className=" mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] mb-[24px] border  border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                      placeholder="Type here"
                      onChange={onChangeDocumentName}
                    />
                    {isEmptyDocument && (
                      <p className="text-red-500 absolute top-[70px]">
                        Please enter document name
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="inputButton"
                      className=" text-black text-[16px] font-normal text-gilroy-medium"
                    >
                      Upload Document
                    </label>
                    <div className="relative">
                      <FileUploadTwo setFileId={setFileId} icon={UploadIcon} />
                      {isFileSelected && (
                        <p className="text-red-500 absolute top-[55px]">
                          Please select a file.
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="inputButton"
                        className=" text-black text-[16px] font-normal text-gilroy-medium"
                      >
                        Select Regulator
                      </label>
                      <div className="relative">
                        <SelectButtonUserManagement
                          setOption={handleSetFunc}
                          options={optionData}
                          selectedOption={selectedFunc}
                          placeholder="Select"
                          mdWidth="md:w-[350px]"
                          isSelectRegulator={true}
                        />
                        {isSelected && (
                          <p className="text-red-500 absolute top-[55px]">
                            Please select regulator.
                          </p>
                        )}
                      </div>
                    </div>

                    <hr className="w-full bg-[#E6E6E6] mt-[40px] mb-[24px]"></hr>
                    <ButtonComp
                      onClose={onClose}
                      loader={loader}
                      isAllFields={isAllFieldsTrue}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UploadDocumentModal;
