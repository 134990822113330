interface Props {
  heading: string;
  buttonLabel?: string;
  buttonLabel2 ?: string;
  button2OnClick ?: any;
  onClick?: (event: any) => void;
  Data?: [];
}

const RegSignUpHead = (props: Props) => {
  return (
    <div className="rounded-t-lg bg-[#E7F0FF] flex flex-row justify-between items-center h-[65px] ">
      <p className="text-[#24222B] md:text-xl  ml-[16px] text-[14px] lg:text-[20px] py-2 text-wrap text-gilroy-bold font-bold">
        {props.heading}
      </p>
      <div>
      {props.buttonLabel2 && (
        <button
          onClick={props.button2OnClick}
          className="text-[#1c468e] mr-2 text-[14px] md:text-xl font-bold text-gilroy-bold"
        >
          {props.buttonLabel2} /
        </button>
      )}
      {props.buttonLabel && (
        <button
          disabled={props.Data && props.Data?.length === 0 ? true : false}
          onClick={props.onClick}
          className="text-[#1c468e] mr-[24px] text-[14px] md:text-xl font-bold text-gilroy-bold"
        >
          {props.buttonLabel}
        </button>
      )}
      </div>
    </div>
  );
};

export default RegSignUpHead;
