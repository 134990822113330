import React, { useState } from "react";

interface CountryCode {
  code: string;
  country: string;
}

interface InputWithCountryCodeProps {
  initialCountryCode?: string;
  initialPhoneNumber?: string;
  countryCodes: CountryCode[];
  onCountryCodeChange?: (code: string) => void;
  onPhoneNumberChange?: (phoneNumber: string) => void;
}

const InputWithCountryCode: React.FC<InputWithCountryCodeProps> = ({
  initialCountryCode = "+1",
  initialPhoneNumber = "",
  countryCodes,
  onCountryCodeChange,
  onPhoneNumberChange,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] =
    useState(initialCountryCode);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const code = e.target.value;
    setSelectedCountryCode(code);
    if (onCountryCodeChange) {
      onCountryCodeChange(code);
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhoneNumber(number);
    if (onPhoneNumberChange) {
      onPhoneNumberChange(number);
    }
  };

  return (
    <div className="flex items-center">
      {/* <select
        value={selectedCountryCode}
        onChange={handleCountryCodeChange}
        className="px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-gray-300 focus:border-gray-300 h-14"
      >
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
            {country.code}
          </option>
        ))}
      </select> */}
      <div className="h-14 border border-gray-300 flex items-center justify-centers px-5 py-3 text-[16px] font-[400] rounded-tl-md rounded-bl-md ">
        +91
      </div>

      <input
        type="tel" // Set input type to 'tel' to ensure numeric keyboard on mobile
        pattern="[0-9]*" // Use pattern attribute to enforce numeric input (optional)
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        className="form-input border flex border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle"
        style={{
          height: "56px",
          width: "100%",
          padding: "8px 16px",
          borderLeft: "none",
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
        }}
        placeholder="Type input"
      />
    </div>
  );
};

export default InputWithCountryCode;
