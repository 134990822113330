import React from "react";

interface SignupItem {
  item: string;
  onClick?: () => void;
}

const SignUpFormItems: React.FC<SignupItem> = ({ item, onClick }) => {
  return (
    <div className="p-2 bg-[#E7F0FF] rounded-lg justify-center items-center gap-2 flex hover:bg-[#1c468e] text-[#1D1D1B] hover:cursor-pointer hover:text-white">
      <div
        className="text-nowrap text-center  text-xs text-gilroy-medium "
        onClick={onClick}
      >
        {item}
      </div>
    </div>
  );
};

export default SignUpFormItems;
