import * as Yup from 'yup';

const AddFormFieldValidationModalschema = Yup.object().shape({
  fieldName: Yup.string()
    .required('Field name is required'),
  placeholder: Yup.string()
    .required('Placeholder is required'),
  sectionId: Yup.number()
    .required('Section ID is required'),
  typeId: Yup.number()
    .required('Type ID is required')
});

export default AddFormFieldValidationModalschema;
