import * as Yup from 'yup';


const AuthorityValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name of Designated Court is required")
    .matches(/^[A-Za-z ]+$/, "Only alphabetic characters are allowed"),
  address1: Yup.string().trim().required("Address Line 1 is required"),
  address2: Yup.string().trim().required("Address Line 2 is required"),
  pincode: Yup.string()
    .required("PIN Code is required")
    .matches(/^[0-9]{6}$/, "Invalid Pincode"),
  stateId: Yup.string().trim().required("State is required"),
  // .min(0, 'State is required'),
  districtId: Yup.string().trim().required("District is required"),
  // .min(0, 'District is required'),
  jurisdictionId: Yup.number().required("Jurdiction is required"),

});

export default AuthorityValidationSchema;
