import create from "zustand";

export interface WebContentData {
  id: number;
  name: string;
  text: string;
  img: string | null;
  link: string | null;
  section: string;
}

interface WebContentState {
  webcontentData: WebContentData[];
  loader: boolean;
  isModalOpen: boolean;
  currentEditData: WebContentData | null;
  isOpenCarouselOpen: boolean;
  faqData : any
  refresh : boolean
  downloadsData : any

}

interface WebContentActions {
  setWebContentData: (data: WebContentData[]) => void;
  setLoader: (state: boolean) => void;
  setIsModalOpen: (state: boolean) => void;
  setIsOpenCarouselOpen: (state: boolean) => void;
  setCurrentEditData: (data: WebContentData | null) => void;
  setFaqData: (data: any) => void;
  setDownloadsData: (data: any) => void;
  updateRefresh: () => void;
  resetModal: () => void;
}

const useWebContentStore = create<WebContentState & WebContentActions>(
  (set) => ({
    webcontentData: [],
    loader: false,
    isModalOpen: false,
    isOpenCarouselOpen: false,
    currentEditData: null,
    faqData : null,
    refresh : false,
    downloadsData : null,

    setWebContentData: (data) => set({ webcontentData: data }),
    setLoader: (state) => set({ loader: state }),
    setIsModalOpen: (state) => set({ isModalOpen: state }),
    setIsOpenCarouselOpen: (state) => set({ isOpenCarouselOpen: state }),
    setCurrentEditData: (data) => set({ currentEditData: data }),
    setFaqData : (data) => set({ faqData: data }),
    setDownloadsData : (data) => set({ downloadsData: data }),
    updateRefresh : () => set((state) => ({ refresh: !state.refresh })),
    resetModal: () => {
      set({
        isModalOpen: false,
        currentEditData: null,
        isOpenCarouselOpen: false,
      });
    },
  })
);

export default useWebContentStore;
