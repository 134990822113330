// import React, {
//   FC,
//   InputHTMLAttributes,
//   forwardRef,
//   useRef,
//   useState,
// } from "react";
// import Calender from "../../../src/assets/images/calender.svg";
// import { dateFormattor } from "../../utils/helperFunctions";

// interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
//   variant?: "search" | "basic" | "rawInput" | "fileSize";
//   width?: string; // New width prop
//   bgcolor?: string; // New bgcolor prop
// }

// const InputFields: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
//   (props, ref) => {
//     const { onChange, type, width = "250px", bgcolor = "white" } = props; // Destructuring width and bgcolor props with default values
//     const hiddenDateInput = useRef<HTMLInputElement>(null);
//     const [dateSelected, setDateSelected] = useState<any>(undefined);
//     const handleDateButtonClick = (event: any) => {
//       const element: any = document.getElementById("datePicker");
//       if (element && "showPicker" in element) {
//         element?.showPicker();
//       }
//       if (hiddenDateInput.current) {
//         hiddenDateInput.current.showPicker();
//       }
//       event.preventDefault();
//     };

//     const onChangeHandler = (event: any) => {
//       if (onChange) {
//         onChange(event); // Directly call the passed-in onChange function with the event
//       }
//       const { value } = event.target;
//       if (value) {
//         setDateSelected(dateFormattor(new Date(value)));
//       }
//     };

//     const variantClasses = {
//       search: `w-full h-[10px] py-5 form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
//       basic: `w-full form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
//       rawInput: `w-full form-input border h-[25px] px-[8px] py-[3px] flex border-gray-400 text-gray-600 rounded-sm focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
//       fileSize: `w-full h-[10px] py-5 form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
//     };
//     return (
//       <>
//         {type === "date" ? (
//           <div className="flex justify-start items-center w-[250px] border rounded-xl">
//             <button
//               onClick={handleDateButtonClick}
//               className="flex justify-between h-[56px] w-full px-[8px] py-[16px] rounded-lg text-gray-600 bg-white hover:bg-gray-100 focus:outline-none"
//             >
//               {dateSelected ? dateSelected : "Select Date"}
//               <div>
//                 <img src={Calender} alt="" />
//               </div>
//             </button>
//             <input
//               ref={ref}
//               type="date"
//               id="datePicker"
//               className="absolute -z-10"
//               onChange={onChangeHandler}
//             />
//           </div>
//         ) : (
//           <div className="relative text-sm">
//             <input
//               type="text"
//               style={{ width, backgroundColor: bgcolor }}
//               className={variantClasses[props.variant ?? "basic"]}
//               {...props}
//               ref={ref}
//             />
//             {props.variant === "fileSize" && (
//               <span className="absolute text-gray-400 right-1 top-3 py-1 pl-2 bg-white">
//                 in KB
//               </span>
//             )}
//           </div>
//         )}
//       </>
//     );
//   }
// );

// export default InputFields;
import {
  FC,
  InputHTMLAttributes,
  forwardRef,
  useRef,
  useState,
} from "react";
import Calender from "../../../src/assets/images/calender.svg";
import { dateFormattor } from "../../utils/helperFunctions";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: "search" | "basic" | "rawInput" | "fileSize";
  initialWidth?: string; // New initialWidth prop
  bgcolor?: string; // New bgcolor prop
}

const InputFields: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      onChange,
      type,
      initialWidth = "100%", // Default width set to 100%
      bgcolor = "white",
    } = props;
    const hiddenDateInput = useRef<HTMLInputElement>(null);
    const [dateSelected, setDateSelected] = useState<any>(undefined);

    const handleDateButtonClick = (event: any) => {
      const element: any = document.getElementById("datePicker");
      if (element && "showPicker" in element) {
        element?.showPicker();
      }
      if (hiddenDateInput.current) {
        hiddenDateInput.current.showPicker();
      }
      event.preventDefault();
    };

    const onChangeHandler = (event: any) => {
      if (onChange) {
        onChange(event);
      }
      const { value } = event.target;
      if (value) {
        setDateSelected(dateFormattor(new Date(value)));
      }
    };

    const variantClasses = {
      search: `w-full h-[10px] py-5 form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
      basic: `w-full form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
      rawInput: `w-full form-input border h-[30px] px-[8px] py-[3px] flex border-gray-400 text-gray-600 rounded-sm focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
      fileSize: `w-full h-[10px] py-5 form-input border h-[56px] px-[8px] py-[16px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle] ${props.className}`,
    };

    return (
      <>
        {type === "date" ? (
          <div className="flex justify-start items-center w-full border rounded-xl">
            <button
              onClick={handleDateButtonClick}
              className="flex justify-between h-[56px] w-full px-[8px] py-[16px] rounded-lg text-gray-600 bg-white hover:bg-gray-100 focus:outline-none"
            >
              {dateSelected ? dateSelected : "Select Date"}
              <div>
                <img src={Calender} alt="" />
              </div>
            </button>
            <input
              ref={ref}
              type="date"
              id="datePicker"
              className="absolute -z-10"
              onChange={onChangeHandler}
            />
          </div>
        ) : (
          <div className="relative text-sm w-full">
            <input
              type="text"
              style={{ width: initialWidth, backgroundColor: bgcolor }}
              className={variantClasses[props.variant ?? "basic"]}
              {...props}
              ref={ref}
            />
            {props.variant === "fileSize" && (
              <span className="absolute text-gray-400 right-1 top-3 py-1 pl-2 bg-white">
                in KB
              </span>
            )}
          </div>
        )}
      </>
    );
  }
);

export default InputFields;
