import add from "../../assets/images/add.svg";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
interface AddDistrictModalProps {
  onClose: () => void;
}

const AddDistrictModal: React.FC<AddDistrictModalProps> = ({ onClose }) => {
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const addCounty = [
    {
      id: "districtName",
      label: "District Name:",
      placeHolder: "Enter the district name",
    },
    {
      id: "districtCode",
      label: "District Code:",
      placeHolder: "Enter the district code",
    },
  ];
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={onClose}
      >
        <Box sx={style}>
          <div className="m-[16px] md:m-[0px]  rounded-2xl p-[8px] text-gilroy-medium pb-[30px] shadow-xl bg-white w-80 sm:w-[350px] md:w-[500px] lg:w-[600px]">
            <div
              className="flex flex-row justify-end mb-[12px] cursor-pointer"
              onClick={onClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="text-center text-black text-2xl font-normal ">
              Add District
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="md:px-[32px] px-[16px]">
                {addCounty.map((items) => (
                  <div className="mt-[20px]">
                    <label
                      htmlFor={items.id}
                      className=" text-black text-[16px] font-normal   text-gilroy-medium"
                    >
                      {items.label}
                    </label>
                    <InputFields
                      id="subjectText"
                      className="mt-[8px] w-full rounded-[16px] h-[61px] px-[16px] py-[21px] border  border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
                      placeholder={items.placeHolder}
                    />
                  </div>
                ))}

                <hr className="w-full bg-[#E6E6E6] mb-[24px] mt-[60px]"></hr>
                <ButtonComp onClose={onClose} />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default AddDistrictModal;
