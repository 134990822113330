import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import ReactTable from "../../components/common/ReactTable";
import LoaderSpin from "../../components/LoaderSpin";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContentThree from "../../components/cms/EntityRegistrationOtpContentThree";
import Addnotification from "./Addnotification";
import Button from "../../components/form/Button";
import { axiosUAMInstance } from "../../utils/axios";

const CmsWebsiteContentInternalPageThree = () => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [originalData, setOriginalData] = useState<any>([]);
  async function fetchData() {
    try {
      const { data: apiData } = await axiosUAMInstance.get(
        `/websitecontent/get/name?wcname=Notification`
      );

      const content = apiData.data.content.data[0]?.notificationsPageData || {
        heading: [],
        button: [],
        notifications: [],
      };

      const sections = [
        {
          section: "heading",
          data: content.heading,
        },
        {
          section: "button",
          data: content.button,
        },
        {
          section: "notifications",
          data: content.notifications,
        },
      ];

      const transformedData: any = sections.flatMap((section) =>
        section.data.map((item: any) => ({
          id: item.id,
          section: section.section,
          text: item.text,
          link: item.link || "N/A",
          img: item.img || null,
          date: item.date || "No date specified",
        }))
      );

      setContentData(content);
      setTableData(transformedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setTableData([]);
    }
    setLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeNotification = () => {
    setIsNotificationOpen(false);
  };

  const columns = [
    { accessorKey: "id", header: "ID", enableSorting: false },
    { accessorKey: "section", header: "Section" },
    { accessorKey: "text", header: "Text" },
    {
      accessorKey: "link",
      header: "Link",
      cell: (info: any) =>
        info.getValue() !== "N/A" ? (
          <a href={info.getValue()} target="_blank" rel="noopener noreferrer">
            View More
          </a>
        ) : (
          "N/A"
        ),
    },
    {
      accessorKey: "img",
      header: "Image",
      cell: (info: any) =>
        info.getValue() ? (
          <img
            src={info.getValue()}
            alt="Notification"
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          "N/A"
        ),
    },
    { accessorKey: "date", header: "Date" },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: ({ row }: any) => (
        <ActionButton
          variant="edit"
          onClick={() => {
            setIsModalOpen(true);
            setCurrentEditData(row.original);
          }}
        />
      ),
    },
  ];

  const notificationManager = sessionStorage.getItem(
    "notification-manager-role"
  );

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">{!notificationManager && <CmsTabs />}</div>
          <div className="mt-4">
            <div className="my-6 flex justify-end items-end">
              <Button
                // loader={loader}
                type="button"
                label="Add Notification"
                onClick={() => setIsNotificationOpen(true)}
              />
            </div>
            <div className="flex justify-center items-center my-3">
              <h1 className="text-lg text-gilroy-regular">Notification</h1>
            </div>
            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div>
                {loader ? (
                  <LoaderSpin />
                ) : tableData.length > 0 ? (
                  <ReactTable defaultData={tableData} columns={columns} />
                ) : (
                  <div className="flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isNotificationOpen && (
            <Addnotification
              onClose={closeNotification}
              content={originalData}
              apiCall={fetchData}
            />
          )}
          {isModalOpen && (
            <EntityRegistrationOtpContentThree
              onClose={closeModal}
              currentEditData={currentEditData || {}}
              apiCall={fetchData}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPageThree;
