import React, { ReactNode, useState, useEffect } from "react";
import Sidebar from "../common/Sidebar";
import axios from "axios";

interface SidebarProps {
  layout: ReactNode;
}

const Layout: React.FC<SidebarProps> = ({ layout }) => {
 
  return (
    <div>
      <Sidebar layout={layout} />
    </div>
  );
};

export default Layout;
