// import React, { FC, InputHTMLAttributes, forwardRef } from "react";

// interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
//   height?: string;
//   width?: string;
//   padding?: string;
//   error?: boolean;
// }

// const InputFields: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
//   (
//     {
//       height = "56px",
//       width = "100%",
//       padding = "8px 16px",
//       className,
//       error,
//       ...rest
//     },
//     ref
//   ) => {
//     return (
//       <input
//         type="text"
//         className={`${
//           error && "border-[red] text-[red]"
//         } form-input border flex border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-300 justify-between align-middle w-[300px] md:w-[350px] lg:w-[${width}]  ${className} `}
//         style={{
//           height,
//           // width,
//           padding,
//         }}
//         {...rest}
//         ref={ref}
//       />
//     );
//   }
// );

// export default InputFields;
// import React, {
//   FC,
//   InputHTMLAttributes,
//   forwardRef,
//   KeyboardEvent,
// } from "react";

// interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
//   height?: string;
//   padding?: string;
//   error?: boolean;
//   onEnterPress?: () => void;
// }

// const InputFields: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
//   (
//     {
//       height = "56px",
//       padding = "8px 16px",
//       className,
//       error,
//       onEnterPress,
//       ...rest
//     },
//     ref
//   ) => {
//     const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
//       if (event.key === "Enter" && onEnterPress) {
//         onEnterPress();
//       }
//     };
//     return (
//       <input
//         type="text"
//         className={`${
//           error
//             ? "border-red-500 text-red-500"
//             : "border-[#1C468E] border-[1px]"
//         } form-input border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-300 ${className} w-full`}
//         style={{
//           height,
//           padding,
//         }}
//         {...rest}
//         ref={ref}
//         onKeyPress={handleKeyPress}
//       />
//     );
//   }
// );

// export default InputFields;
import React, {
  FC,
  InputHTMLAttributes,
  forwardRef,
  KeyboardEvent,
} from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string;
  padding?: string;
  width?: string;
  error?: boolean;
  onEnterPress?: () => void;
}

const InputFields: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      height = "56px",
      padding = "8px 16px",
      width = "100%",
      className,
      error,

      onEnterPress,
      ...rest
    },
    ref
  ) => {
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onEnterPress) {
        onEnterPress();
      }
    };
    return (
      <input
        type="text"
        className={`${
          error
            ? "border-red-500 text-red-500"
            : "border-[#7e7e7e] border-[1px]"
        } form-input border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-300 ${className}`}
        style={{
          height,
          padding,
          width,
        }}
        {...rest}
        ref={ref}
        onKeyPress={handleKeyPress}
      />
    );
  }
);

export default InputFields;
