import React from "react";
import SignUpFormItems from "./SignUpFormItems";

interface TabData {
  id: string;
  label: string;
  // Add other properties as needed
}

interface Props {
  tabsData?: TabData[];
  handleTabOnClick ?: (data : any) => void
}

const ContentsDiv: React.FC<Props> = ({ tabsData, handleTabOnClick }) => {
  return (
    <div className="mt-[26.86px] mx-[16px] justify-start items-start gap-2 flex flex-wrap pb-2">
      {tabsData?.map((tab) => (
        <div onClick={handleTabOnClick ? () => handleTabOnClick(tab) : () => {}}>
          <SignUpFormItems key={tab.id} item={tab.label} />
        </div>
      ))}
    </div>
  );
};

export default ContentsDiv;
