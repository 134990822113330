import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import Accordion from "../../components/cms/CustumAccordin/custumAccordin";
import UserManagement from "../../components/UserManagement/userManagement";
import EditUserManagement from "../../components/UserManagement/EditUserManagement";

interface AccordionItem {
  header: React.ReactNode;
  content: React.ReactNode;
}

const EditUser = () => {
  const accordionItems: AccordionItem[] = [
    {
      header: "User details",
      content: <EditUserManagement />,
    },
  ];
  return (
    <div>
      <Layout
        layout={
          <div className="relative mx-4 xl:ml-[40px]">
            <div className="mt-3">
              <h1 className="text-xl font-normal text-[#1C468E] text-gilroy-semibold ">
                Edit user
              </h1>
            </div>
            <div className="mt-6">
              <Accordion items={accordionItems} showEdit={true} />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default EditUser;
