import React, { ReactNode, useState, useEffect } from "react";
import Logo from "../../assets/images/logo2.svg";
import dashboardLogo from "../../assets/images/dashboard.svg";
import dashboardWhiteLogo from "../../assets/images/dashboardWhiteLogo.svg";
import myTaskIcon from "../../assets/images/myTaskIcon.svg";
import myTaskWhiteIcon from "../../assets/images/myTaskWhiteIcon.svg";
import entityMasterIcon from "../../assets/images/entitymaster.svg";
import entityMasterWhiteIcon from "../../assets/images/entitymasterwhite.svg";
import schemMasterIcon from "../../assets/images/schemMasterIcon.svg";
import schemMasterWhiteIcon from "../../assets/images/schemMasterWhiteIcon.svg";
import CmsIcon from "../../assets/images/new_images/cmsIcon.png";
import CmsWhiteIcon from "../../assets/images/new_images/cmsWhiteIcon.png";
import misIcon from "../../assets/images/misIcon.svg";
import userIcon from "../../assets/images/userIcon.svg";
import userWhiteIcon from "../../assets/images/userIconwhite.svg";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowClose from "../../assets/images/new_images/arrowClose.png";
import useCollapseStore from "../../state/useCollapseStore";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import useCommonStore from "../../state/commonStore";
import { axiosUAMInstance } from "../../utils/axios";
import Swal from "sweetalert2";

interface SidebarProps {
  layout: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ layout }) => {
  const { isAnyModalOpen } = useCommonStore((state) => state);
  const [mSidebar, setMsidebar] = useState<boolean>(false);
  const [url, setUrl] = useState<String>("");
  const [roles, setRoles] = useState<string[]>([]);
  const [sidebarData, setSidebarData] = useState<any>([]);
  const [sidebar, setSidebar] = useState<any>([]);
  const [tokenData, setTokenData] = useState(null);
  const [state, setState] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const collapse = useCollapseStore((state: any) => state.collapse);
  const setCollapse = useCollapseStore((state: any) => state.setCollapse);

  const refreshToken = sessionStorage.getItem("refresh_token");

  useEffect(() => {
    const refreshCount = () => {
      // Get the current count from sessionStorage
      const count = parseInt(sessionStorage.getItem("refreshCount") || "0", 10);

      // Update the count and save it back to sessionStorage
      const newCount = count + 1;
      sessionStorage.setItem("refreshCount", newCount.toString());
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", refreshCount);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", refreshCount);
    };
  }, [state]);

  useEffect(() => {
    const reloadToken = sessionStorage.getItem("reload");
    if (reloadToken) {
      window.location.reload();
      sessionStorage.setItem("reload", "");
    }
  }, []);

  // Function to fetch data from the API
  const fetchData = async () => {
    axiosUAMInstance
      .post("/adminauth/refresh-token", {
        refresh_token: refreshToken,
      })
      .then((responce) => {
        sessionStorage.setItem("access_token", responce?.data?.access_token);
        sessionStorage.setItem("refresh_token", responce?.data?.refresh_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch data on initial render
    fetchData();

    const intervalId = setInterval(fetchData, 500000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const notificationManager = sessionStorage.getItem(
    "notification-manager-role"
  );

  const data = [
    {
      title: "Dashboard",
      url: "/dashboard",
      rurl: "/dashboard",
      logo: dashboardLogo,
      selectlogo: dashboardWhiteLogo,
      sort: "a",
    },
    {
      title: "My Task",
      url: "/mytask",
      rurl: "/mytask/deposit",
      logo: myTaskIcon,
      selectlogo: myTaskWhiteIcon,
      sort: "b",
    },
    {
      title: "Entity Master",
      url: "/entitymaster",
      rurl: "/entitymaster/deposit",
      logo: entityMasterIcon,
      selectlogo: entityMasterWhiteIcon,
      sort: "c",
    },
    {
      title: "Scheme Master",
      url: "/schememaster",
      rurl: "/schememaster",
      logo: schemMasterIcon,
      selectlogo: schemMasterWhiteIcon,
      sort: "d",
    },
    {
      title: "Content  Management System",
      url: "/cms",
      rurl: notificationManager
        ? "/cms/websitecontent/internal/3"
        : "/cms/register",
      logo: CmsIcon,
      selectlogo: CmsWhiteIcon,
      sort: "e",
    },
    {
      title: "MIS Reports",
      url: "/mis",
      rurl: "/mis",
      logo: misIcon,
      selectlogo: misIcon,
      sort: "f",
    },
    {
      title: "User Management",
      url: "/usermanagement",
      rurl: "/usermanagement/role",
      logo: userIcon,
      selectlogo: userWhiteIcon,
      sort: "g",
    },
  ];
  useEffect(() => {
    const sessionData = sessionStorage.getItem("roles");

    if (sessionData) {
      const rolesArray: string[] = sessionData.split(",");
      setRoles(rolesArray);
      for (let i = 0; i < rolesArray.length; i++) {
        if (rolesArray[i] === "approver-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[2],
            data[1],
          ]);
        }
        if (rolesArray[i] === "cms-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[4],
          ]);
        }
        if (rolesArray[i] === "entity-master-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[2],
            data[3],
          ]);
        }
        if (rolesArray[i] === "notification-manager-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[4],
          ]);
        }
        if (rolesArray[i] === "reviewer-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[2],
            data[1],
          ]);
        }
        if (rolesArray[i] === "user-manager-admin-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[6],
          ]);
        }
        if (rolesArray[i] === "mis-report-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[5],
          ]);
        }
        if (rolesArray[i] === "dashboard-viewer-role") {
          setSidebarData((prevSidebarData: any) => [
            ...prevSidebarData,
            data[0],
          ]);
        }
      }
    }
  }, []);

  useEffect(() => {
    const sortedData = sidebarData.sort((a: any, b: any) => {
      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    });

    const uniqueData: any[] = [];
    const uniqueTitles = new Set();

    sortedData.forEach((item: any) => {
      const title = item.title;
      if (!uniqueTitles.has(title)) {
        uniqueData.push(item);
        uniqueTitles.add(title);
      }
    });

    setSidebar(uniqueData);
  }, [sidebarData]);

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  useEffect(() => {
    const cmsPath = pathname.split("/")[1];
    setUrl("/" + cmsPath);
  }, []);

  const sidebarOnclick = () => {
    setMsidebar(!mSidebar);
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const optionOnclick = (url: String) => {};

  const handleUserActivity = () => {
    setIsActive(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a timeout to mark the user as inactive after 10 minutes (600000 ms)
    const newTimeoutId = setTimeout(() => {
      setIsActive(false);
    }, 600000);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("focus", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("focus", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const refreshPage = sessionStorage.getItem("refreshCount");

  useEffect(() => {
    if (!isActive || refreshPage == "2") {
      sessionStorage.clear();
      Swal.fire({
        icon: "error",
        title:
          refreshPage == "2"
            ? "Dont refresh the page. Please login again"
            : "User inactive for 10 min. Please login again",
      });
      navigate("/");
    }
  });

  return (
    <div className={`${isAnyModalOpen ? "blur-[4px]" : ""}`}>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        onClick={sidebarOnclick}
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 ${
          collapse ? "w-[100px]" : "w-[322px]"
        }w-[322px] h-screen transition-transform ${
          mSidebar ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
        } `}
        aria-label="Sidebar"
      >
        <div
          className={`h-full overflow-y-auto bg-[#E7F0FF] w-[225px] ${
            collapse ? "w-[90px]" : "md:w-[322px]"
          }`}
        >
          <ul className="">
            <li className="flex items-center justify-between lg:block border-b border-[#E6E6E6] p-4">
              <img src={Logo} alt="logo" className="max-h-[52px]" />
              <button className="block lg:hidden" onClick={sidebarOnclick}>
                <img src={CrossIcon} alt="Close sidebar" className="w-6 h-6" />
              </button>
              <div
                className="hidden lg:block absolute right-20 md:-right-5 top-16 p-2"
                onClick={handleCollapse}
              >
                <img
                  src={ArrowClose}
                  alt="closearroe"
                  className={`bg-[#E7F0FF] rounded-full cursor-pointer ${
                    collapse && "transform scale-x-[-1]"
                  }`}
                />
              </div>
            </li>
            {sidebar?.map((data: any, idx: any) => {
              return (
                <li
                  className={`${
                    collapse
                      ? "flex items-center justify-center py-2"
                      : "px-4 py-2"
                  } `}
                  key={idx}
                >
                  <Link to={data?.rurl}>
                    <div
                      onClick={(e) => optionOnclick(data?.url)}
                      className={`w-[190px] ${
                        collapse ? "w-[60px]" : " md:w-[290px]"
                      } h-auto md:h-14 ${
                        url === data?.url ? "bg-[#1c468e]" : "bg-[#E7F0FF]"
                      }  rounded-lg flex items-center ${
                        collapse ? "justify-center" : "justify-start"
                      } cursor-pointer`}
                    >
                      <div className="m-4 h-[24px] w-[24px]">
                        <img
                          src={
                            url === data?.url ? data?.selectlogo : data?.logo
                          }
                          alt="logo"
                          className="object-fit"
                        />
                      </div>
                      {!collapse && (
                        <div
                          className={`${
                            url === data?.url ? "text-white" : "text-[#666666]"
                          }  text-base font-normal text-gilroy-medium leading-normal`}
                        >
                          {!collapse && data?.title}
                        </div>
                      )}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>

      <div className={` ${collapse ? "lg:ml-[90px]" : "lg:ml-[322px]"}`}>
        <div>
          <Header />
        </div>
        <div className="overflow-x-hidden">{layout}</div>
      </div>
    </div>
  );
};

export default Sidebar;
