import { create } from 'zustand';

interface CMSFormControl {
    isAnyModalOpen : boolean,
    toggleModal: (data : boolean) => void;
}
const useCommonStore = create<CMSFormControl>(set => ({
    isAnyModalOpen : false,
    toggleModal : (data : boolean) => set(state => ({isAnyModalOpen : data})),
}));

export default useCommonStore;