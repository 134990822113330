import { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { createColumnHelper } from "@tanstack/table-core";
import ReactTable from "../../components/common/ReactTable";
import CmsTabs from "../../components/cms/CmsTabs";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import ActionButton from "../../components/common/ActionButton";
import { useNavigate } from "react-router-dom";
import LoaderSpin from "../../components/LoaderSpin";
import { axiosUAMInstance } from "../../utils/axios";

type EntityType = {
  id: number;
  entityName: string;
  registrationAllowed: boolean;
  autoApproval: boolean;
};

const columnHelper = createColumnHelper<EntityType>();

const CmsRegister = () => {
  const [data, setData] = useState<EntityType[]>();
  const [loader, setLoader] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const navigate = useNavigate();
  console.log({ data });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/registration/entities`)
      .then((responce) => {
        const data = responce?.data?.data;
        const sortedData = data.sort(
          (a: { id: number }, b: { id: number }) => a.id - b.id
        );
        setData(sortedData);
        setTotal(responce?.data?.data?.length);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  let count = 0;
  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>Sr. No.</span>,
    }),
    columnHelper.accessor("entityName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Entity Name</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "Reg. Allowed",
      cell: (info) => {
        const value = info.getValue();

        const apiCall = () => {
          axiosUAMInstance
            .patch(
              `/registration/toggle-reg-allowed/${value?.id}?set=${
                value?.registrationAllowed === true ? false : true
              }`
            )
            .then((responce) => {
              console.log(responce);
            })
            .catch((error) => {
              console.log(error);
            });
        };
        return (
          <div key={Math.random()}>
            <ToggleSwitch
              enabled={value?.registrationAllowed}
              apiCall={apiCall}
            />
          </div>
        );
      },
      header: () => <span>Reg. Allowed</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "Auto Approval",
      cell: (info) => {
        const value = info.getValue();

        const apiCall = () => {
          axiosUAMInstance
            .patch(
              `/registration/toggle-auto-approval/${value?.id}?set=${
                value?.autoApproval === true ? false : true
              }`
            )
            .then((responce) => {
              console.log(responce);
            })
            .catch((error) => {
              console.log(error);
            });
        };
        return (
          <div key={Math.random()}>
            <ToggleSwitch enabled={value?.autoApproval} apiCall={apiCall} />
          </div>
        );
      },
      header: () => <span>Auto Approval</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();
        console.log(value);
        return (
          <div className="flex flex-row justify-center">
            <div className="mr-2">
              <ActionButton
                variant="edit"
                onClick={() =>
                  navigate("/cms/register/formcontrol", {
                    state: { id: value },
                  })
                }
              />
            </div>
            <div>
              <ActionButton
                variant="magicpen"
                onClick={() => navigate("/cms/register/tree")}
              />
            </div>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];
  return (
    <div>
      <Layout
        layout={
          <>
            <div className="mt-4 ml-4 xl:ml-[40px]">
              <CmsTabs />
            </div>
            <div className="mt-2">
              <div className="md:px-10 md:py-4">
                {loader ? (
                  <div className="mt-10">
                    <LoaderSpin />
                  </div>
                ) : (
                  <>
                    {data && (
                      <ReactTable defaultData={data} columns={columns} />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default CmsRegister;
