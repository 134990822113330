import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import add from "../../assets/images/add.svg";
import TextArea from "../common/TextArea";
import SignUpFormItems from "./SignUpFormItems";
import InputFields from "./InputField";
import ButtonComp from "./ButtonComp";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface EditRegistrationOtpModalTwoProps {
  onClose: () => void;
  data: any;
}

const EditRegistrationOtpModalTwo: React.FC<
  EditRegistrationOtpModalTwoProps
> = ({ onClose, data }) => {
  // const [text, setText] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [msg, setMsg] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    getApiCall();
  }, []);

  const getApiCall = () => {
    axiosUAMInstance
      .get(`/template/list/${data?.action}/${data?.id}`)
      .then((response) => {
        console.log(response?.data?.data);
        setTags(response?.data?.data?.applicable_tags);
        setMsg(response?.data?.data?.message_body);
        setSubject(response?.data?.data?.subject);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateApiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .put(`/template/update/${data?.id}`, {
        message: msg,
        subject: subject,
      })
      .then((response) => {
        console.log(response);
        Swal.fire({
          title: `Data updated successfully`,
          icon: "success",
        });
        setLoader(false);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        Swal.fire({
          title: "Something went wrong! Please try again",
          icon: "error",
        });
      });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMsg(event.target.value);
  };

  const handleChange2 = (event: any) => {
    setSubject(event.target.value);
  };

  const countWords = (text: string) => {
    if (!text.trim()) return 0;
    const words = text.trim().split(/\s+/);
    return words.length;
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateApiCall();
  };

  const handleClose = () => {
    onClose();
  };

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const handleTagClick = (tag: string) => {
    // Append tag to the message text
    setMsg((prevMsg) => prevMsg + " " + tag);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-30 z-40"></div>
      <div
        className="fixed  m-[16px] md:m-[0px] w-[90%] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[46px] shadow-xl backdrop-blur-lg bg-white z-50"
        onClick={handleModalClick}
      >
        <div
          className="flex flex-row justify-end mb-[12px] cursor-pointer"
          onClick={handleClose}
        >
          <img src={add} className="w-6 h-6" alt="icon" />
        </div>
        <h1 className="mb-[20px] text-center text-black text-[24px] font-normal">
          Entity Registration OTP
        </h1>

        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col items-center p-4">
            <label
              htmlFor="subjectText"
              className="text-black text-[16px] font-normal text-gilroy-medium self-start "
            >
              Subject
            </label>
            <InputFields
              id="subjectText"
              className="mt-[8px] w-full rounded-[16px] h-[50px] px-[16px] py-[8px] mb-[24px] border border-[#E6E6E6] focus:outline-none focus:ring-1 focus:ring-gray-100"
              placeholder="Invite Link"
              value={subject}
              onChange={handleChange2}
            />
          </div>
          <div className="flex flex-col  md:px-4 ">
            <label
              htmlFor="bodyText"
              className="text-black text-[16px] font-normal text-gilroy-medium self-start"
            >
              Body Text
            </label>
            <TextArea
              mdWidth="500px"
              value={msg}
              id="bodyText"
              onChange={handleChange}
              placeholder="Type your reason here"
              className="mt-[8px] text-[16px] h-[90px] border border-[#E6E6E6] rounded-[16px] w-full p-[16px] focus:outline-none focus:ring-1 focus:ring-gray-100 textarea-component"
            />

            <div className="flex justify-between mt-[8px]">
              <p className="text-black text-base font-normal">
                Applicable Tags:
              </p>
              <p className="text-right text-black text-opacity-40 text-xs font-normal leading-[14px]">
                {countWords(msg)}/500
              </p>
            </div>
            {/* <div className="mt-[16px] mb-[26px] flex flex-wrap"> */}
            <div className="my-[16px] justify-start items-start gap-2 flex flex-wrap pb-2">
              {tags.map((menuItem, index) => (
                <SignUpFormItems
                  key={index}
                  item={menuItem}
                  onClick={() => handleTagClick(menuItem)}
                />
              ))}
            </div>
          </div>
          <div>
            <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
            <ButtonComp onClose={handleClose} loader={loader} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRegistrationOtpModalTwo;
