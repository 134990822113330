import React from "react";
import TreeNode from "./TreeNode";

interface Node {
  label: string;
  children?: Node[];
}

const Tree: React.FC = () => {
  const treeData: Node[] = [
    {
      label: "Dashboard",
    },

    {
      label: "Scheme management",
      children: [
        {
          label: "Scheme Creation",
        },
        {
          label: "Audit trail",
        },
      ],
    },
    {
      label: "User access management",
      children: [
        {
          label: "create role",
        },
        {
          label: "create user",
        },
      ],
    },
  ];

  const renderTreeNodes = (nodes: Node[] | undefined) => {
    if (!nodes) return null;

    return nodes.map((node) => (
      <TreeNode key={node.label} label={node.label}>
        {renderTreeNodes(node.children)}
      </TreeNode>
    ));
  };

  return (
    <div className="flex flex-row pl-[40px] mt-[40px]">
      <div className="mr-[4px] w-[8px] h-[250px] mt-[18px] border-l border-t border-b border-dashed border-[#666666]">
        <div className="border-b pt-[55px] border-dashed border-[#666666]"></div>
      </div>
      <div>{renderTreeNodes(treeData)}</div>
    </div>
  );
};

export default Tree;
