import React, { FC, InputHTMLAttributes, forwardRef } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  filename: string;
  icon: string;
}

const NotificationFileUpload: FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ filename, icon, ...props }, ref) => {
    const handleClick = (event: any) => {
      const input = document.getElementById("inputButton");
      input?.click();
      event.preventDefault();
    };

    const buttonStyle = {
      backgroundColor: "white",
      padding: "10px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #E6E6E6",
    };

    const iconStyle = {
      marginRight: "8px",
    };

    const filenameStyle = {
      color: "#7f7f7f",
    };

    const classname =
      "mb-[8px] top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle]";

    return (
      <div className="relative w-full">
        <input
          {...props}
          ref={ref}
          className={classname}
          type="file"
          id="inputButton"
        />
        <button
          onClick={handleClick}
          className="top-0 w-full z-20 bg-white form-input border h-[50px] flex border-gray-300 text-gray-600 rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-100 justify-between align-middle overflow-hidden"
          style={buttonStyle}
        >
          <span className="relative mr-[350px]" style={filenameStyle}>
            {filename ? filename : "Browse files"}
          </span>
          {icon && (
            <img
              src={icon}
              alt=""
              className="mr-4 rounded-lg w-6"
              style={iconStyle}
            />
          )}
        </button>
      </div>
    );
  }
);

export default NotificationFileUpload;
