import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import LoaderSpin from "../components/LoaderSpin";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../src/utils/axios";

const Mis = () => {
  const [loader1, setloader1] = useState<boolean>(false);
  const [loader2, setloader2] = useState<boolean>(false);

  const download = () => {
    setloader1(true);
    axiosUAMInstance
      .get("/mis-report/download")
      .then((responce) => {
        const url = responce?.data?.data;

        if (url) {
          window.open(url, "_blank");
          Swal.fire({
            icon: "success",
            title: "Download successful",
            text: "File downloaded successfully",
          });
        }

        // if (url) {
        //   const newTab = window.open("", "_blank");
        //   if (newTab) {
        //     newTab.location = url;
        //   } else {
        //     window.location.href = url;
        //   }
        // }

        setloader1(false);
      })
      .catch((error: any) => {
        console.log(error);
        setloader1(false);
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
          text: "",
        });
      });
  };

  const downloadEmailMis = () => {
    const emailId = sessionStorage.getItem("emailId");
    setloader2(true);
    axiosUAMInstance
      .post("/mis-report/email", {
        email: emailId,
      })
      .then((response) => {
        const data = response?.data;
        console.log(data);

        Swal.fire({
          icon: "success",
          title: "Email Sent",
          text: response?.data?.message,
        });

        setloader2(false);
      })
      .catch((error: any) => {
        console.log(error);
        setloader2(false);
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
          text: "",
        });
      });
  };

  return (
    <div>
      <Layout
        layout={
          <div className="flex items-center justify-center">
            <div className="m-4">
              {" "}
              <button
                disabled={loader1}
                onClick={download}
                className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1c468e] rounded-xl text-white ${
                  loader1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loader1 ? <LoaderSpin /> : "Download Mis Report"}
              </button>
            </div>
            <div className="m-4">
              {" "}
              <button
                disabled={loader2}
                onClick={downloadEmailMis}
                className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1c468e] rounded-xl text-white ${
                  loader2 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loader2 ? <LoaderSpin /> : "Email Mis Report"}
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Mis;
