import { useState, useEffect } from "react";
import { axiosTraceIdInstance, axiosUAMInstance } from "../utils/axios";
import { bffUrl } from "../utils/api";

function useFetchEntities() {
  const [entities, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const req = axiosUAMInstance.get(`/registration/entities`);
        const data = (await req).data;
        if (!data?.success) {
          throw new Error(`HTTP error! status: ${data.statusCode}`);
        }
        setData(data.data);
        setError(null);
      } catch (error: any) {
        setError(error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Optional: Cleanup function in case of component unmounting
    return () => {
      // Abort fetch operation or other cleanup actions
    };
  }, []); // Dependency array, can include other variables if the fetch depends on them

  return { entities, loading, error };
}

export default useFetchEntities;
