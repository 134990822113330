import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import add from "../../assets/images/add.svg";
import useWebContentStore from "../../state/useWebContentStore";
import InputFields from "../../components/form/InputField";
import LoaderSpin from "../../components/LoaderSpin";
import Swal from "sweetalert2";
import zIndex from "@mui/material/styles/zIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface IFormInput {
  question: string;
  answer: string;
}

interface Props {
  onClose: () => void;
  apiCall?: (data: {qaList : any[], sectionId : number, questionIdx : number | null}) => Promise<void>;
  editData: {
    q: string
    a: string
    id: null | number,
    idx: number | null,
    sectionId: any
  },
  loader: boolean,
  setLoader?: any
}

const AddEditQuestionAnswers: React.FC<Props> = ({ onClose, apiCall, editData, loader, setLoader }) => {
  const { faqData, setFaqData } = useWebContentStore()
  const [sectionName, setSectionName] = useState("")
  const [error, setError] = useState("")
  const [qaList, setQaList] = useState<any>([])
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormInput>();;
  
  useEffect(() => {
    console.log("Yoo in log");
    if (editData.idx !== null) {
      
      const filterData = faqData?.alldata?.map((d: any) => {
        return {
          ...d?.content?.data?.faqPageData?.questionSections?.find((s: any, i : number) => i == parseInt(editData?.sectionId))
  
            ?.questions[editData?.idx ?? 0],
          language: d?.language,
          qerror: "",
          aerror: "",
        }
      })
  
      setQaList(filterData)
    }
    else{
      const filterData = faqData?.alldata?.map((d: any) => {
        return {
          question : "",
          answer : "",
          language: d?.language,
          qerror: "",
          aerror: "",
        }
      })
  
      setQaList(filterData)
    }

  }, [editData])
  console.log({ faqData, editData });


  const handleFormSubmit = async (event : any) => {
    event.preventDefault();
    let error = 0;
    let updated = qaList?.map((qa : any) => {
      console.log({qa});
      
      let obj = qa;
      if (obj?.question?.trim() === "") {
        error+=1;
        obj = {...obj, qerror : "Question should not be empty"}
      }
      else{
        console.log("Yooo");
        
        obj = {...obj, qerror : ""}
      }
      if(obj?.answer?.trim() === ""){
        error+=1;
        obj = {...obj, aerror : "Answer should not be empty"}
      }
      else{
        obj = {...obj, aerror : ""}
      }

      return obj;
    })

    setQaList(updated);
    if(error > 0 ){
      setError('Some fields are missing')
      return
    }
    else{
      setError('')
    }
    if (apiCall) {
      try {
        if (setLoader) {
          setLoader(true)
        }
        await apiCall({qaList, sectionId : editData.sectionId, questionIdx : editData.idx})

      } catch (error) {

      }
      finally {
        if (setLoader) {
          setLoader(false)
        }
      }
    }
  };

  const handleInputChange = (event: any, idx: number) => {
    const { value, name } = event?.target

    const updated = [...qaList];
    updated[idx] = {
      ...updated[idx],
      [name]: value
    }
    setQaList(updated)

  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className=" md:m-[0px] w-[270px] md:w-[70rem] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="text-center text-black text-[24px] font-normal border-b-2">
            Add Question And Answer
            <p className="text-xl text-red-400">{error}</p>
          </h1>
          <div
            className="custom-scrollbar p-4"
            style={{
              maxHeight: "27rem",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
              {
                qaList?.length > 0 &&
                qaList?.map((qa: any, index: number) => {
                  return <div className="p-2 m-2" key={index}>
                    <h1 className="bg-gray-200 rounded-xl p-1 text-center mb-2">{qa?.language}</h1>
                    <div className="bg-[#ebf0fa] p-2 rounded mb-2">
                      <label
                        htmlFor="Text"
                        className="text-black text-[16px] font-normal text-gilroy-medium"
                      >
                        Question:
                      </label>
                      <InputFields
                        onChange={(e) => handleInputChange(e, index)}
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-2 w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="type here"
                        value={qa?.question}
                        name="question"
                      />
                      <span className="text-red-400">{qa?.qerror}</span>
                    </div>
                    <div className="bg-gray-100 p-2 rounded">
                      <label
                        htmlFor="Text"
                        className="text-black text-[16px] font-normal text-gilroy-medium"
                      >
                        Answer:
                      </label>
                      <textarea
                        onChange={(e) => handleInputChange(e, index)}
                        className="shadow appearance-none border rounded-2xl h-[61px] mb-2 w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="type here"
                        value={qa?.answer}
                        name="answer"
                      />
                      <span className="text-red-400">{qa?.aerror}</span>
                    </div>
                  </div>
                })

              }
          </div>
              <hr className="w-full bg-[#E6E6E6]"/>
              <div className="flex flex-row justify-around pt-3">
                <button
                  className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1c468e] text-[#1c468e]"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmit}
                  type={"button"}
                  className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] rounded-xl text-white ${loader
                    ? "bg-gray-300 cursor-not-allowed text-white"
                    : "bg-[#1c468e]"
                    }`}
                  disabled={loader}
                >
                  {loader ? <LoaderSpin /> : "Save"}
                </button>
              </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddEditQuestionAnswers;
