import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import RegSignUpHead from "../../components/cms/RegSignUpHead";
import ContentsDiv from "../../components/cms/ContentsDiv";
import { createColumnHelper } from "@tanstack/table-core";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import ActionButton from "../../components/common/ActionButton";
import ReactTable from "../../components/common/ReactTable";
import EditModal from "../../components/cms/EditModal";
import ManageFormFields from "../../components/cms/ManageFormFields";
import useCmsFormControlStore from "../../state/cmsFormControl";
import { useLocation } from "react-router-dom";
import ManageFormFieldsForRegistration from "../../components/cms/ManageFormFieldsForRegistration";
import LoaderSpin from "../../components/LoaderSpin";
import EditDocumentModel from "../../components/cms/EditDocument";
import AddFormFieldModalScheme from "../../components/cms/AddFormFieldModalScheme";
import EditModalScheme from "../../components/cms/EditModalScheme";
import ManageFormFieldsForRegistrationScheme from "../../components/cms/ManageFormFieldsForRegistrationScheme";
import EditDocumentScheme from "../../components/cms/EditDocumentScheme";
import { axiosUAMInstance } from "../../utils/axios";

type CmsRegister = {
  id: number;
  documentName: string;
  addToRegistration: boolean;
  addToProfile: boolean;
  required: boolean;
};

const columnHelper = createColumnHelper<CmsRegister>();

const CmsScheme = () => {
  const formChanged = useCmsFormControlStore((state) => state.formFieldUpdated);
  const documentChanged = useCmsFormControlStore(
    (state) => state.documentFieldUpdated
  );
  const [data, setData] = useState<CmsRegister[]>();
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [singleDocument, setSingleDocument] = useState<object>();
  const [rgCaFormFields, setRgCaFormFields] = useState<[]>([]);
  const [depositTakerFormFields, setDepositTakerFormFields] = useState<[]>([]);
  const [fileTypeData, setFileTypeData] = useState<[]>([]);
  const [validations, setValidations] = useState<any[]>([]);
  const [fieldTypes, setFieldTypes] = useState<any[]>([]);
  const [entitySectionsData, setentitySectionsData] = useState<[]>([]);
  const [modalOpenClose, setModalOpenClose] = useState<boolean>(false);
  const [modalFieldForm, setmodalFieldForm] = useState<boolean>(false);
  const [editFormFieldData, setEditFormFieldData] = useState<any>(undefined);
  const [modalOpenCloseAdd, setModalOpenCloseAdd] = useState<boolean>(false);
  const [modalFormFieldOpenCloseAdd, setFormFieldModalOpenCloseAdd] =
    useState<boolean>(false);
  const [modalFormFieldData, setModalFormFieldData] = useState<any[]>([]);
  const [formFieldType, setFormFieldType] = useState<string>("");
  const location = useLocation();
  const registerId = location?.state?.id?.id || "";
  let count = 0;
  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= dataTotal) {
          count++;
          return count;
        }
      },
      header: () => <span>Sr. No.</span>,
    }),
    columnHelper.accessor("documentName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Entity Name</span>,
    }),
    columnHelper.accessor("addToRegistration", {
      cell: (info) => {
        const value: boolean = info.getValue();
        const documentFieldId = info.row.original.id;
        const Status = info.row.original.addToRegistration;
        const keyword = info.column.id;
        const apicall = () => {
          axiosUAMInstance
            .patch(`/registration/document-status`, {
              documentFieldId: documentFieldId,
              status: !Status,
              keyword,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        return (
          <>
            <ToggleSwitch enabled={Status} apiCall={apicall} />
          </>
        );
      },
      header: () => <span>Scheme Creation by Deposit Taker</span>,
    }),
    columnHelper.accessor("addToProfile", {
      cell: (info) => {
        const value: boolean = info.getValue();
        const documentFieldId = info.row.original.id;
        const Status = info.row.original.addToRegistration;
        const keyword = info.column.id;

        const apicall = () => {
          axiosUAMInstance
            .patch(`/registration/document-status`, {
              documentFieldId: documentFieldId,
              status: !value,
              keyword,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        return (
          <>
            <ToggleSwitch enabled={value} apiCall={apicall} />
          </>
        );
      },
      header: () => (
        <span>Scheme Creation by Regulator / Competent Authority</span>
      ),
    }),

    columnHelper.accessor("required", {
      cell: (info) => {
        const documentFieldId = info.row.original.id;
        const Status = info.row.original.required;
        const keyword = info.column.id;

        const apicall = () => {
          axiosUAMInstance
            .patch(`/registration/document-status`, {
              documentFieldId: documentFieldId,
              status: !Status,
              keyword,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        };

        return (
          <>
            <ToggleSwitch enabled={Status} apiCall={apicall} />
          </>
        );
      },
      header: () => <span>Required</span>,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();

        return (
          <div className="flex flex-row justify-center">
            <div>
              <ActionButton
                variant="edit"
                onClick={() => {
                  setSingleDocument(info?.row?.original);
                  setModalOpenClose(true);
                }}
              />
            </div>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  useEffect(() => {
    const getAllRegistrationFields = (registerId: string) => {
      setLoader(true);
      axiosUAMInstance
        .get(`/scheme/field-data/1`)
        .then(async (response) => {
          let rgCaData = [];
          try {
            let rgCaData = await axiosUAMInstance.get(`/scheme/field-data/2`);
            let data = rgCaData.data;

            setRgCaFormFields(
              data?.data?.formFields?.allFormFields?.map(
                (f: any, i: number) => ({
                  sr_no: i + 1,
                  tmp_value: "",
                  ...f,
                })
              )
            );
          } catch (error) {
            console.log({ error });
          }
          setValidations(response?.data?.data?.validations);
          setFieldTypes(response?.data?.data?.fieldTypes);
          setentitySectionsData(response?.data?.data?.entitySections);
          setFileTypeData(response?.data?.data?.fileTypes);
          setDepositTakerFormFields(
            response?.data?.data?.formFields?.allFormFields?.map(
              (f: any, i: number) => ({
                sr_no: i + 1,
                tmp_value: "",
                ...f,
              })
            )
          );

          // setModalFormFieldData(
          //   response?.data?.data?.formFields?.allFormFields?.map(
          //     (f: any, i: number) => ({ sr_no: i + 1, tmp_value: "", ...f })
          //   )
          // );
          setLoader(false);
        })
        .catch((error: any) => {
          setLoader(false);
        });
    };

    getAllRegistrationFields(registerId);
  }, [formChanged]);

  const handleFormFieldModal = (tag: "rgca" | "dt") => {
    setModalFormFieldData(
      tag === "rgca" ? rgCaFormFields : depositTakerFormFields
    );
    setmodalFieldForm(true);
  };

  const handleModal = () => {
    setModalOpenClose(!modalOpenClose);
  };
  const handleModalAdd = () => {
    setModalOpenCloseAdd(false);
  };

  const handleOpenFormFieldAddModal = (type: string) => {
    setFormFieldType(type);
    setFormFieldModalOpenCloseAdd(true);
  };

  const handleTabOnClick = (data: any) => {
    setEditFormFieldData(data);
    setFormFieldModalOpenCloseAdd(true);
  };

  const editDocumentSubmit = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/scheme/document-fields`)
      .then((response) => {
        if (response?.data?.success) {
          const sortedData = response?.data?.data?.sort(
            (a: any, b: any) => a.id - b.id
          );
          setData(sortedData);
          // console.log(sortedData.length)
          setDataTotal(sortedData.length);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    editDocumentSubmit();
  }, [registerId, documentChanged]);

  console.log({
    rgCaFormFields,
    depositTakerFormFields,
  });

  return (
    <div>
      <Layout
        layout={
          <div className="relative">
            <div className="mt-4 ml-4 xl:ml-[40px]">
              <CmsTabs />
            </div>
            <div className="mt-[35px] mx-4 xl:mx-[40px] flex flex-col ">
              <div className="rounded-t-lg rounded-b-lg">
                <RegSignUpHead
                  buttonLabel2="Add"
                  buttonLabel="Edit"
                  heading="Scheme Creation by Deposit Taker"
                  button2OnClick={() => handleOpenFormFieldAddModal("signup")}
                  onClick={() => handleFormFieldModal("dt")}
                  Data={depositTakerFormFields}
                />

                {loader ? (
                  <div className="">
                    <LoaderSpin />
                  </div>
                ) : (
                  <>
                    {depositTakerFormFields &&
                    depositTakerFormFields.length > 0 ? (
                      <ContentsDiv
                        tabsData={depositTakerFormFields || []}
                        handleTabOnClick={handleTabOnClick}
                      />
                    ) : (
                      <div>
                        <p className="text-lg text-center text-gilroy-regular font-normal">
                          Please add field
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="rounded-t-lg rounded-b-lg mt-4">
                <RegSignUpHead
                  buttonLabel2="Add"
                  buttonLabel="Edit"
                  heading="Scheme Creation by Regulator / Competent Authority	"
                  button2OnClick={() =>
                    handleOpenFormFieldAddModal("registration")
                  }
                  onClick={() => handleFormFieldModal("rgca")}
                  Data={rgCaFormFields}
                />

                {loader ? (
                  <div>
                    <LoaderSpin />
                  </div>
                ) : rgCaFormFields && rgCaFormFields.length > 0 ? (
                  <ContentsDiv
                    tabsData={rgCaFormFields || []}
                    handleTabOnClick={handleTabOnClick}
                  />
                ) : (
                  <div>
                    <p className="text-lg text-center text-gilroy-regular font-normal">
                      Please add field
                    </p>
                  </div>
                )}
              </div>
              <div className="rounded-t-lg rounded-b-lg mt-4">
                <RegSignUpHead
                  buttonLabel="Add"
                  heading="Documents"
                  onClick={setModalOpenCloseAdd}
                />
              </div>

              {loader ? (
                <div className="mt-10">
                  <LoaderSpin />
                </div>
              ) : (
                <div className="mb-4 mt-[27px]">
                  {data && data?.length > 0 && (
                    <ReactTable defaultData={data} columns={columns} />
                  )}
                </div>
              )}
            </div>

            {data && data?.length > 0 && modalOpenClose && (
              <EditDocumentScheme
                handleOpenClose={handleModal}
                heading="Edit Document"
                singleDocument={singleDocument}
                registerId={registerId}
                fileTypeData={fileTypeData}
                entitySectionsData={entitySectionsData}
                apiCall={editDocumentSubmit}
              />
            )}
            {modalFieldForm && (
              <ManageFormFieldsForRegistrationScheme
                handleOpenClose={() => setmodalFieldForm(false)}
                defaultData={modalFormFieldData}
              />
            )}

            {modalOpenCloseAdd && (
              <EditModalScheme
                handleOpenClose={handleModalAdd}
                heading="Add Document"
                fileTypeData={fileTypeData}
                entitySectionsData={entitySectionsData}
              />
            )}
            {modalFormFieldOpenCloseAdd && (
              <AddFormFieldModalScheme
                editData={editFormFieldData}
                handleOpenClose={() => {
                  setFormFieldModalOpenCloseAdd(false);
                  setEditFormFieldData(undefined);
                }}
                heading="Add Field"
                entitySectionsData={entitySectionsData}
                validations={validations}
                fieldTypes={fieldTypes}
                type={formFieldType}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
export default CmsScheme;
