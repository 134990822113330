import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import ReactTable from "../../components/common/ReactTable";
import LoaderSpin from "../../components/LoaderSpin";
import { ColumnDef } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import EntityRegistrationOtpContentFive from "../../components/cms/EntityRegistrationOtpContentFive";
import Adddownload from "./Adddownload";
import Button from "../../components/form/Button";
import useWebContentStore from "../../state/useWebContentStore";
import { axiosUAMInstance } from "../../utils/axios";

const CmsWebsiteContentInternalPageFive = () => {
  const { downloadsData, setDownloadsData } = useWebContentStore();
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOpenModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentEditData, setCurrentEditData] = useState<any>(null);
  const [contentData, setContentData] = useState<any>(null);
  const [isOpenDownloadOpen, setIsOpenDownloadOpen] = useState(false);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response2 = await axiosUAMInstance.get(
        `/websitecontent/content/Downloads`
      );
      const resData = response2.data;
      const data = resData?.data?.find((d: any) => d?.language === "english");
      if (
        resData?.status === "success" &&
        data?.content?.data[0]?.downloadPageData
      ) {
        setDownloadsData(resData);

        const { downloadPageData } = data?.content?.data[0];

        setContentData(downloadPageData);

        const sections = [
          { section: "heading", data: downloadPageData?.heading || [] },
          { section: "button", data: downloadPageData?.button || [] },
          { section: "downloads", data: downloadPageData?.downloads || [] },
        ];
        let idCounter = 1;
        const transformedData: any = sections?.flatMap(
          (section, sectionIndex) =>
            section.data.map((item: any, itemIndex: number) => {
              return {
                id: idCounter++,
                idx: itemIndex,
                section: section.section,
                content: item.text,
                link: item.link || "N/A",
                img: item.img || null,
                name: item.name || null,
              };
            })
        );

        setTableData(transformedData);
      } else {
        console.error("Failed to fetch data: No data returned");
        setTableData([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setTableData([]);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnDef<(typeof tableData)[number]>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "section", header: "Section" },
    { accessorKey: "content", header: "Document" },
    // { accessorKey: "name", header: "Name" },
    // {
    //   accessorKey: "link",
    //   header: "Link",
    //   cell: ({ getValue }: any) =>
    //     getValue() ? (
    //       <a href={getValue()} target="_blank" rel="noopener noreferrer">
    //         Download
    //       </a>
    //     ) : (
    //       "No Link Available"
    //     ),
    // },
    {
      accessorKey: "link",
      header: "Link",
      cell: (info: any) => info.getValue(),
    },
    {
      accessorKey: "img",
      header: "Image",
      cell: ({ getValue }: any) =>
        getValue() ? (
          <img src={getValue()} alt="Download Icon" style={{ width: "50px" }} />
        ) : (
          "No Image Available"
        ),
    },
    {
      accessorKey: "edit",
      header: "Edit",
      cell: ({ row }: any) => (
        <ActionButton
          variant="edit"
          onClick={() => {
            setCurrentEditData(row?.original);
            setIsModalOpen(true);
          }}
        />
      ),
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeDownload = () => {
    setIsOpenDownloadOpen(false);
  };

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
            <div className="mt-4">
              <div className=" my-6  flex justify-end items-end ">
                <Button
                  // loader={loader}
                  type="button"
                  label="Add Download"
                  onClick={() => {
                    setCurrentEditData({ section: "add" });
                    setIsModalOpen(true);
                  }}
                />
              </div>
              <div className="flex justify-center items-center my-3">
                <h1 className="text-lg text-gilroy-regular">DownLoad</h1>
              </div>
              <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
                {loader ? (
                  <LoaderSpin />
                ) : tableData.length > 0 ? (
                  <ReactTable defaultData={tableData} columns={columns} />
                ) : (
                  <div className="flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
            </div>

            {isOpenDownloadOpen && (
              <Adddownload
                onClose={closeDownload}
                content={contentData}
                apiCall={fetchData}
              />
            )}

            {isOpenModalOpen && (
              <EntityRegistrationOtpContentFive
                onClose={closeModal}
                currentEditData={currentEditData || {}}
                data={contentData}
                apiCall={fetchData}
              />
            )}
          </div>
        </div>
      }
    />
  );
};

export default CmsWebsiteContentInternalPageFive;
