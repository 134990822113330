import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import add from "../../assets/images/add.svg";
import Swal from "sweetalert2";
import InputFields from "../../components/form/InputField";
import ButtonComp from "../../components/cms/ButtonComp";
import SelectButton from "../../components/cms/SelectButton"; // Ensure path is correct
import { axiosUAMInstance } from "../../utils/axios";

// Define the styles for the modal
const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

// Define form values interface
interface FormValues {
  Name: string;
  Text: string;
  Link: string;
  Image: File | null;
}

// Define component props interface
interface Props {
  onClose: () => void;
  apiCall?: () => void;
}

// Define option type
interface Option {
  value: string;
  label: string;
  id: number;
}

// Carousel component
const Addcarousel: React.FC<Props> = ({ onClose, apiCall }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      Text: "",
      Image: null,
    },
  });

  const [selectedKey, setSelectedKey] = useState<Option | null>(null);
  const imageFile = watch("Image");

  // Load image preview
  useEffect(() => {
    if (imageFile instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImagePreviewUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  // Generate unique ID
  const generateId = () => {
    return Math.floor(Math.random() * 100000);
  };

  // Handle form submission
  const handleFormSubmit = async (formData: FormValues) => {
    setLoader(true);

    const newCarouselItem = {
      id: generateId(),
      name: formData.Name || "Image",
      text: formData.Text,
      // link: formData.Link || null,
      img: imagePreviewUrl,
      section: "carousel",
      altText: formData.Text,
      keylist: selectedKey?.value,
    };

    try {
      const response = await axiosUAMInstance.get(
        `/websitecontent/content/Home`
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch website content.");
      }

      const contentData = response.data.data;

      contentData.forEach((content: any) => {
        if (!content.content.updatedStructure.homePageData.carousel) {
          content.content.updatedStructure.homePageData.carousel = [];
        }
        content.content.updatedStructure.homePageData.carousel.push(
          newCarouselItem
        );
      });

      const updateResponse = await axiosUAMInstance.post(
        `/websitecontent/update-many`,
        contentData
      );

      if (updateResponse.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Add carousel successfully!",
        });
        // setTimeout(() => {
        //   onClose();
        //   if (apiCall) apiCall();
        // }, 1000);
        onClose();
        if (apiCall) apiCall();
      } else {
        throw new Error("Failed to update website content.");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update website content. Please try again.",
      });
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  // Handle image change
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setValue("Image", file);
      const fileUrl = URL.createObjectURL(file);
      setImagePreviewUrl(fileUrl);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await axiosUAMInstance.post(
          `/websitecontent/save/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse?.data?.status === "success") {
          setImagePreviewUrl(uploadResponse?.data?.data?.fileurl);
          setValue("Image", uploadResponse?.data?.data?.fileurl);
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: "Failed to upload image. Please try again.",
          });
        }
      } catch (error) {
        console.error("Upload Error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error occurred while uploading the image.",
        });
      }
    } else {
      setValue("Image", null);
      setImagePreviewUrl(null);
    }
  };

  const keylist = [
    "totalSchemeUnderLetigation",
    "totalDepositTakerRegistered",
    "totalDepositTakerApproved",
    "totalDepositTakerPending",
    "totalSchemeRegistered",
    "totalSchemeBanned",
  ];

  // Function to convert camelCase text to Title Case with spaces
  function camelCaseToTitleCase(camelCase: string) {
    if (camelCase === null || camelCase === "") {
      return camelCase;
    }
    camelCase = camelCase.trim();
    var newText = "";
    for (var i = 0; i < camelCase.length; i++) {
      if (
        /[A-Z]/.test(camelCase[i]) &&
        i !== 0 &&
        /[a-z]/.test(camelCase[i - 1])
      ) {
        newText += " ";
      }
      newText += camelCase[i];
    }
    return newText
      .replace(/([A-Z])/g, (match) => " " + match)
      .trim()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Convert static keylist to options with human-readable labels
  const options = keylist.map((key, index) => ({
    id: index + 1,
    value: key,
    label: camelCaseToTitleCase(key).replace("Total ", ""),
  }));

  // Handle key selection
  const handleSetKey = (option: Option) => {
    console.log(option, "option");
    setSelectedKey(option);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="m-[16px] md:m-[0px] w-[270px] md:w-[544px] rounded-2xl p-[8px] text-gilroy-medium pb-[38px] shadow-xl backdrop-blur-lg bg-white">
          <div
            className="flex flex-row justify-end mb-[12px] cursor-pointer"
            onClick={onClose}
          >
            <img src={add} className="w-6 h-6" alt="close icon" />
          </div>
          <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
            Add Carousel
          </h1>

          <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 md">
            <div
              className="custom-scrollbar p-4"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="my-4">
                <label
                  htmlFor="Text"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Text:
                </label>
                <InputFields
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("Text")}
                  placeholder="type here"
                />
              </div>
              <div className="my-4">
                <label
                  htmlFor="key"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Key:
                </label>
                <SelectButton
                  setOption={handleSetKey}
                  options={options}
                  selectedOption={selectedKey}
                  placeholder="Select Key"
                />
              </div>

              <div className="my-4">
                <label
                  htmlFor="Image"
                  className="text-black text-[16px] font-normal mb-[8px] text-gilroy-medium"
                >
                  Image:
                </label>
                <input
                  placeholder="type here"
                  type="file"
                  onChange={handleImageChange}
                  className="shadow appearance-none border rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    className="mt-4 w-10"
                  />
                )}
              </div>
              <hr className="w-full bg-[#E6E6E6] mb-[24px]" />
              <ButtonComp onClose={onClose} loader={loader} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default Addcarousel;
